import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataWheelView } from '@snappet-content-products/data-wheel/domain';

@Component({
	selector: 'cp-data-wheel-view-toggle',
	templateUrl: './data-wheel-view-toggle.component.html',
	styleUrls: ['./data-wheel-view-toggle.component.scss']
})
export class DataWheelViewToggleComponent {
	@Output() readonly selectedViewChange = new EventEmitter<DataWheelView>();

	isChecked = false;

	@Input() set selectedView(selectedView: DataWheelView) {
		this.isChecked = selectedView === 'course';
	}

	onToggle() {
		this.selectedViewChange.emit(this.mapIsCheckedToView(!this.isChecked));
	}

	onSelect(isChecked: boolean) {
		this.selectedViewChange.emit(this.mapIsCheckedToView(isChecked));
	}

	private mapIsCheckedToView(isChecked: boolean): DataWheelView {
		return isChecked ? 'course' : 'standards';
	}
}
