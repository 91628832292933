import { HostContextType } from '@snappet-content-products/shared/domain';
import { DataWheelContextFactory } from './data-wheel-context.factory';
import { IDataWheelContext } from './data-wheel-context.interface';

export class DataWheelContext implements IDataWheelContext {
	contextType: HostContextType;

	private readonly context: IDataWheelContext;

	constructor(contextType: HostContextType) {
		this.contextType = contextType;
		this.context = DataWheelContextFactory.create(contextType);
	}

	get showLogout(): boolean {
		return this.context.showLogout;
	}

	get showLogin(): boolean {
		return this.context.showLogin;
	}

	get showLogo(): boolean {
		return this.context.showLogo;
	}
}
