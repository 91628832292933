<div class="toggle-container">
    <div class="toggle-control">
        <label [class.active]="!isChecked"
               data-test="select-view-standards"
               (click)="onSelect(false)">{{ 'DATA_WHEEL.VIEW_STANDARDS' | translate }}</label>
        <div class="toggle-switch"
             data-test="toggle-view"
             (click)="onToggle()">
            <input type="checkbox"
                   [(ngModel)]="isChecked">
            <div class="switch"
                 [class.checked]="isChecked"></div>
        </div>
        <label [class.active]="isChecked"
               data-test="select-view-courses"
               (click)="onSelect(true)">{{ 'DATA_WHEEL.VIEW_COURSES' | translate }}</label>
    </div>
</div>
