import { Directive, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { DataWheelDirection } from '@snappet-content-products/data-wheel/domain';
import { Subject, takeUntil, tap } from 'rxjs';

@Directive({
	selector: '[cpDataWheelInteraction]'
})
export class DataWheelInteractionDirective implements OnDestroy {
	@Output() readonly direction = new EventEmitter<DataWheelDirection>();

	private readonly directionSubject = new Subject<DataWheelDirection>();

	private readonly destroy$ = new Subject<void>();

	constructor() {
		this.directionSubject.pipe(
			tap(direction => this.direction.emit(direction)),
			takeUntil(this.destroy$)
		).subscribe();
	}

	@HostListener('wheel', ['$event'])
	onWheel({ deltaY }: WheelEvent): void {
		if (deltaY > 0) {
			this.directionSubject.next('cw');
		} else if (deltaY < 0) {
			this.directionSubject.next('ccw');
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
