import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pupil } from '../domain/pupil.model';
import { retry } from 'rxjs/operators';
import { RegionConfigService, genericRetryStrategy } from '@snappet-content-products/shared/util';

@Injectable()
export class PupilService {
	constructor(private readonly httpClient: HttpClient, private readonly regionConfigService: RegionConfigService) { }

	get apiUrl(): string {
		return this.regionConfigService.config?.authorizationApiUrls.bff;
	}

	getAllSubjectGroupUsers(classId: number, subjectGroupId: number): Observable<Pupil[]> {
		return this.httpClient.get<Pupil[]>(`${this.apiUrl}/classes/${classId}/subjectgroups/${subjectGroupId}/pupils`).pipe(
			retry(genericRetryStrategy())
		);
	}
}
