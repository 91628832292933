import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinningBallsComponent } from './spinning-balls.component';

@NgModule({
	imports: [CommonModule],
	declarations: [SpinningBallsComponent],
	exports: [SpinningBallsComponent]
})
export class SpinningBallsModule {
}
