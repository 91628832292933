/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	Color,
	CourseGrade, CourseGroup, Cycle, DataConverterService,
	DataEntities,
	DataFilter,
	DataFilterService,
	DataItem,
	DataPreprocessorService,
	DataSelectorService, DataUtilService,
	DataWheelContext,
	DataWheelControllerService,
	DataWheelDirection,
	DataWheelFacade,
	DataWheelNavigation,
	DataWheelView,
	EducationalStandardNode,
	EntityTypeEnum,
	LearningObjective,
	Lesson,
	LessonListData,
	ROOT_GUID,
	Section,
	SelectedState,
	Subject,
	SubMarket
} from '@snappet-content-products/data-wheel/domain';
import { BaseContainerComponent } from '@snappet-content-products/shared/angular';
import { HostContextType, LessonPath } from '@snappet-content-products/shared/domain';
import { AuthService } from '@snappet-content-products/shared/util';
import { Market } from '@snappet/enums/dist';
import { sortBy, uniqBy } from 'lodash-es';
import { filter as rxjsFilter, firstValueFrom, takeUntil, tap } from 'rxjs';

import * as nlCourseGrades from '../../assets/nl-NL/courseGrades.json';
import * as nlCourseGroups from '../../assets/nl-NL/courseGroups.json';
import * as nlCycles from '../../assets/nl-NL/cycles.json';
import * as nlEducationalStandards from '../../assets/nl-NL/educationalStandards.json';
import * as nlLearningObjectives from '../../assets/nl-NL/learningObjectives.json';
import * as nlLessons from '../../assets/nl-NL/lessons.json';
import * as nlLoColors from '../../assets/nl-NL/lo-colors.json';
import * as nlSections from '../../assets/nl-NL/sections.json';
import * as nlSubjects from '../../assets/nl-NL/subjects.json';
import * as nlSubMarkets from '../../assets/nl-NL/subMarkets.json';

import * as esCourseGrades from '../../assets/es-ES/courseGrades.json';
import * as esCourseGroups from '../../assets/es-ES/courseGroups.json';
import * as esCycles from '../../assets/es-ES/cycles.json';
import * as esEducationalStandards from '../../assets/es-ES/educationalStandards.json';
import * as esLearningObjectives from '../../assets/es-ES/learningObjectives.json';
import * as esLessons from '../../assets/es-ES/lessons.json';
import * as esLoColors from '../../assets/es-ES/lo-colors.json';
import * as esSections from '../../assets/es-ES/sections.json';
import * as esSubjects from '../../assets/es-ES/subjects.json';
import * as esSubMarkets from '../../assets/es-ES/subMarkets.json';

import * as usCourseGrades from '../../assets/en-US/courseGrades.json';
import * as usCourseGroups from '../../assets/en-US/courseGroups.json';
import * as usCycles from '../../assets/en-US/cycles.json';
import * as usEducationalStandards from '../../assets/en-US/educationalStandards.json';
import * as usLearningObjectives from '../../assets/en-US/learningObjectives.json';
import * as usLessons from '../../assets/en-US/lessons.json';
import * as usLoColors from '../../assets/en-US/lo-colors.json';
import * as usSections from '../../assets/en-US/sections.json';
import * as usSubjects from '../../assets/en-US/subjects.json';
import * as usSubMarkets from '../../assets/en-US/subMarkets.json';

@Component({
	selector: 'cp-data-wheel',
	templateUrl: './data-wheel.component.html',
	styleUrls: ['./data-wheel.component.scss'],
	providers: [
		DataConverterService,
		DataPreprocessorService,
		DataFilterService,
		DataSelectorService,
		DataUtilService,
		DataWheelFacade,
		DataWheelControllerService
	]
})
export class DataWheelComponent extends BaseContainerComponent implements OnChanges {
	@Input() set context(type: HostContextType) {
		this.dataWheelContext = new DataWheelContext(type);
	}

	get context(): HostContextType {
		return this.dataWheelContext.contextType;
	}

	@Input() loColors: string;

	@Output() readonly lessonClick = new EventEmitter<LessonPath>();
	@Output() readonly logoutClick = new EventEmitter<void>();
	@Output() readonly loginClick = new EventEmitter<void>();
	@Output() readonly renderingDone = new EventEmitter<void>();

	data: DataItem;
	selectedView: DataWheelView;
	selectedState: SelectedState;
	radiusZoomFactor: number;
	lessonListData: LessonListData;
	section: DataItem;
	colors: Color[] = [];
	selectedColorId: number;
	dataWheelContext = new DataWheelContext('website');

	pathLabel: string[];
	filter: DataFilter;
	entities: DataEntities;
	innerCircleCallback: () => void;

	private contentData: DataItem[];
	private educationalStandardData: DataItem[];
	private learningObjectiveData: DataItem[];

	private readonly marketDataMap: Map<Market, {
		subjects: Subject[];
		subMarkets: SubMarket[];
		learningObjectives: LearningObjective[];
		courseGrades: CourseGrade[];
		cycles: Cycle[];
		courseGroups: CourseGroup[];
		sections: Section[];
		lessons: Lesson[];
		educationalStandards: EducationalStandardNode[];
		selectedLoColors: string;
	}>;

	constructor(
		protected authService: AuthService,
		protected translateService: TranslateService,
		private readonly dataPreprocessorService: DataPreprocessorService,
		private readonly dataFilterService: DataFilterService,
		private readonly dataSelectorService: DataSelectorService,
		private readonly dataUtilService: DataUtilService,
		private readonly dataWheelFacade: DataWheelFacade,
		private readonly dataWheelControllerService: DataWheelControllerService
	) {
		super(authService, translateService);

		// The Object.values() cast is necessary because the ES JSON is too big for TS to infer the type.
		this.marketDataMap = new Map([
			[Market.Nl, {
				subjects: Array.from(nlSubjects),
				subMarkets: Array.from(nlSubMarkets),
				learningObjectives: Array.from(nlLearningObjectives),
				courseGrades: Array.from(nlCourseGrades),
				cycles: Array.from(nlCycles),
				courseGroups: Array.from(nlCourseGroups),
				sections: Array.from(nlSections),
				lessons: Array.from(nlLessons),
				educationalStandards: Array.from(nlEducationalStandards),
				selectedLoColors: JSON.stringify(nlLoColors)
			}],
			[Market.Es, {
				subjects: Array.from(esSubjects),
				subMarkets: Array.from(esSubMarkets),
				learningObjectives: Array.from(esLearningObjectives),
				courseGrades: Array.from(esCourseGrades),
				cycles: Array.from(esCycles),
				courseGroups: Array.from(esCourseGroups),
				sections: Array.from(esSections),
				lessons: Array.from(esLessons),
				educationalStandards: Array.from(Object.values(esEducationalStandards) as EducationalStandardNode[]),
				selectedLoColors: JSON.stringify(esLoColors)
			}],
			[Market.Us, {
				subjects: Array.from(usSubjects),
				subMarkets: Array.from(usSubMarkets),
				learningObjectives: Array.from(usLearningObjectives),
				courseGrades: Array.from(usCourseGrades),
				cycles: Array.from(usCycles),
				courseGroups: Array.from(usCourseGroups),
				sections: Array.from(usSections),
				lessons: Array.from(usLessons),
				educationalStandards: Array.from(usEducationalStandards),
				selectedLoColors: JSON.stringify(usLoColors)
			}]
		]);

		this.dataWheelControllerService.radiusZoomFactor$.pipe(
			tap(radiusZoomFactor => this.radiusZoomFactor = radiusZoomFactor),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataWheelControllerService.selectedView$.pipe(
			tap(selectedView => this.selectedView = selectedView),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataWheelControllerService.selectedColorId$.pipe(
			tap(selectedColorId => this.selectedColorId = selectedColorId),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataWheelControllerService.selectedState$.pipe(
			tap(selectedState => this.selectedState = selectedState),
			tap(() => this.bindInnerCircleCallback()),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataWheelControllerService.lessonListData$.pipe(
			tap(lessonListData => this.lessonListData = lessonListData),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataWheelControllerService.data$.pipe(
			tap(data => this.data = data),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataWheelControllerService.pathLabel$.pipe(
			tap(pathLabel => this.pathLabel = pathLabel),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataWheelControllerService.filter$.pipe(
			tap(filter => this.filter = filter),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataWheelFacade.init$.pipe(
			rxjsFilter(({ market }) => this.marketDataMap.has(market)),
			tap(() => this.initialize()),
			takeUntil(this.destroy$)
		).subscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		super.ngOnChanges(changes);

		if (changes['loColors'] && this.loColors) {
			this.marketDataMap.get(this.market).selectedLoColors = this.loColors;
			this.initialize();
		}
		if (changes['market'] && this.market) {
			this.dataWheelFacade.setMarket(+this.market);
		}
		if (changes['lang'] && this.lang) {
			this.dataWheelFacade.setLang(this.lang);
		}
	}

	trackByFn(_: number, { id }: DataItem): number {
		return id;
	}

	async initialize(): Promise<void> {
		const { subjects, courseGroups, courseGrades, sections, lessons, educationalStandards, cycles, learningObjectives, subMarkets } = this.marketDataMap.get(this.market);
		const cycleData = this.dataPreprocessorService.mapCyclesToDataNodes(cycles);
		const subMarketData = this.dataPreprocessorService.mapSubMarketsToDataNodes(subMarkets, courseGroups, educationalStandards);

		this.contentData = this.dataPreprocessorService.mapContentDataToNestedDataNodeHierarchy({
			subjects,
			courseGroups,
			courseGrades,
			sections,
			lessons
		});

		this.learningObjectiveData = this.dataPreprocessorService.mapLearningObjectivesToDataNodes(learningObjectives);

		this.educationalStandardData = this.dataPreprocessorService.mapEducationalStandardDataToNestedEntityHierarchy(educationalStandards);

		this.entities = {
			subjects: sortBy(uniqBy<DataItem>(this.dataSelectorService.getLevelByType(this.contentData, EntityTypeEnum.Subject), 'id'), 'label'),
			grades: sortBy(uniqBy<DataItem>(this.dataSelectorService.getLevelByType(this.contentData, EntityTypeEnum.Grade), 'id'), this.dataPreprocessorService.sortGrade),
			cycles: sortBy(uniqBy<DataItem>(this.dataSelectorService.getLevelByType(cycleData, EntityTypeEnum.Cycle), 'id'), 'label'),
			courseGroups: sortBy(uniqBy<DataItem>(this.dataSelectorService.getLevelByType(this.contentData, EntityTypeEnum.CourseGroup), 'id'), 'label'),
			educationalStandards: sortBy(this.dataSelectorService.getLevelByType(this.educationalStandardData, EntityTypeEnum.EducationalStandard), 'id'),
			subMarkets: sortBy(subMarketData, 'label')
		};

		this.colors = this.entities.grades.map(g => ({ id: g.id, label: g.label.match(/ [0-9a-zA-Z]/)[0].trim() }));

		const [lessonLabel, noLessonLabel, subjectsLabel] = await Promise.all([
			firstValueFrom(this.translateService.get('DATA_WHEEL.LESSON')),
			firstValueFrom(this.translateService.get('DATA_WHEEL.NO_LESSON_MESSAGE')),
			firstValueFrom(this.translateService.get('DATA_WHEEL.SUBJECTS'))
		]);

		const campaignLessonId = +sessionStorage.getItem('campaignLessonId');
		let navigation: DataWheelNavigation;

		if (campaignLessonId) {
			navigation = {
				rootId: ROOT_GUID,
				leafId: this.dataSelectorService.getNodeById(this.contentData, EntityTypeEnum.Lesson, campaignLessonId)?.guid
			};
		}

		this.dataWheelControllerService.initialize({
			market: this.market,
			contentData: this.contentData,
			educationalStandardData: this.educationalStandardData,
			learningObjectiveData: this.learningObjectiveData,
			colors: this.colors,
			loColors: Array.from(JSON.parse(this.marketDataMap.get(this.market).selectedLoColors)),
			defaultFilter: {
				[EntityTypeEnum.Subject]: this.entities.subjects.filter(e => e.isDefault).map(e => e.id),
				[EntityTypeEnum.Grade]: [],
				[EntityTypeEnum.CourseGroup]: [],
				[EntityTypeEnum.EducationalStandard]: this.entities.educationalStandards.filter(e => e.isDefault).map(e => e.id),
				[EntityTypeEnum.Cycle]: [],
				[EntityTypeEnum.SubMarket]: this.entities.subMarkets.filter(e => e.isDefault).map(e => e.id)
			},
			shouldUseSubMarketFilter: subMarketData.length > 0,
			labels: {
				root: {
					standards: subjectsLabel,
					course: subjectsLabel
				},
				lesson: lessonLabel,
				noLesson: noLessonLabel
			},
			jsonData: {
				courseGrades,
				sections,
				cycles
			},
			shouldTrimData: true,
			navigation
		});
	}

	onClickSelect(selectedId: string) {
		this.dataWheelControllerService.clickSelect(selectedId);
	}

	onRotateSelect(selectedId: string) {
		this.dataWheelControllerService.rotateSelect(selectedId);
	}

	onRotate(rotateDirection: DataWheelDirection) {
		this.dataWheelControllerService.rotate(rotateDirection);
	}

	onZoomBy(radiusZoomFactor: number): void {
		this.dataWheelControllerService.zoomBy(radiusZoomFactor);
	}

	onSelectedViewChange(selectedView: DataWheelView) {
		this.dataWheelControllerService.selectedViewChange(selectedView);
	}

	onFilterChange(dataFilter: DataFilter) {
		this.dataWheelControllerService.filterChange(dataFilter);
	}

	onSelectedColorChange(selectedColorId: number) {
		this.dataWheelControllerService.selectedColorChange(selectedColorId);
	}

	onLessonClick(lesson: DataItem) {
		if (lesson.type !== EntityTypeEnum.Lesson || !lesson.id) {
			return;
		}

		const lessonPath = this.mapToLessonPath(lesson.id);

		this.lessonClick.emit(lessonPath);
	}

	private bindInnerCircleCallback() {
		const rootItem = this.dataWheelControllerService.getSelectedRoot(this.selectedState.rootId);
		const filterRemoveOrder = [
			EntityTypeEnum.CourseGroup,
			this.market === Market.Es && this.selectedView === 'standards' ? EntityTypeEnum.Cycle : EntityTypeEnum.Grade,
			EntityTypeEnum.SubMarket,
			EntityTypeEnum.Subject,
			EntityTypeEnum.EducationalStandard
		];

		this.innerCircleCallback = this.dataFilterService.hasFilterApplied(this.filter) || rootItem?.parent
			? () => {
				if (rootItem?.guid) {
					this.dataWheelControllerService.clickSelect(rootItem.guid);
				}

				if (!rootItem?.parent) {
					this.dataWheelControllerService.removeFilter(filterRemoveOrder);
				}
			}
			: null;
	}

	private mapToLessonPath(lessonId: number): LessonPath {
		const lesson = this.dataSelectorService.getNodeById(this.contentData, EntityTypeEnum.Lesson, lessonId);
		// Courses are not part of the sunburst, only course groups are, so we have to get these from the content data source.
		const { sections } = this.marketDataMap.get(this.market);
		const section = sections.find(s => s.i === lesson.parentId);
		const grade = this.dataUtilService.findParentByType(lesson, EntityTypeEnum.Grade);
		const subject = this.dataUtilService.findParentByType(lesson, EntityTypeEnum.Subject);

		return {
			lessonId,
			gradeId: grade?.id,
			courseId: section?.p,
			subjectId: subject?.id
		} as LessonPath;
	}
}
