// Polyfill for old browsers not supporting the arrayBuffer.
interface Blob {
	arrayBuffer(): Promise<ArrayBuffer>;
}

((proto) => {
	if (typeof proto.arrayBuffer === 'function') {
		return;
	}

	proto.arrayBuffer = function arrayBuffer(this: Blob): Promise<ArrayBuffer> {
		return new Promise((resolve) => {
			console.log('using arrayBuffer polyfill');
			const fr = new FileReader();

			fr.onload = () => {
				resolve(fr.result as ArrayBuffer);
			};
			fr.readAsArrayBuffer(this);
		});
	};
})(Blob.prototype);
