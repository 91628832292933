import { NgModule } from '@angular/core';
import { MenuComponent } from './menu.component';
import { MenuTriggerDirective } from './menu-trigger.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { MenuItemContentDirective } from './menu-item-content.directive';
import { OverlayContainerComponent } from '../overlay/overlay-container/overlay-container.component';

@NgModule({
	imports: [
		CommonModule,
		OverlayModule,
		CdkMenuModule,
		ButtonModule,
		IconModule,
		A11yModule,
		OverlayContainerComponent
	],
	exports: [
		MenuComponent,
		MenuTriggerDirective,
		MenuItemContentDirective
	],
	declarations: [
		MenuComponent,
		MenuTriggerDirective,
		MenuItemContentDirective
	]
})
export class MenuModule { }
