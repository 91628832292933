import { Inject, Injectable, OnDestroy } from '@angular/core';
import { environment } from '@snappet-content-products/shared/environments';
import { RaygunService } from './raygun.service';

@Injectable()
export class LoggingService implements OnDestroy {
	isDebugEnabled: boolean;

	private logToRaygun: boolean;
	private raygunService: RaygunService;

	constructor(@Inject('rayGunKey') rayGunKey: string) {
		if (rayGunKey) {
			this.logToRaygun = true;
			this.raygunService = new RaygunService(rayGunKey);
		}
	}

	logError(error: Error): void {
		if (!error) {
			return;
		}

		if (!environment.production) {
			console.error('LoggingService error report:', error);
		}

		if (this.logToRaygun) {
			this.raygunService.log(error);
		}
	}

	debug(message: string, ...items: unknown[]): void {
		if (this.isDebugEnabled) {
			console.log(`DEBUG: ${message}`, items);
		}
	}

	ngOnDestroy(): void {
		if (this.raygunService) {
			this.raygunService.destroy();
		}
	}
}
