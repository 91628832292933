import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { retry } from 'rxjs/operators';
import { genericRetryStrategy, GenericRetryStrategyConfig, RegionConfigService } from '@snappet-content-products/shared/util';
import { PupilLoStatusCount, PupilLosStatusCount, UserLearningObjectiveStatus } from '../..';

@Injectable()
export class LoStatusService {
	constructor(private readonly httpClient: HttpClient, private readonly regionConfigService: RegionConfigService) { }

	get apiUrl(): string {
		return this.regionConfigService.config?.authorizationApiUrls.loStatus;
	}

	getUsersLearningObjectiveStatus(classId: number, subjectGroupId: number, learningObjectiveId: number, userIds: number[]): Observable<UserLearningObjectiveStatus[]> {
		if (!userIds.length) {
			return of([] as UserLearningObjectiveStatus[]);
		}

		const data = { userIds };

		return this.httpClient.post<UserLearningObjectiveStatus[]>(
			`${this.apiUrl}/LearningObjectiveStatus/${classId}/${subjectGroupId}/learningObjective/${learningObjectiveId}`, data
		).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLearningObjectiveStatusCount(classId: number, subjectGroupId: number, learningObjectiveId: number, userIds: number[]): Observable<PupilLoStatusCount[]> {
		if (!userIds.length) {
			return of([] as PupilLoStatusCount[]);
		}

		const data = { userIds };

		return this.httpClient.post<PupilLoStatusCount[]>(
			`${this.apiUrl}/LearningObjectiveStatus/${classId}/${subjectGroupId}/learningObjective/${learningObjectiveId}/count`, data
		).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLearningObjectivesStatusCount(
		classId: number, subjectGroupId: number, learningObjectiveIds: number[], userIds: number[],
		retryStrategyConfig: GenericRetryStrategyConfig): Observable<PupilLosStatusCount[]> {
		if (!userIds.length) {
			return of([] as PupilLosStatusCount[]);
		}

		const data = { userIds, learningObjectiveIds };

		return this.httpClient.post<PupilLosStatusCount[]>(
			`${this.apiUrl}/LearningObjectiveStatus/${classId}/${subjectGroupId}/learningObjectivesCount`, data
		).pipe(
			retry(genericRetryStrategy(retryStrategyConfig))
		);
	}
}
