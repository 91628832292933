export class ReadSpeakerError extends Error {
	constructor(
		public name: 'ReadSpeakerHighlightError' | 'ReadSpeakerAudioError',
		message?: string
	) {
		super(message);
	}
}

export class ReadSpeakerHighlightError extends ReadSpeakerError {
	constructor(
		message: string
	) {
		super('ReadSpeakerHighlightError', message);
	}
}

export class ReadSpeakerAudioError extends ReadSpeakerError {
	constructor(
		message: string
	) {
		super('ReadSpeakerAudioError', message);
	}
}
