import { ChangeDetectionStrategy, Component, ContentChild, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { LearningObjectiveUtil, LessonType } from '@snappet-content-products/shared/domain';

@Component({
	selector: 'cp-data-wheel-lesson-card',
	templateUrl: './data-wheel-lesson-card.component.html',
	styleUrls: ['./data-wheel-lesson-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataWheelLessonCardComponent implements OnChanges {
	@Input() lessonId: number;
	@Input() lessonLabel: string;
	@Input() lessonType: LessonType;
	@Input() learningObjectiveId: number;
	@Input() learningObjectiveIcon: string;
	@Input() learningObjectiveLabel: string;

	@ContentChild('leftCardContent') leftCardContent: TemplateRef<unknown>;

	loIconUrl: string;

	ngOnChanges(changes: SimpleChanges) {
		if (changes['learningObjectiveId'] && this.learningObjectiveId) {
			this.loIconUrl = LearningObjectiveUtil.getLoIconUrl(this.learningObjectiveId);
		}
	}
}
