import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { TabsModule } from './tabs/tabs.module';
import { ExpansionPanelModule } from './expansion-panel/expansion-panel.module';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MenuModule } from './menu/menu.module';
import { ButtonModule } from './button/button.module';
import { DialogModule } from '@angular/cdk/dialog';
import { SpinnerModule } from './spinner/spinner.module';
import { SpinningBallsModule } from './spinning-balls/spinning-balls.module';
import { IconModule } from './icon/icon.module';
import { TooltipModule } from './tooltip/tooltip.module';
import { DropdownModule } from './dropdown/dropdown.module';
import { ToggleGroupModule } from './toggle-group/toggle-group.module';
import { CheckboxToggleModule } from './checkbox-toggle/checkbox-toggle.module';
import { PopoverModule } from './popover/popover.module';
import { OverlayContainerComponent } from './overlay/overlay-container/overlay-container.component';

@NgModule({
	imports: [
		CommonModule,
		IconModule,
		TabsModule,
		ExpansionPanelModule,
		MatProgressSpinnerModule,
		MenuModule,
		DialogModule,
		SpinnerModule,
		SpinningBallsModule,
		TooltipModule,
		DropdownModule,
		ToggleGroupModule,
		PopoverModule,
		OverlayContainerComponent
	],
	exports: [
		OverlayModule,
		DropdownModule,
		IconModule,
		TabsModule,
		ExpansionPanelModule,
		ProgressSpinnerComponent,
		MenuModule,
		ButtonModule,
		DialogModule,
		SpinnerModule,
		SpinningBallsModule,
		TooltipModule,
		ToggleGroupModule,
		CheckboxToggleModule,
		PopoverModule,
		OverlayContainerComponent
	],
	declarations: [
		ProgressSpinnerComponent
	]
})
export class SharedUiModule { }
