import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Color } from '@snappet-content-products/data-wheel/domain';
import { BehaviorSubject, Subject, combineLatest, filter, map, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'cp-data-wheel-color-slider',
	templateUrl: './data-wheel-color-slider.component.html',
	styleUrls: ['./data-wheel-color-slider.component.scss']
})
export class DataWheelColorSliderComponent implements OnChanges, OnDestroy {
	@Input() colors: Color[];
	@Input() selectedColorId: number;

	@Output() readonly selectedColorChange = new EventEmitter<number>();

	selectedColorIndex = 0;
	selectedColorLabel: string;
	maxColorIndex: number;

	private readonly colorsSubject = new BehaviorSubject<Color[]>(null);
	private readonly selectedColorIdSubject = new BehaviorSubject<number>(null);
	private readonly destroy$ = new Subject<void>();

	constructor() {
		combineLatest([
			this.colorsSubject,
			this.selectedColorIdSubject
		]).pipe(
			filter(([colors, selectedColorId]) => !!colors && !!selectedColorId),
			map(([colors, selectedColorId]) => ({ colors, selectedColorIndex: colors.findIndex(c => c.id === selectedColorId) })),
			filter(({ selectedColorIndex }) => selectedColorIndex > -1),
			tap(({ colors, selectedColorIndex }) => {
				this.selectedColorIndex = selectedColorIndex;
				this.selectedColorLabel = colors[selectedColorIndex].label;
				this.maxColorIndex = colors.length - 1;
			}),
			takeUntil(this.destroy$)
		).subscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['colors'] && this.colors) {
			this.colorsSubject.next(this.colors);
		}
		if (changes['selectedColorId'] && this.selectedColorId) {
			this.selectedColorIdSubject.next(this.selectedColorId);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	trackByFn(_: number, color: Color): number {
		return color.id;
	}

	onColorChange() {
		if (!this.colors.length) {
			return;
		}

		this.selectedColorChange.emit(this.colors[this.selectedColorIndex].id);
	}
}
