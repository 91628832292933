export enum EntityTypeEnum {
	EducationalStandard = 1,
	Domain = 2,
	SubDomain = 3,
	Grade = 4,
	StandardNode = 5,
	LO = 6,
	Subject = 7,
	CourseGroup = 8,
	Section = 9,
	Lesson = 10,
	SuperDomain = 11,
	Cycle = 12,
	SubMarket = 13
}
