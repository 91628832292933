import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurriculumSunburstComponent } from './curriculum-sunburst/curriculum-sunburst.component';
import { DataWheelControllerComponent } from './data-wheel-controller/data-wheel-controller.component';
import { SharedUiModule } from '@snappet-content-products/shared/ui';
import { SharedUtilModule } from '@snappet-content-products/shared/util';
import { DataWheelFilterComponent } from './data-wheel-filter/data-wheel-filter.component';
import { FormsModule } from '@angular/forms';
import { DataWheelColorSliderComponent } from './data-wheel-color-slider/data-wheel-color-slider.component';
import { DataWheelViewToggleComponent } from './data-wheel-view-toggle/data-wheel-view-toggle.component';
import { DataWheelInteractionDirective } from './data-wheel-interaction/data-wheel-interaction.directive';
import { SharedAngularModule } from '@snappet-content-products/shared/angular';
import { DataWheelLessonCardComponent } from './data-wheel-lesson-card/data-wheel-lesson-card.component';
import { DataWheelContainerComponent } from './data-wheel-container/data-wheel-container.component';
import { DataWheelDialogComponent } from './data-wheel-dialog/data-wheel-dialog.component';
import { DataWheelMagnifyingGlassComponent } from './data-wheel-magnifying-glass/data-wheel-magnifying-glass.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedUiModule,
		SharedUtilModule,
		SharedAngularModule
	],
	declarations: [
		CurriculumSunburstComponent,
		DataWheelControllerComponent,
		DataWheelFilterComponent,
		DataWheelLessonCardComponent,
		DataWheelColorSliderComponent,
		DataWheelViewToggleComponent,
		DataWheelInteractionDirective,
		DataWheelContainerComponent,
		DataWheelDialogComponent,
		DataWheelMagnifyingGlassComponent
	],
	exports: [
		DataWheelControllerComponent,
		DataWheelFilterComponent,
		DataWheelLessonCardComponent,
		DataWheelInteractionDirective,
		DataWheelContainerComponent,
		DataWheelDialogComponent,
		DataWheelMagnifyingGlassComponent
	]
})
export class DataWheelFeatureModule { }
