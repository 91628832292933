<div *ngIf="hasData else noDataTemplate"
	 class="wheel"
	 (resize)="onResize($event)"
	 #dataWheel
	 resizeObserver>
	<cp-curriculum-sunburst class="sunburst"
							data-test="sunburst"
							[@scaleInOut]="radiusZoomFactor === 1"
							[data]="data"
							[pathLabel]="pathLabel"
							[selectedState]="selectedState"
							[radiusZoomFactor]="radiusZoomFactor"
							[gapFactor]="gapFactor"
							[selectedColorId]="selectedColorId"
							[innerCircleCallback]="innerCircleCallback"
							[template]="magnifyingGlassTemplate"
							(clickSelect)="clickSelect.emit($event)"
							(rotateSelect)="rotateSelect.emit($event)"
							(renderingDone)="renderingDone.emit()"></cp-curriculum-sunburst>
</div>
<div class="wheel-buttons">
	<div class="zoom-buttons">
		<button cp-icon-button
				class="zoom-button"
				data-test="zoom-in-button"
				(click)="onZoom('in')">
			<cp-icon iconId="Plus"></cp-icon>
		</button>
		<div class="zoom-buttons-divider"></div>
		<button cp-icon-button
				class="zoom-button"
				data-test="zoom-out-button"
				(click)="onZoom('out')">
			<cp-icon iconId="Minus"></cp-icon>
		</button>
	</div>

	<cp-data-wheel-view-toggle *ngIf="!hideViewToggle"
							   class="view-toggle"
							   [selectedView]="selectedView"
							   (selectedViewChange)="onSelectedViewChange($event)"></cp-data-wheel-view-toggle>

	<cp-data-wheel-color-slider *ngIf="colors?.length"
								class="color-slider"
								[colors]="colors"
								[selectedColorId]="selectedColorId"
								(selectedColorChange)="onSelectedColorChange($event)"></cp-data-wheel-color-slider>

</div>
<div [@fadeInOut]="radiusZoomFactor > 1"
	 class="rotate-buttons">
	<button cp-icon-button
			class="rotate-button"
			data-test="rotate-clockwise-button"
			(click)="onClockwise($event)">
		<cp-icon iconId="Up"></cp-icon>
	</button>
	<button cp-icon-button
			class="rotate-button"
			data-test="rotate-counterclockwise-button"
			(click)="onCounterclockwise($event)">
		<cp-icon iconId="Down"></cp-icon>
	</button>
</div>

<ng-template #noDataTemplate>
	<div class="no-data-message">
		{{ 'DATA_WHEEL.NO_DATA_MESSAGE' | translate }}
	</div>
</ng-template>