import { trigger, animate, style, transition } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { ANIMATION_DURATION } from '@snappet-content-products/data-wheel/domain';
import { Platform } from '@angular/cdk/platform';

@Component({
	selector: 'cp-data-wheel-magnifying-glass',
	templateUrl: './data-wheel-magnifying-glass.component.html',
	styleUrls: ['./data-wheel-magnifying-glass.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(100%)' }),
				animate(
					`${ANIMATION_DURATION}ms ease-in-out`,
					style({ opacity: 1, transform: 'translateY(0)' })
				)
			]),
			transition(':leave', [
				style({ opacity: 1, transform: 'translateY(0)' }),
				animate(
					`${ANIMATION_DURATION}ms ease-in-out`,
					style({ opacity: 0, transform: 'translateY(-100%)' })
				)
			])
		])
	]
})
export class DataWheelMagnifyingGlassComponent {
	@Input() id: number;
	@Input() template: TemplateRef<unknown>;

	constructor(private readonly platform: Platform) { }

	get isIOS(): boolean {
		return this.platform.IOS;
	}
}
