import { ConnectedPosition } from '@angular/cdk/overlay';

export class OverlayArrow {
	static outerCssClass = 'arrow';
	static innerBeforeCssClass = 'arrow-before';
	static innerAfterCssClass = 'arrow-after';
}

export const overlayPositions: {
	south: ConnectedPosition;
	southWest: ConnectedPosition;
	southEast: ConnectedPosition;
	north: ConnectedPosition;
	northEast: ConnectedPosition;
	northWest: ConnectedPosition;
} = {
	south: {
		originX: 'center',
		originY: 'bottom',
		overlayX: 'center',
		overlayY: 'top',
		panelClass: 's',
		offsetY: 0,
		offsetX: 0
	},
	southWest: {
		originX: 'end',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'top',
		panelClass: 'sw',
		offsetY: 0,
		offsetX: 20
	},
	southEast: {
		originX: 'start',
		originY: 'bottom',
		overlayX: 'start',
		overlayY: 'top',
		panelClass: 'se',
		offsetY: 0,
		offsetX: -20
	},
	north: {
		originX: 'center',
		originY: 'top',
		overlayX: 'center',
		overlayY: 'bottom',
		panelClass: 'n',
		offsetY: 0,
		offsetX: 0
	},
	northEast: {
		originX: 'start',
		originY: 'top',
		overlayX: 'start',
		overlayY: 'bottom',
		panelClass: 'ne',
		offsetY: 0,
		offsetX: -20
	},
	northWest: {
		originX: 'end',
		originY: 'top',
		overlayX: 'end',
		overlayY: 'bottom',
		panelClass: 'nw',
		offsetY: 0,
		offsetX: 20
	}
};
