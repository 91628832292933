import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataFilter, DataWheelContext, DataWheelView, DataEntities } from '@snappet-content-products/data-wheel/domain';
import { environment } from '@snappet-content-products/shared/environments';
import { Market } from '@snappet/enums/dist';

@Component({
	selector: 'cp-top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
	@Input() entities: DataEntities;
	@Input() market: Market;
	@Input() selectedView: DataWheelView;
	@Input() filter: DataFilter;

	@Input() dataWheelContext: DataWheelContext;

	@Output() readonly filterChange = new EventEmitter<DataFilter>();

	@Output() readonly logoutClick = new EventEmitter<void>();
	@Output() readonly loginClick = new EventEmitter<void>();

	safeLogoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.sharedAssetsLocation + '/assets/images/snappet-blue.svg');

	constructor(private readonly sanitizer: DomSanitizer) { }
}
