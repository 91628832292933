<div class="spinner-container-outer"
	 aria-label="Loading">
	<div class="spinner-container">
		<div class="spinner-container-inner">
			<div class="spinner-mask left">
				<svg preserveAspectRatio="xMidYMid meet"
					 viewBox="0 0 100 100">
					<circle cx="50%"
							cy="50%"
							r="45"
							class="cut-off-bottom"
							[style.clipPath]="'url(#' + cutOffBottomLeftId +')'"></circle>
				</svg>
			</div>
			<div class="spinner-mask right">
				<svg preserveAspectRatio="xMidYMid meet"
					 viewBox="0 0 100 100">
					<circle cx="50%"
							cy="50%"
							r="45"
							class="cut-off-bottom"
							[style.clipPath]="'url(#' + cutOffBottomRightId +')'"></circle>
				</svg>
			</div>
		</div>
	</div>
</div>
<svg class="loader-spinner-defs"
	 width="0"
	 height="0"
	 viewBox="0 0 0 0">
	<defs>
		<clipPath [id]="cutOffBottomLeftId">
			<path d="m 0 0 l 60 0 l 0 50 l -50 50 l -10 0 l 0 -100"></path>
		</clipPath>
		<clipPath [id]="cutOffBottomRightId">
			<path d="m 0 0 l 100 0 l 0 100 l -10 0 l -50 -50 l 0 -50"></path>
		</clipPath>
	</defs>
</svg>
