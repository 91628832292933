import { Injectable } from '@angular/core';
import { LoginFormControl } from '@snappet-content-products/shared/angular';
import { Market } from '@snappet/enums/dist';

@Injectable()
export class AppService {
	getLoginFormControls(input: {
		market: Market;
		context: string;
		loColors: string;
	}): LoginFormControl[] {
		return [
			{
				key: 'market',
				type: 'select',
				value: input.market,
				dataTest: 'market',
				label: 'Market:',
				options: [
					Market.Nl,
					Market.Es,
					Market.Us
				]
			},
			{
				key: 'context',
				type: 'select',
				value: input.context,
				dataTest: 'context',
				label: 'Context:',
				options: [
					'website',
					'dashboard'
				]
			},
			{
				key: 'loColors',
				type: 'text',
				value: input.loColors,
				dataTest: 'lo-colors'
			}
		];
	}
}
