
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExerciseThumbnailComponent } from './exercise-thumbnail.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		ExerciseThumbnailComponent
	],
	exports: [
		ExerciseThumbnailComponent
	]
})
export class ExerciseThumbnailModule { }
