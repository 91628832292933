import { InjectionToken } from '@angular/core';
import { environment } from '@snappet-content-products/shared/environments';
import { AuthConfig } from 'angular-oauth2-oidc';

export type Region = 'eu' | 'us';

export const AUTH_CONFIG = new InjectionToken<AuthConfig>('auth.config');

export abstract class BaseAuthConfig extends AuthConfig {
	issuer = environment.authServer.eu;
	redirectUri: string;
	postLogoutRedirectUri: string;
	sessionChecksEnabled = false;
	responseType = 'code';
	showDebugInformation = true;
	strictDiscoveryDocumentValidation = false;

	constructor() {
		super();

		this.redirectUri = `${window.location.origin}${window.location.pathname}`.replace(/\/$/, '');
		this.postLogoutRedirectUri = this.redirectUri;

		const region = localStorage.getItem('region') as Region || 'eu';
		const issuer = region === 'eu' ? environment.authServer.eu : environment.authServer.us;

		this.issuer = issuer;
	}
}
