import { RegionConfig } from '../region-config';

export const regionConfig: RegionConfig = {
	authorizationApiUrls: {
		bff: 'https://content-products.content.metro.snappet.org/api/bff/eu',
		answersRead: 'https://prod-answers-read.snappet.org/api/v1',
		teach: 'https://content-products.content.metro.snappet.org/api/teach/eu/v1',
		ability: 'https://content-products.content.metro.snappet.org/api/ability/eu/v1',
		browserSnapshotService: 'https://browser-snapshots.snappet.org',
		loStatus: 'https://content-products.content.metro.snappet.org/api/lo-status/eu/v3',
		pupilApp: 'https://pupil.snappet.org/graphql'
	}
};
