import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

class PopoverState {
	trigger?: HTMLElement;
	open: boolean;
}

@Injectable()
export class PopoverService {
	private readonly state$ = new BehaviorSubject<PopoverState>({ open: false });

	getState(): Observable<PopoverState> {
		return this.state$.asObservable();
	}

	setState(value: PopoverState) {
		return this.state$.next(value);
	}
}
