import { ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, filter, map, Subject, takeUntil, tap } from 'rxjs';
import { IconsService } from './icons.service';

@Component({
	selector: 'cp-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit, OnChanges, OnDestroy {
	@Input() iconId = '';
	@Input() isColored = false;
	@Input() class = '';

	@HostBinding('attr.class') classAttr: string | undefined;

	ariaLabelledBy: string | null | undefined;
	ariaDescribedBy: string | null | undefined;
	iconViewBox: string | null;
	safeIcon: SafeResourceUrl | undefined;
	icon: SVGElement;

	isLoading$ = this.iconsService.svgSpriteDoc$.pipe(
		map(doc => !doc)
	);

	private readonly iconSubject = new BehaviorSubject<string>(null);
	private readonly destroy$ = new Subject<void>();

	constructor(
		private readonly cd: ChangeDetectorRef,
		private readonly iconsService: IconsService,
		private readonly sanitizer: DomSanitizer,
		private readonly elementRef: ElementRef<HTMLElement>
	) { }

	get type(): 'Colored' | '' {
		return this.isColored ? 'Colored' : '';
	}

	ngOnInit(): void {
		combineLatest([
			this.iconsService.svgSpriteDoc$.pipe(filter(doc => !!doc)),
			this.iconSubject.pipe(filter(icon => !!icon))
		]).pipe(
			tap(([doc]) => this.readSvgSpriteFromDocument(doc)),
			takeUntil(this.destroy$)
		).subscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['iconId'] || changes['isColored']) {
			this.iconSubject.next(this.iconId);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private readSvgSpriteFromDocument(svgSpriteDoc: Document): void {
		this.icon = svgSpriteDoc.querySelector(`symbol#${this.iconId}${this.type}`);

		if (!this.icon) {
			return;
		}

		const title = svgSpriteDoc.querySelector(`symbol#${this.iconId}${this.type} title`);
		const description = svgSpriteDoc.querySelector(`symbol#${this.iconId}${this.type} desc`);

		this.iconViewBox = this.icon.getAttribute('viewBox') || null;
		this.safeIcon = this.sanitizer.bypassSecurityTrustHtml(this.icon.innerHTML);
		this.ariaLabelledBy = title?.getAttribute('id');
		this.ariaDescribedBy = description?.getAttribute('id');

		// eslint-disable-next-line max-len
		this.classAttr = `${this.elementRef.nativeElement.className.replace(/\b\w*icon[\w-]*\w*\b/g, '').trim()} ${this.class} icon icon-${this.iconId.toLowerCase()}${this.type.toLowerCase()}`.trim();

		this.cd.detectChanges();
	}
}
