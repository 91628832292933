import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { OverlayArrow, overlayPositions } from '../overlay/overlay.const';
import { DropdownOption } from './dropdown-option.model';
import { DropdownTriggerDirective } from './dropdown-trigger.directive';
import { Subject, delay, merge, takeUntil } from 'rxjs';

@Component({
	selector: 'cp-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements AfterViewInit, OnDestroy {
	@Input() optionTemplate: TemplateRef<unknown>;
	@Input() labelTemplate: TemplateRef<unknown>;
	@Input() selectedOptionTemplate: TemplateRef<unknown>;
	@Input() options: DropdownOption[];
	@Input() isLoading: boolean;
	@Input() disabled: boolean;
	@Input() placeholder = '';
	@Input() multiSelect = false;
	@Input() size: 'small' | 'large' | 'xs' = 'large';
	@Input() color = 'default';

	@Output() readonly selectedOptionsChange = new EventEmitter<DropdownOption[]>();

	@ViewChild(DropdownTriggerDirective) trigger: DropdownTriggerDirective;

	dropdownPositions = Object.values(overlayPositions);
	isOpen: boolean;
	overlayArrow = OverlayArrow;

	private _selectedOptions: DropdownOption[];
	private readonly destroy$ = new Subject<void>();

	constructor(private readonly cd: ChangeDetectorRef) { }

	get dropdownLabel(): string {
		return this.selectedOptions.length ? this.selectedOptions.map(o => o.label).join(', ') : this.placeholder;
	}

	get maxHeight() {
		const { top, bottom } = this.trigger.elementRef.nativeElement.getBoundingClientRect();

		const offsetBottom = window.innerHeight - bottom;
		const overlayPanePadding = 20;

		if (offsetBottom > top) {
			return window.innerHeight - bottom - overlayPanePadding;
		}

		return top - overlayPanePadding;
	}

	get selectedOptions(): DropdownOption[] {
		return this.options?.filter(option => this._selectedOptions ? this._selectedOptions.find(o => o.value === option.value) : option.selected);
	}

	@Input() set selectedOptions(options: DropdownOption[]) {
		this._selectedOptions = options;
	}

	ngAfterViewInit() {
		merge(this.trigger.opened, this.trigger.closed).pipe(
			delay(0),
			takeUntil(this.destroy$)
		).subscribe(() => {
			this.isOpen = this.trigger.isOpen();
			this.cd.detectChanges();
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	trackByFn(_: number, option: DropdownOption): string {
		return option.value;
	}

	isSelected(option: DropdownOption): boolean {
		return !!this.selectedOptions.find(o => o === option);
	}

	onDropdownOptionTriggered(option: DropdownOption): void {
		if (this.multiSelect) {
			const found = this.selectedOptions.find(o => o.value === option.value);

			if (found) {
				this.selectedOptions = this.selectedOptions.filter(o => o.value !== option.value);
			} else {
				this.selectedOptions = [...this.selectedOptions, option];
			}
		} else {
			this.selectedOptions = [option];
			this.trigger.close();
		}

		this.selectedOptionsChange.emit(this.selectedOptions);
		this.cd.detectChanges();
	}
}
