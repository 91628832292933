/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { SignedCookieResponse } from '@snappet/signed-cookies';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { environment } from '@snappet-content-products/shared/environments';
import { Region } from './auth-config/auth-config';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private readonly requiresAuthenticationSubject = new BehaviorSubject<boolean>(true);
	requiresAuthentication$ = this.requiresAuthenticationSubject.asObservable();

	private readonly tokenSubject = new BehaviorSubject<string>('');
	token$ = this.tokenSubject.asObservable().pipe(
		distinctUntilChanged()
	);

	private readonly signedCookieResponseSubject = new BehaviorSubject<SignedCookieResponse>(null);
	signedCookieResponse$ = this.signedCookieResponseSubject.asObservable().pipe(
		distinctUntilChanged()
	);

	authenticated$ = combineLatest([this.token$, this.signedCookieResponse$, this.requiresAuthentication$]).pipe(
		filter(([token, status, requiresAuthentication]) => requiresAuthentication ? !!this.getValidatedToken(token) && status !== null : true)
	);

	isTrial$ = this.token$.pipe(
		filter(token => !!token),
		map(token => jwt_decode(token)['is_trial'])
	);

	classId$ = this.token$.pipe(
		filter(token => !!token),
		map(token => jwt_decode(token)['class_id'])
	);

	schoolId$ = this.token$.pipe(
		filter(token => !!token),
		map(token => Number(jwt_decode(token)['school_id']))
	);

	region$ = this.token$.pipe(
		map(token => this.mapTokenToRegion(token))
	);

	setToken(token: string): void {
		this.tokenSubject.next(token);
	}

	setSignedCookie(response: SignedCookieResponse): void {
		this.signedCookieResponseSubject.next(response);
	}

	setRequiresAuthentication(requiresAuthentication: boolean): void {
		this.requiresAuthenticationSubject.next(requiresAuthentication);
	}

	private mapTokenToRegion(token: string): Region {
		if (!token) {
			return null;
		}

		const decodedToken = jwt_decode(token);
		const iss = decodedToken['iss'];

		switch (iss) {
			case environment.authServer.us:
				return 'us';
			case environment.authServer.eu:
			default:
				return 'eu';
		}
	}

	private getValidatedToken(token: string): string {
		if (!token) {
			return null;
		}

		const expirationTimeSeconds = Number(jwt_decode(token)['exp']);
		const nowTimeSeconds = Date.now() / 1000;
		const defaultClockToleranceSeconds = 300;

		if (expirationTimeSeconds >= nowTimeSeconds - defaultClockToleranceSeconds) {
			return token;
		}

		return null;
	}
}
