import { enableProdMode } from '@angular/core';

export function enableProdModeWrapper(): void {
	try {
		enableProdMode();
	} catch {
		// Running multiple micro-frontends which both call enableProdModeWrapper can trigger an [Cannot enable prod mode after platform setup.] error.
		// This is likely because they DO share the Angular runtime
		// and the devmode toggle is shared globally: https://github.com/angular/angular/blob/master/packages/core/src/util/is_dev_mode.ts
	}
}
