import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { windowProviders } from './window.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '@snappet-content-products/shared/environments';
import { ResizeObserverDirective } from './resize-observer.directive';
import { StaticContentComponent } from './static-content/static-content.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, environment.sharedAssetsLocation + '/assets/i18n/', '.json');
}

@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
		HttpClientModule
	],
	declarations: [
		ResizeObserverDirective,
		StaticContentComponent
	],
	exports: [
		CommonModule,
		TranslateModule,
		ResizeObserverDirective,
		StaticContentComponent
	],
	providers: [
		windowProviders
	]
})
export class SharedUtilModule { }
