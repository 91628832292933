export enum ColorEnum {
	Dark = '#333333',
	Primary = '#c32451',
	Secondary = '#34a3d7',
	Orange = '#e95f15',
	Yellow = '#f6cf19',
	Gray = '#ededed',
	GrayLight = '#f7f7f9',
	GrayDarker = '#949494',
	GrayDark = '#c2c2c2'
}
