import { Directive, ViewContainerRef, TemplateRef, Input } from '@angular/core';

@Directive({
	selector: '[ifChanges]'
})
export class IfChangesDirective {
	private currentValue: unknown;
	private hasView = false;

	constructor(
		private readonly viewContainer: ViewContainerRef,
		private readonly templateRef: TemplateRef<unknown>
	) { }

	@Input() set ifChanges(val: unknown) {
		if (!this.hasView) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.hasView = true;
		} else if (val !== this.currentValue) {
			this.viewContainer.clear();
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.currentValue = val;
		}
	}
}
