import { enableProdModeWrapper } from '@snappet-content-products/shared/util';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgZone } from '@angular/core';

import { AppModule } from './app/app.module';

declare global {
	interface Window {
		sharedMFEZone: NgZone;
	}
}

enableProdModeWrapper();

platformBrowserDynamic()
	.bootstrapModule(AppModule, { ngZone: (window as Window).sharedMFEZone })
	.catch(err => console.error(err));
