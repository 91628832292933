import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PupilAppSettingsService } from './application-services';
import {
	AbilityService, ActivitySuggestionService, BrowserSnapshotService, ContentService, ExerciseDifficultyService,
	LoStatusService, PupilService, ReadSpeakerService, TeachService
} from './infrastructure';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		BrowserAnimationsModule
	],
	providers: [
		ContentService,
		AbilityService,
		PupilService,
		ExerciseDifficultyService,
		BrowserSnapshotService,
		PupilAppSettingsService,
		TeachService,
		LoStatusService,
		ReadSpeakerService,
		ActivitySuggestionService
	]
})
export class SharedDomainModule { }
