export enum AudioFormatEnum {
	Mp3 = 'mp3',
	Flv = 'flv',
	Ogg = 'ogg',
	Xml = 'xml'
}

export enum OutputEnum {
	Audiolink = 'audiolink',
	Audio = 'audio'
}

export enum SynchronisationEnum {
	Word = 'word ',
	Sent = 'sent ',
	Wordsent = 'wordsent',
	Sentword = 'sentword',
	Html5iframe = 'html5iframe'
}
