import { Component, HostBinding, Input } from '@angular/core';
import { uuidv4 } from '@snappet-content-products/shared/node';

@Component({
	selector: 'cp-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	host: { 'class': 'spinner' }
})
export class SpinnerComponent {
	@Input() @HostBinding('class.colored') isColored = false;

	cutOffBottomLeftId: string = uuidv4();
	cutOffBottomRightId: string = uuidv4();
}
