/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { CdkMenuTrigger, MENU_TRIGGER, PARENT_OR_NEW_MENU_STACK_PROVIDER } from '@angular/cdk/menu';
import { AfterViewInit, Directive, ElementRef, Inject, OnDestroy } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { OverlayObserver } from '../overlay/overlay-observer';
import { WINDOW } from '@snappet-content-products/shared/util';

@Directive({
	selector: '[cpDropdownTriggerFor]',
	exportAs: 'cpDropdownTriggerFor',
	inputs: ['menuTemplateRef: cpDropdownTriggerFor', 'menuPosition: cdkMenuPosition'],
	outputs: ['opened: cdkMenuOpened', 'closed: cdkMenuClosed'],
	providers: [
		{ provide: MENU_TRIGGER, useExisting: CdkMenuTrigger },
		PARENT_OR_NEW_MENU_STACK_PROVIDER
	]
})
export class DropdownTriggerDirective extends CdkMenuTrigger implements AfterViewInit, OnDestroy {
	private readonly overlayObserver: OverlayObserver;
	private readonly destroy$ = new Subject<void>();

	constructor(
		readonly elementRef: ElementRef<HTMLElement>,
		@Inject(WINDOW) private readonly window: Window
	) {
		super();

		this.overlayObserver = new OverlayObserver(this.elementRef.nativeElement);
	}

	ngAfterViewInit(): void {
		fromEvent(this.window, 'resize').pipe(
			tap(() => this.close()),
			takeUntil(this.destroy$)
		).subscribe();

		this.opened.pipe(
			delay(0),
			takeUntil(this.destroy$)
		).subscribe(() => {
			this.overlayObserver.observeOverlay(this.overlayRef.overlayElement);
		});
	}

	ngOnDestroy(): void {
		this.overlayObserver.disconnect();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
