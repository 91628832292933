<cp-app-settings-panel id="dataWheel">
	<cp-login-flow class="login"
				   [formControls]="formControls"
				   (inputChange)="onInputChange($event)">
	</cp-login-flow>
</cp-app-settings-panel>
<ng-container *ngIf="showDataWheel">
	<cp-data-wheel class="data-wheel"
				   [lang]="lang"
				   [market]="market"
				   [context]="context"
				   [loColors]="loColors"
				   (lessonClick)="onLessonClick($event)"
				   (loginClick)="onLoginClick()"
				   (logoutClick)="onLogoutClick()"></cp-data-wheel>
</ng-container>