import { IconUrlService } from './services/icon-url.service';
// eslint-disable-next-line max-len
export const loIconFallbackUrl = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHRpdGxlPkltZzwvdGl0bGU+PGcgaWQ9IkxheWVyXzUiIGRhdGEtbmFtZT0iTGF5ZXIgNSI+PHBhdGggZD0iTTI1LjEsMjEuNDZsLTMuMzEtNS4xYTEuNTUsMS41NSwwLDAsMC0yLjY5LS4xbC0yLjM5LDMuMWExLjY5LDEuNjksMCwwLDEtMi44MiwwbC0xLjI0LTEuNjJhMS42NywxLjY3LDAsMCwwLTIuOCwwTDcsMjEuNTVjLS43NywxLS4zNSwxLjg1Ljk0LDEuODVIMjRjMS4yOSwwLDEuNzctLjg3LDEuMDctMS45NFoiLz48cGF0aCBkPSJNMTQuODgsMTMuODhhMi42NCwyLjY0LDAsMSwwLTIuNjYtMi42NEEyLjY2LDIuNjYsMCwwLDAsMTQuODgsMTMuODhaIi8+PC9nPjwvc3ZnPg==';

export function getResizedLoIconUrl(
	sourceUrl = loIconFallbackUrl,
	width = 100, height = 100,
	environment: 'master' | 'production' = 'production',
	fallbackUrl = loIconFallbackUrl): string {
	const service = new IconUrlService(sourceUrl, environment);

	try {
		return service.convertToImageHandlerUrl(width, height);
	} catch {
		return fallbackUrl;
	}
}
