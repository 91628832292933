import { Environment } from './environment.type';

export const environment = {
	name: 'production' as Environment,
	production: true,
	rayGunKey: {
		digiboard: '4DN3K0FGq9a15f9hK557A',
		exercisePlayer: 'K0baUJmBq1EkmKEZaqQsWQ',
		lessonPrepare: '7uKwFqVm5IcS66VZXic4A',
		learningMaterials: 'T4UawXq2njYqed4ZUeRYg',
		dataWheel: ''
	},
	lessonPrepareBaseUrl: 'https://content-products.content.metro.snappet.org/lesson-prepare-app/v2',
	sharedAssetsLocation: 'https://content-products.content.metro.snappet.org/shared-assets/v3',
	authServer: {
		eu: 'https://auth.snappet.org',
		us: 'https://us.auth.snappet.org'
	},
	contentApiUrl: 'https://api.content.metro.snappet.org/v1',
	helpPageUrl: 'https://help-pages.snappet.org',
	loCardUrl: 'https://learning-objectives.snappet.org/cards',
	loIconUrl: 'https://learning-objectives.snappet.org/icons',
	exerciseImageUrl: 'https://exercises.snappet.org',
	exerciseDifficulty: 'https://exercise-difficulty.content.metro.snappet.org',
	authorizationApiUrls: {
		readingCorner: 'https://reading-corner.content.metro.snappet.org',
		readSpeaker: 'https://readspeaker-api.content.metro.snappet.org'
	},
	readingCornerWebsite: 'https://app.thereadingcorner.tv',
	countryIds: {
		spain: 3,
		netherlands: 1,
		unitedStates: 10
	}
};
