import { FileLocation } from '../models/file-location.model';
import { ImageResizeRequest } from '../models/image-resize.model';

export class IconUrlService {
	constructor(
		private source: string,
		private environment: 'master' | 'production') { }

	convertToImageHandlerUrl(width: number, height: number): string {
		const url = new URL(this.source);

		this.validateUrlProtocol(url);

		const fileLocation = this.getFileLocation(url);

		const request = {
			bucket: fileLocation.bucket,
			key: fileLocation.key,
			edits: {
				resize: {
					width: Math.round(width),
					height: Math.round(height),
					fit: 'fill'
				}
			}
		} as ImageResizeRequest;

		const requestString = JSON.stringify(request);
		const imagePath = btoa(requestString);

		const imageUrl = `https://${this.baseUrl()}/${imagePath}${url.search}`;

		return imageUrl;
	}

	private getFileLocation(url: URL): FileLocation {
		const fileName = url.pathname.substr(1);

		return {
			bucket: url.hostname,
			key: fileName
		} as FileLocation;
	}

	private validateUrlProtocol(url: URL) {
		if (url.protocol !== 'https:') {
			throw new Error('not valid icon');
		}
	}

	private baseUrl() {
		if (this.environment === 'master') {
			return 'master-lo-icons-handler.snappet.org';
		} else {
			return 'prod-lo-icons-handler.snappet.org';
		}
	}
}
