import { DataItem } from './data-item.model';
import { MAX_ZOOM_FACTOR } from './data-wheel.consts';
import { EntityTypeEnum } from './entities';

export function shouldEnlargeLeaf(radiusZoomFactor: number, leaf: DataItem, root: DataItem) {
	const thirdLayerIsRoot = leaf?.parent?.parent?.guid === root?.guid;

	return radiusZoomFactor === MAX_ZOOM_FACTOR
		&& thirdLayerIsRoot
		&& (leaf?.type === EntityTypeEnum.LO || leaf?.type === EntityTypeEnum.Lesson);
}
