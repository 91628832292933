import { Injector, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function defineCustomElement(injector: Injector, tagName: string, element: Type<any>): void {
	if (customElements.get(tagName) !== undefined) {
		return;
	}

	const customElement = createCustomElement(element, { injector });

	customElements.define(tagName, customElement);
}
