/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { RaygunPayload, RaygunStatic } from 'raygun4js';

const RAYGUN_VERSION_NUMBER = '1.0.0.5';

export class RaygunService {
	raygunInstance: RaygunStatic;

	private readonly stripUrls = [
		{
			regexp: /api\/v1\/pupils\/(\d+)\/answers\/(\w+)\/attempts/gi,
			replace: 'api/v1/pupils/hidden/answers/$2/attempts'
		}
	];

	constructor(raygunKey: string) {
		const raygunOptions = {
			ignoreAjaxAbort: false,
			ignoreAjaxError: false,
			debugMode: false,
			ignore3rdPartyErrors: false,
			excludedUserAgents: ['PhantomJS'],
			pulseIgnoreUrlCasing: true
		};
		const sensitiveDataFilter = ['userId', 'pupilId', 'teacherId'];

		try {
			const raygun: RaygunStatic = (window as any).Raygun;

			if (raygun) {
				this.raygunInstance = raygun.constructNewRaygun();
				this.raygunInstance.init(raygunKey, raygunOptions);
				this.raygunInstance.setVersion(RAYGUN_VERSION_NUMBER);
				this.raygunInstance.disableAutoBreadcrumbs(); // If we enable it we have to think about hiding PII!
				this.raygunInstance.filterSensitiveData(sensitiveDataFilter);
				this.raygunInstance.onBeforeSend(payload => this.filterPayload(payload));
			}
		} catch (e) {
			console.error('Raygun init error', e);
		}
	}

	destroy(): void {
		if (this.raygunInstance) {
			this.raygunInstance = null;
		}
	}

	log(error: Error): void {
		if (this.raygunInstance) {
			this.raygunInstance.send(error);
		}
	}

	filterPayload(payload: RaygunPayload): RaygunPayload {
		if (payload?.Details?.Request?.Url) {
			payload.Details.Request.Url = this.findSensitiveUrl(payload.Details.Request.Url);
		}
		if (payload?.Details?.Request?.Headers?.Referer) {
			payload.Details.Request.Headers.Referer = this.findSensitiveUrl(payload.Details.Request.Headers.Referer);
		}

		return payload;
	}

	private findSensitiveUrl(url: string): string {
		let newUrl = url;

		this.stripUrls.forEach(urlRegex => newUrl = url.replace(urlRegex.regexp, urlRegex.replace));

		return newUrl;
	}
}
