/* eslint-disable @typescript-eslint/member-ordering */
import { FocusableOption, FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnDestroy, ViewEncapsulation } from '@angular/core';

export type ThemePalette = 'primary' | 'secondary' | 'accent' | 'light' | 'warn' | 'default' | undefined;

const BUTTON_HOST_ATTRIBUTES = [
	'cp-button',
	'cp-icon-button'
];

@Component({
	selector: '[cp-button], [cp-icon-button]',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements AfterViewInit, OnDestroy, FocusableOption {
	private _color: ThemePalette;
	private readonly defaultColor = 'primary';
	private _disabled = false;
	private readonly element: HTMLElement;

	@HostBinding('class')
	@Input() size: 'small' | 'large' | 'xs' = 'large';

	@HostBinding('attr.disabled') get valid(): boolean {
		return this.disabled || null;
	}

	@Input()
	get color(): ThemePalette {
		return this._color;
	}

	set color(value: ThemePalette) {
		const colorPalette = value || this.defaultColor;

		if (colorPalette !== this._color) {
			if (this._color) {
				this.elementRef.nativeElement.classList.remove(`sn-${this._color}`);
			}
			if (colorPalette) {
				this.elementRef.nativeElement.classList.add(`sn-${colorPalette}`);
			}

			this._color = colorPalette;
		}
	}

	@Input() get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value: unknown) {
		this._disabled = value != null && `${value}` !== 'false';
	}

	constructor(
		private readonly elementRef: ElementRef<HTMLElement>,
		private readonly _focusMonitor: FocusMonitor
	) {
		this.element = this.elementRef.nativeElement;

		for (const attr of BUTTON_HOST_ATTRIBUTES) {
			if (this.hasHostAttributes(attr)) {
				this.element.classList.add(attr);
			}
		}

		if (this.element.tagName === 'A') {
			this.element.setAttribute('role', 'button');
		}

		this.elementRef.nativeElement.classList.add('cp-button-base');
	}

	ngAfterViewInit(): void {
		this._focusMonitor.monitor(this.elementRef, true);
	}

	ngOnDestroy(): void {
		this._focusMonitor.stopMonitoring(this.elementRef);
	}

	focus(origin: FocusOrigin = 'program', options?: FocusOptions): void {
		this._focusMonitor.focusVia(this.element, origin, options);
	}

	private hasHostAttributes(...attributes: string[]): boolean {
		return attributes.some(attribute => this.element.hasAttribute(attribute));
	}
}
