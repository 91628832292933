/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { DataItem } from '.';
import { CourseGrade, CourseGroup, Cycle, EducationalStandardNode, EntityTypeEnum, LearningObjective, Lesson, Section, SubMarket, Subject } from './entities';
import {
	Activity, ActivityType, ExtendedEducationalStandardNode, ExtendedEducationalStandardTree,
	LessonPlanLessonActivity, ExtendedActivityPlanSectionGroup
} from '@snappet-content-products/shared/domain';

@Injectable()
export class DataPreprocessorService {
	// The GUIDs can be a simple index so they will remain the same as long as the dataset remains the same.
	private guidIndex = 0;

	mapContentDataToNestedDataNodeHierarchy({ subjects, courseGroups, courseGrades, sections, lessons }: {
		subjects: Subject[];
		courseGroups: CourseGroup[];
		courseGrades: CourseGrade[];
		sections: Section[];
		lessons: Lesson[];
	}): DataItem[] {
		const subjectItems: DataItem[] = [];

		for (const subject of subjects) {
			const subjectItem: DataItem = {
				id: subject.subjectId,
				parentId: null,
				parent: null,
				label: subject.name,
				type: EntityTypeEnum.Subject,
				children: [],
				guid: this.getNewGuid(),
				isDefault: subject.isDefault,
				filterIds: []
			};

			const courseGroupsInSubject = courseGroups.filter(g => g.subjectId === subject.subjectId);

			for (const courseGroup of courseGroupsInSubject) {
				const courseGroupItem: DataItem = {
					id: courseGroup.courseGroupId,
					label: courseGroup.name,
					parentId: subjectItem.id,
					parent: subjectItem,
					type: EntityTypeEnum.CourseGroup,
					children: [],
					guid: this.getNewGuid(),
					filterIds: courseGroup.subMarketIds,
					subMarketIds: courseGroup.subMarketIds
				};

				const courseGradesInCourseGroup = courseGrades.filter(g => g.courseGroupId === courseGroup.courseGroupId);

				for (const courseGrade of courseGradesInCourseGroup) {
					const courseGradeItem: DataItem = {
						id: courseGrade.gradeId,
						label: courseGrade.gradeName,
						parentId: courseGroupItem.id,
						parent: courseGroupItem,
						type: EntityTypeEnum.Grade,
						children: [],
						guid: this.getNewGuid(),
						filterIds: []
					};

					const sectionsInCourseGrade = sections.filter(s => s.p === courseGrade.courseId);

					for (const section of sectionsInCourseGrade) {
						const lessonsInSection = lessons.filter(l => l.p === section.i);

						if (lessonsInSection.length > 0) {
							const sectionItem: DataItem = {
								id: section.i,
								label: section.l,
								parentId: courseGradeItem.id,
								parent: courseGradeItem,
								type: EntityTypeEnum.Section,
								children: [],
								guid: this.getNewGuid(),
								filterIds: []
							};

							for (const lesson of lessonsInSection) {
								const lessonItem: DataItem = {
									id: lesson.i,
									loId: lesson.lo,
									label: lesson.l,
									parentId: sectionItem.id,
									isExam: lesson.e,
									parent: sectionItem,
									type: EntityTypeEnum.Lesson,
									children: [],
									guid: this.getNewGuid(),
									filterIds: []
								};

								sectionItem.children.push(lessonItem);
							}

							courseGradeItem.children.push(sectionItem);
						}
					}

					courseGroupItem.children.push(courseGradeItem);
				}

				courseGroupItem.children.sort((a, b) => this.sortGrade(a) - this.sortGrade(b));

				subjectItem.children.push(courseGroupItem);
			}

			subjectItems.push(subjectItem);
		}

		return subjectItems;
	}

	sortGrade(grade: DataItem) {
		const gradeNumber = grade.label.split(' ')[1];

		return isNaN(Number(gradeNumber)) ? gradeNumber.charCodeAt(0) : Number(gradeNumber) + 1000;
	}

	mapEducationalStandardDataToNestedEntityHierarchy(educationalStandards: EducationalStandardNode[]): DataItem[] {
		return educationalStandards.map(educationalStandard => this.toDataItem(educationalStandard, null));
	}

	mapExtendedSectionGroupsToNestedDataNodeHierarchy(extendedSectionGroups: ExtendedActivityPlanSectionGroup[]): DataItem[] {
		const sectionGroupItems: DataItem[] = [];

		const showSectionGroups = extendedSectionGroups.length > 1;

		for (const sectionGroup of extendedSectionGroups) {
			const sectionGroupId = sectionGroup.sectionGroupId;
			const sectionGroupType = EntityTypeEnum.Section;
			const sectionGroupGuid = `${sectionGroupType}_${sectionGroupId}`;

			const sectionGroupItem: DataItem = {
				id: sectionGroupId,
				type: sectionGroupType,
				guid: sectionGroupGuid,
				label: sectionGroup.sectionGroup,
				parentId: null,
				parent: null,
				children: [],
				filterIds: []
			};

			for (const section of sectionGroup.activitySections) {
				const activitySlots = section.activitySlots;

				if (activitySlots.length > 0) {
					const sectionId = section.sectionId;
					const sectionType = EntityTypeEnum.Section;
					const sectionGuid = `${sectionType}_${sectionId}`;

					const sectionItem: DataItem = {
						id: sectionId,
						type: sectionType,
						guid: sectionGuid,
						label: section.name,
						parentId: sectionGroupItem.id,
						parent: sectionGroupItem,
						children: [],
						filterIds: []
					};

					for (const activitySlot of activitySlots) {
						for (const activity of activitySlot.activities) {
							const lessonId = activity.activityId;
							const lessonType = EntityTypeEnum.Lesson;
							const lessonGuid = `${lessonType}_${lessonId}`;

							const lessonItem: DataItem = {
								id: lessonId,
								type: lessonType,
								guid: lessonGuid,
								label: '',
								parentId: sectionItem.id,
								parent: sectionItem,
								children: [],
								value: 1,
								filterIds: [],
								item: activity
							};

							if (activity.activityType === ActivityType.Lesson) {
								const lessonActivity = (activity as Activity<LessonPlanLessonActivity>).item;

								lessonItem.lessonId = lessonActivity.lessonId;
								lessonItem.label = lessonActivity.name;
								lessonItem.loId = lessonActivity.isExam ? null : lessonActivity.learningObjectiveId;
								lessonItem.isExam = lessonActivity.isExam;
							}

							if (showSectionGroups) {
								lessonItem.parent = sectionGroupItem;
								lessonItem.parentId = sectionGroupItem.id;

								sectionGroupItem.children.push(lessonItem);
							} else {
								sectionItem.children.push(lessonItem);
							}
						}
					}

					if (!showSectionGroups) {
						sectionGroupItems.push(sectionItem);
					}
				}
			}

			if (showSectionGroups) {
				sectionGroupItems.push(sectionGroupItem);
			}
		}

		return sectionGroupItems;
	}

	mapExtendedEducationalStandardTreesToNestedEntityHierarchy(extendedEducationalStandardTrees: ExtendedEducationalStandardTree[]): DataItem[] {
		const treeItems: DataItem[] = [];

		for (const tree of extendedEducationalStandardTrees) {
			const id = tree.educationalStandardTreeId;
			const type = EntityTypeEnum.EducationalStandard;
			const guid = `${type}_${id}`;

			const treeItem: DataItem = {
				id,
				type,
				guid,
				label: tree.name,
				parentId: null,
				parent: null,
				children: [],
				filterIds: []
			};

			for (const node of tree.educationalStandardNodes) {
				const nodeItem = this.mapExtendedEducationalStandardNode(node, treeItem);

				treeItem.children.push(nodeItem);
			}

			treeItems.push(treeItem);
		}

		return treeItems;
	}

	mapCyclesToDataNodes(cycles: Cycle[]): DataItem[] {
		return cycles.map(({ cycleId, name }) => ({
			id: cycleId,
			parentId: null,
			parent: null,
			label: name,
			type: EntityTypeEnum.Cycle,
			children: [],
			guid: this.getNewGuid(),
			filterIds: []
		}));
	}

	mapSubMarketsToDataNodes(subMarkets: SubMarket[], courseGroups: CourseGroup[], educationalStandards: EducationalStandardNode[]): DataItem[] {
		const usedSubMarketIds = new Set<number>([
			...courseGroups.flatMap(course => course.subMarketIds || []),
			...educationalStandards.flatMap(standard => standard.subMarketIds || [])
		]);

		// Filter the array of sub markets to only use the ones that are used in courses or educational standards.
		const usedSubMarkets = subMarkets.filter(subMarket => usedSubMarketIds.has(subMarket.subMarketId));

		return usedSubMarkets.map(({ subMarketId, subMarket }) => ({
			id: subMarketId,
			parentId: null,
			parent: null,
			label: subMarket,
			type: EntityTypeEnum.SubMarket,
			children: [],
			guid: this.getNewGuid(),
			filterIds: [],
			isDefault: false
		}));
	}

	mapLearningObjectivesToDataNodes(learningObjectives: LearningObjective[]): DataItem[] {
		const type = EntityTypeEnum.LO;

		return learningObjectives.map(({ id, label }) => ({
			id,
			label,
			type,
			parentId: null,
			parent: null,
			children: [],
			guid: `${type}_${id}`,
			filterIds: []
		}));
	}

	private mapExtendedEducationalStandardNode(node: ExtendedEducationalStandardNode, parent: DataItem): DataItem {
		const id = node.educationalStandardNodeId;
		const type = EntityTypeEnum.StandardNode;
		const guid = `${type}_${id}`;

		const dataItem = {
			id,
			type,
			guid,
			parent,
			label: node.name,
			description: node.description,
			parentId: parent.id,
			children: [],
			filterIds: []
		} as DataItem;

		dataItem.children = node.childEducationalStandardNodes?.map(c => this.mapExtendedEducationalStandardNode(c, dataItem));

		const learningObjectives = node.learningObjectives;

		if (learningObjectives.length > 0) {
			for (const learningObjective of learningObjectives) {
				const loId = learningObjective.learningObjectiveId;
				const loType = EntityTypeEnum.LO;
				// learning objective could be a child of multiple nodes, so we need to make the guid unique
				const loGuid = `${loType}_${id}_${loId}`;

				const loItem: DataItem = {
					loId,
					id: loId,
					label: learningObjective.friendlyName ?? learningObjective.name,
					parentId: id,
					parent: dataItem,
					type: loType,
					children: [],
					guid: loGuid,
					filterIds: [],
					value: 1
				};

				dataItem.children.push(loItem);
			}
		}

		return dataItem;
	}

	private toDataItem(node: EducationalStandardNode, parent: DataItem): DataItem {
		const dataItem = {
			parent,
			id: node.id,
			label: node.label,
			description: node.description,
			parentId: parent?.id,
			type: node.type,
			guid: this.getNewGuid(),
			loId: node.type === EntityTypeEnum.LO ? node.id : null,
			filterIds: parent?.filterIds ?? node.subMarketIds,
			subMarketIds: node.subMarketIds
		} as DataItem;

		dataItem.children = node.children?.map(c => this.toDataItem(c, dataItem));

		return dataItem;
	}

	private getNewGuid() {
		this.guidIndex += 1;
		return this.guidIndex.toString();
	}
}


