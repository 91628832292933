import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconComponent } from './icon.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	imports: [CommonModule, HttpClientModule],
	declarations: [IconComponent],
	exports: [IconComponent]
})
export class IconModule { }
