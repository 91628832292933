import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { DocumentVersion, ExerciseInfo } from '@snappet/exercise-info';

@Component({
	selector: 'cp-exercise-info-thumbnail',
	templateUrl: './exercise-info-thumbnail.component.html',
	styleUrls: ['./exercise-info-thumbnail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExerciseInfoThumbnailComponent {
	@Input() exercise: { exerciseDefinition: string; isInLandscape: boolean; documentVersion: DocumentVersion; exerciseId: number };
	@Input() isWorksheet: boolean;
	@Input() isPractice: boolean;
	@Input() environment: string;

	get exerciseInfo(): ExerciseInfo {
		return new ExerciseInfo(this.exercise);
	}

	get height(): number {
		return this.exerciseInfo.height;
	}

	get width(): number {
		return this.exerciseInfo.width;
	}
}
