import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LoginFormControl } from './login-flow/login-form-control.model';
import { Directive } from '@angular/core';

@Directive()
export abstract class BaseAppComponent {
	formControls: LoginFormControl[] = [];

	constructor(protected readonly oauthService: OAuthService, protected readonly translateService: TranslateService) { }

	get lang(): string | undefined {
		return this.translateService.getBrowserLang();
	}

	tokenHandler = (): Observable<string> => this.oauthService.events.pipe(
		filter(e => e.type === 'token_received' || e.type === 'discovery_document_loaded' && this.oauthService.hasValidIdToken()),
		take(1),
		map(() => this.oauthService.getAccessToken())
	);

	onInputChange(input: { [key: number]: string | boolean }): void {
		Object.keys(input).forEach(key => {
			let value = input[key];

			if (value === 'true' || value === 'false') {
				value = value === 'true';
			}

			value = this.parseIfNumber(value);

			this[key] = value;
			localStorage.setItem(key, value?.toString());
		});
	}

	protected restoreValue(key: string, type: string, defaultValue: unknown) {
		const value = localStorage.getItem(key);

		switch (type) {
			case 'number':
				this[key] = Number.parseInt(value, 10) || defaultValue;
				break;
			case 'boolean':
				this[key] = value === 'true';
				break;
			case 'string':
				this[key] = value || defaultValue;
				break;
			case 'array':
				this[key] = value ? value.split(',').map(v => Number.parseInt(v, 10) || v) : defaultValue;
				break;
			default:
				break;
		}
	}

	private parseIfNumber(value: string): number | string {
		if (/^\d+$/.test(value)) {
			return Number.parseInt(value, 10);
		}
		return value;
	}
}
