import { Injectable } from '@angular/core';
import { DataSelectorService } from './data-selector.service';
import { DataItem } from '../domain';
import { EntityTypeEnum } from '../domain/entities';

@Injectable()
export class DataUtilService {
	constructor(private readonly dataSelectorService: DataSelectorService) { }

	getDepthFromOuterRing(item: DataItem, depth = 0): number {
		if (!item) {
			return null;
		}

		if (item.children.length === 0) {
			return depth;
		}

		return Math.max(...item.children.map(c => this.getDepthFromOuterRing(c, depth + 1)));
	}

	getNthParent(item: DataItem, nth: number, maxNth: number): DataItem {
		if (item.parent && nth < maxNth) {
			return this.getNthParent(item.parent, nth + 1, maxNth);
		}

		return item;
	}

	findParentByType(item: DataItem, type: EntityTypeEnum): DataItem {
		if (!item || item.type === type) {
			return item;
		}

		return this.findParentByType(item.parent, type);
	}

	getFirstVisibleParent(data: DataItem, unfilteredData: DataItem, nodeId: string): string {
		// If the node is the root of the unfiltered data, immediately return.
		if (unfilteredData.guid === nodeId) {
			return nodeId;
		}

		// Get the node from the entire UI data set.
		const node = this.dataSelectorService.getNodeByGuid(unfilteredData.children, nodeId);

		// Edge case when the innermost rings are trimmed, just return the root of all data.
		if (node === null) {
			return unfilteredData.guid;
		}

		// Check if it's visible in the filtered UI data set.
		if (!this.dataSelectorService.getNodeByGuid(data.children, nodeId)) {
			return this.getFirstVisibleParent(data, unfilteredData, node.parent.guid);
		}

		return nodeId;
	}
}
