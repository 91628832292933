import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { environment } from '@snappet-content-products/shared/environments';
import { AUTH_CONFIG, BaseAuthConfig, Region } from '@snappet-content-products/shared/util';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoginFormControl } from './login-form-control.model';
import { branchIdentifier } from '../branch-identifier';

@Component({
	selector: 'cp-login-flow',
	templateUrl: './login-flow.component.html',
	styleUrls: ['./login-flow.component.scss']
})
export class LoginFlowComponent implements OnChanges {
	@Input() formControls!: LoginFormControl[];

	@Output() readonly inputChange = new EventEmitter<{ [key: number]: string | boolean }>();

	region: Region = 'eu';
	formGroup!: UntypedFormGroup;
	branchIdentifier = environment.production ? '' : branchIdentifier;

	constructor(
		private oauthService: OAuthService,
		@Inject(AUTH_CONFIG) private readonly authConfig: BaseAuthConfig
	) {
		this.region = localStorage.getItem('region') as Region || 'eu';

		oauthService.configure(this.authConfig);
		oauthService.loadDiscoveryDocumentAndTryLogin();
	}

	get isLoggedIn(): boolean {
		return this.oauthService.hasValidIdToken();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['formControls']) {
			this.formGroup = this.toFormGroup(this.formControls);
		}
	}

	login(): void {
		this.oauthService.initLoginFlow();
	}

	logout(): void {
		this.oauthService.logOut();
	}

	submit(): void {
		this.inputChange.emit(this.formGroup.value);
	}

	trackByFn(_: number, control: LoginFormControl): string {
		return control.key;
	}

	regionChange(): void {
		localStorage.setItem('region', this.region);
		const issuer = this.region === 'eu' ? environment.authServer.eu : environment.authServer.us;

		this.oauthService.issuer = issuer;
		this.oauthService.loadDiscoveryDocument();
	}

	private toFormGroup(formControls: LoginFormControl[]): UntypedFormGroup {
		const group: { [key: string]: UntypedFormControl } = {};

		formControls.forEach(loginFormControl => group[loginFormControl.key] = new UntypedFormControl(loginFormControl.value));

		return new UntypedFormGroup(group);
	}
}
