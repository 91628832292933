import { RegionConfig } from '../region-config';

export const regionConfig: RegionConfig = {
	authorizationApiUrls: {
		bff: 'https://content-products.content.metro.snappet.org/api/bff/us',
		answersRead: 'https://us.answers-read.snappet.org/api/v1',
		teach: 'https://content-products.content.metro.snappet.org/api/teach/us/v1',
		ability: 'https://content-products.content.metro.snappet.org/api/ability/us/v1',
		browserSnapshotService: 'https://browser-snapshots.legacyus-us.metro.snappet.org',
		loStatus: 'https://content-products.content.metro.snappet.org/api/lo-status/us/v3',
		pupilApp: 'https://us.student.snappet.org/graphql'
	}
};
