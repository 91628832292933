import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RegionConfigService, genericRetryStrategy } from '@snappet-content-products/shared/util';
import { ActivitySuggestion } from '../domain/activity-suggestion.model';

@Injectable()
export class ActivitySuggestionService {
	constructor(private readonly httpClient: HttpClient, private readonly regionConfigService: RegionConfigService) { }

	get apiUrl(): string {
		return this.regionConfigService.config?.authorizationApiUrls.bff;
	}

	getActivitySuggestions(classId: number, subjectGroupId: number): Observable<ActivitySuggestion[]> {
		return this.httpClient.get<ActivitySuggestion[]>(`${this.apiUrl}/classes/${classId}/subjectgroups/${subjectGroupId}/suggestions`).pipe(
			retry(genericRetryStrategy())
		);
	}
}
