import { SignedCookieResponse } from './signed-cookies-response.enum';

export function requestSignedCookies(
	accessToken: string,
	environment: 'master' | 'production' = 'master'): Promise<SignedCookieResponse> {
	const lastRequestTime = 'signed-cookies-last-request-time';
	const checkDelayHours = 3;

	const signedCookiesServiceUrl = environment === 'master' ?
		'https://signed-cookies.content-master.metro.snappet.org/' :
		'https://signed-cookies.content.metro.snappet.org/';

	const now = new Date().getTime();
	const lastCheck = parseInt(sessionStorage.getItem(lastRequestTime), 10) || 0;
	const hoursSinceLastCheck = Math.abs(lastCheck - now) / 36e5;

	if (hoursSinceLastCheck < checkDelayHours) {
		return Promise.resolve(SignedCookieResponse.Unknown);
	}

	const handleErrors = (response: Response) => {
		if (!response.ok || response.status !== 200) {
			throw Error(response.statusText);
		}
		return response;
	};

	return new Promise<SignedCookieResponse>((resolve) => {
		fetch(signedCookiesServiceUrl, {
			method: 'GET',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'include',
			headers: {
				'x-content-authorization': 'Bearer ' + accessToken,
				'ngsw-bypass': 'true'
			}
		})
			.then(response => handleErrors(response))
			.then(() => {
				sessionStorage.setItem(lastRequestTime, now.toString());
				resolve(SignedCookieResponse.Ok);
			})
			.catch(() => resolve(SignedCookieResponse.Error));
	});
}
