import { registerLocaleData } from '@angular/common';
import localeCa from '@angular/common/locales/ca';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeEu from '@angular/common/locales/eu';
import localeGl from '@angular/common/locales/gl';
import localeNl from '@angular/common/locales/nl';
import { Component, HostBinding, Input, OnChanges, OnDestroy, SimpleChanges, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@snappet-content-products/shared/environments';
import { AuthService, MarketService, RegionConfigService } from '@snappet-content-products/shared/util';
import { Market } from '@snappet/enums/dist';
import { requestSignedCookies } from '@snappet/signed-cookies';
import { Observable, Subject, combineLatest, defer } from 'rxjs';
import { filter, retry, switchMap, takeUntil, tap } from 'rxjs/operators';
import { branchIdentifier } from './branch-identifier';

@Component({
	template: ''
})
export abstract class BaseContainerComponent implements OnChanges, OnDestroy {
	@Input() getToken: () => Observable<string>;
	@Input() lang: string;

	@HostBinding('attr.data-snappet-mfe-branch') branchIdentifier: string;

	protected readonly languages = {
		'en': localeEn,
		'nl': localeNl,
		'es': localeEs,
		'ca': localeCa,
		'eu': localeEu,
		'gl': localeGl
	};

	protected destroy$ = new Subject<void>();

	protected readonly marketService = inject(MarketService);
	protected readonly regionConfigService = inject(RegionConfigService);

	private _market: Market;

	constructor(
		protected authService: AuthService,
		protected translateService: TranslateService
	) {
		translateService.addLangs(Object.keys(this.languages));
		translateService.setDefaultLang('en');
		translateService.use(this.translateService.currentLang);
		Object.keys(this.languages).forEach(key => registerLocaleData(this.languages[key], key));

		this.authService.token$.pipe(
			filter(token => !!token),
			switchMap(token => defer(() => requestSignedCookies(token, environment.name)).pipe(
				tap(response => this.authService.setSignedCookie(response)),
				retry({ delay: 2000 })
			)),
			takeUntil(this.destroy$)
		).subscribe();

		combineLatest([
			this.authService.region$,
			this.marketService.region$
		]).pipe(
			tap(([authRegion, marketRegion]) => {
				this.regionConfigService.setConfig(authRegion || marketRegion);
			}),
			takeUntil(this.destroy$)
		).subscribe();

		if (!environment.production) {
			this.branchIdentifier = branchIdentifier;
		}
	}

	get market(): Market {
		return this._market;
	}

	@Input() set market(market: Market) {
		this._market = +market;
	}

	@Input() set token(token: string) {
		this.authService.setToken(token);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.getToken && this.getToken()) {
			this.getToken().pipe(
				tap(token => this.authService.setToken(token)),
				takeUntil(this.destroy$)
			).subscribe();
		}
		if (changes.lang && this.lang && Object.keys(this.languages).includes(this.lang)) {
			this.translateService.use(this.lang);
		}
		if (changes.market && this.market && Object.values(Market).includes(this.market)) {
			this.marketService.setMarket(this.market);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
