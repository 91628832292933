import { environment } from '@snappet-content-products/shared/environments';
import { LearningObjective } from './content/learning-objective.model';

export class LearningObjectiveUtil {
	static isComplete(learningObjective: LearningObjective): boolean {
		if (!learningObjective) {
			return false;
		}

		const { learningObjectiveIcon, friendlyName } = learningObjective;

		return !!learningObjectiveIcon && !!friendlyName;
	}

	static getLoPrimaryName(learningObjective: LearningObjective): string {
		return learningObjective?.friendlyName || learningObjective?.name;
	}

	static getLoCardUrl(learningObjectiveId: number): string {
		return `${environment.loCardUrl}/${learningObjectiveId}.png`;
	}

	static getLoIconUrl(learningObjectiveId: number): string {
		return `${environment.loIconUrl}/${learningObjectiveId}.SVG`;
	}
}
