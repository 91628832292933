import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@snappet-content-products/shared/environments';
import { getResizedLoIconUrl, loIconFallbackUrl } from '@snappet/lo-icon-url';

@Component({
	selector: 'cp-learning-objective-icon',
	templateUrl: './learning-objective-icon.component.html',
	styleUrls: ['./learning-objective-icon.component.scss']
})
export class LearningObjectiveIconComponent implements OnChanges {
	@Input() sourceUrl = '';
	@Input() width = 100;
	@Input() height = 100;
	@Input() altText = '';
	@Input() titleText = '';

	url: string = loIconFallbackUrl;

	constructor(private readonly sanitizer: DomSanitizer) { }

	get safeUrl(): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['sourceUrl'] && this.sourceUrl) {
			this.url = getResizedLoIconUrl(this.sourceUrl, this.width, this.height, environment.name);
		}
	}

	onError(): void {
		this.url = loIconFallbackUrl;
	}
}
