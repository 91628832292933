<ng-container *ngIf="icon && (isLoading$ | async) === false">
	<svg role="img"
		 [attr.viewBox]="iconViewBox"
		 [attr.aria-labelledby]="ariaLabelledBy"
		 [attr.aria-describedby]="ariaDescribedBy">
		<use attr.href="#{{ iconId }}{{ type }}"></use>
		<symbol [id]="iconId + type"
				[innerHTML]="safeIcon"></symbol>
	</svg>
</ng-container>
