import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RegionConfigService } from '@snappet-content-products/shared/util';
import { map, switchMap } from 'rxjs/operators';
import { BrowserSnapshotInputModel, PdfOptions, PngOptions, OutputModel, S3OutputModel } from '../..';

@Injectable()
export class BrowserSnapshotService {
	constructor(private readonly httpClient: HttpClient, private readonly regionConfigService: RegionConfigService) { }

	get apiUrl(): string {
		return this.regionConfigService.config?.authorizationApiUrls.browserSnapshotService;
	}

	getSnapshot(browserSnapshotInputModel: BrowserSnapshotInputModel<PdfOptions | PngOptions>): Observable<string> {
		return this.httpClient.post<(OutputModel | S3OutputModel)[]>(this.apiUrl, browserSnapshotInputModel).pipe(
			map(outputs => outputs[0]),
			switchMap(output => {
				if (browserSnapshotInputModel.uploadToS3) {
					return this.downloadFile((output as S3OutputModel).url);
				}

				return of((output as OutputModel).outputData);
			})
		);
	}

	private downloadFile(url: string): Observable<string> {
		return this.httpClient.get(url, { responseType: 'text' });
	}
}
