import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@snappet-content-products/shared/environments';
import { genericRetryStrategy } from '@snappet-content-products/shared/util';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { DifficultyLevelExercises } from '../..';

@Injectable()
export class ExerciseDifficultyService {
	private readonly apiUrl: string = environment.exerciseDifficulty;

	constructor(private readonly httpClient: HttpClient) { }

	getExerciseGroupsByDifficulty(learningObjectiveId: number, excludeExerciseIds: number[]): Observable<DifficultyLevelExercises[]> {
		const queryParams = excludeExerciseIds.length ? `&excludeExerciseIds=${excludeExerciseIds.join(',')}` : '';

		return this.httpClient.get<DifficultyLevelExercises[]>(`${this.apiUrl}/?learningObjectiveId=${learningObjectiveId}${queryParams}`).pipe(
			retry(genericRetryStrategy())
		);
	}
}
