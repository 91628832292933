import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { RegionConfigService, genericRetryStrategy } from '@snappet-content-products/shared/util';
import { LessonPlanLesson, LessonPlanSection } from '../..';

@Injectable()
export class TeachService {
	constructor(private readonly httpClient: HttpClient, private readonly regionConfigService: RegionConfigService) { }

	get apiUrl(): string {
		return this.regionConfigService.config?.authorizationApiUrls.teach;
	}

	getLessonPlanLesson(subjectGroupId: number, lessonId: number): Observable<LessonPlanLesson> {
		return this.httpClient.get<LessonPlanLesson>(`${this.apiUrl}/lessonplans/${subjectGroupId}/lessons/${lessonId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLessonPlanLessonsBySubjectGroupAndSection(subjectGroupId: number, sectionId: number): Observable<LessonPlanLesson[]> {
		return this.httpClient.get<LessonPlanLesson[]>(`${this.apiUrl}/lessonplans/${subjectGroupId}/sections/${sectionId}/lessons`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLessonPlanSection(subjectGroupId: number, sectionId: number): Observable<LessonPlanSection> {
		return this.httpClient.get<LessonPlanSection>(`${this.apiUrl}/lessonplans/${subjectGroupId}/sections/${sectionId}`).pipe(
			retry(genericRetryStrategy())
		);
	}
}
