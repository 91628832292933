import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { ExpansionPanelHeaderComponent } from './expansion-panel-header/expansion-panel-header.component';
import { ExpansionPanelContentDirective } from './expansion-panel-content.directive';

@NgModule({
	imports: [
		CommonModule,
		PortalModule
	],
	declarations: [
		ExpansionPanelComponent,
		ExpansionPanelHeaderComponent,
		ExpansionPanelContentDirective
	],
	exports: [
		ExpansionPanelComponent,
		ExpansionPanelHeaderComponent,
		ExpansionPanelContentDirective
	]
})
export class ExpansionPanelModule { }
