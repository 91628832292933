// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const AudioContext = window.AudioContext || (window as any).webkitAudioContext;

export function isSupported(): boolean {
	try {
		const testAudioContext = new AudioContext();

		testAudioContext.close();

		return true;
	} catch (e) {
		return false;
	}
}

