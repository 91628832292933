<button class="dropdown-trigger"
		[disabled]="disabled || options.length === 0"
		[size]="size"
		[color]="color"
		[class.opened]="isOpen"
		[class.active]="isOpen"
		[cpDropdownTriggerFor]="dropdown"
		[cdkMenuPosition]="dropdownPositions"
		cp-button>
	<div class="dropdown-templated"
		 *ngIf="selectedOptionTemplate">
		<ng-container
					  *ngTemplateOutlet="selectedOptionTemplate; context: { selectedOptions: selectedOptions }"></ng-container>
	</div>
	<div class="dropdown-default"
		 *ngIf="!selectedOptionTemplate">
		{{ dropdownLabel }}
	</div>
	<cp-spinner *ngIf="isLoading"
				data-test="spinner"
				class="spinner"></cp-spinner>
	<span class="dropdown-arrow">
		<span class="left-bar"></span>
		<span class="right-bar"></span>
	</span>
</button>

<ng-template #dropdown>
	<div class="dropdown-container">
		<div [class]="overlayArrow.outerCssClass">
			<div [class]="overlayArrow.innerBeforeCssClass"></div>
			<div [class]="overlayArrow.innerAfterCssClass"></div>
		</div>
		<div class="dropdown-container-inner"
			 [style.maxHeight.px]="maxHeight">
			<div class="dropdown-body"
				 cdkMenu>
				<button *ngFor="let option of options; trackBy: trackByFn"
						class="dropdown-option"
						[attr.data-test]="'option-' + option.value"
						[class.selected]="isSelected(option)"
						[attr.aria-selected]="isSelected(option)"
						[class.disabled]="option.disabled"
						[disabled]="option.disabled"
						(click)="onDropdownOptionTriggered(option)">
					<ng-container
								  *ngTemplateOutlet="optionTemplate ? optionTemplate : plainOption; context: { option: option, selected: isSelected(option) }"></ng-container>
				</button>
			</div>
		</div>
	</div>
</ng-template>

<ng-template let-option="option"
			 let-selected="selected"
			 #plainOption>
	<ng-container
				  *ngTemplateOutlet="labelTemplate ? labelTemplate : plainLabel; context: { label: option.label }"></ng-container>

	<span *ngIf="multiSelect"
		  class="checkbox-inner-container"
		  [class.checked]="selected">
		<span class="checkbox-frame"></span>
		<span class="checkbox-background">
			<cp-icon iconId="Check"></cp-icon>
		</span>
	</span>
</ng-template>

<ng-template let-label="label"
			 #plainLabel>
	{{ label}}
</ng-template>