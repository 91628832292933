import { NgModule } from '@angular/core';
import { TabComponent } from './tab/tab.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { CommonModule } from '@angular/common';
import { TabLabelDirective } from './tab-label/tab-label.directive';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
	imports: [
		CommonModule,
		PortalModule
	],
	declarations: [
		TabComponent,
		TabGroupComponent,
		TabLabelDirective
	],
	exports: [
		TabComponent,
		TabGroupComponent,
		TabLabelDirective
	]
})
export class TabsModule { }
