import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { retry } from 'rxjs/operators';
import { genericRetryStrategy, RegionConfigService } from '@snappet-content-products/shared/util';
import { SubjectCurrentAbility, LearningObjectiveCurrentAbility } from '../..';

@Injectable()
export class AbilityService {
	constructor(private readonly httpClient: HttpClient, private readonly regionConfigService: RegionConfigService) { }

	get apiUrl(): string {
		return this.regionConfigService.config?.authorizationApiUrls.ability;
	}

	getPupilsCurrentAbilityByLearningObjectiveId(learningObjectiveId: number, pupilIds: number[]): Observable<LearningObjectiveCurrentAbility[]> {
		if (!pupilIds.length) {
			return of([] as LearningObjectiveCurrentAbility[]);
		}

		const queryParams = pupilIds.length ? `?pupilIds=${pupilIds.join(',')}` : '';

		return this.httpClient.get<LearningObjectiveCurrentAbility[]>(`${this.apiUrl}/learningObjectives/${learningObjectiveId}/currentAbility/pupils${queryParams}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getPupilsCurrentAbilityBySubjectId(subjectId: number, pupilIds: number[]): Observable<SubjectCurrentAbility[]> {
		if (!pupilIds.length) {
			return of([] as SubjectCurrentAbility[]);
		}

		const queryParams = pupilIds.length ? `?pupilIds=${pupilIds.join(',')}` : '';

		return this.httpClient.get<SubjectCurrentAbility[]>(`${this.apiUrl}/subjects/${subjectId}/currentAbility/pupils${queryParams}`).pipe(
			retry(genericRetryStrategy())
		);
	}
}
