import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'cp-app-settings-panel',
	templateUrl: './app-settings-panel.component.html',
	styleUrls: ['./app-settings-panel.component.scss']
})
export class AppSettingsPanelComponent {
	@Input() id: string;

	@ViewChild('panel') panelRef: ElementRef<HTMLElement>;

	private y = 0;
	private x = 0;

	onDragMoved(event: CdkDragMove) {
		const distance = event.source.getFreeDragPosition();

		const y = this.y + distance.y;
		const x = this.x + distance.x;

		localStorage.setItem('adminPanelPosition_' + this.id, JSON.stringify({ x, y }));
	}

	onProjectContentChanged() {
		this.restorePosition();
	}

	private restorePosition(): void {
		const position = localStorage.getItem('adminPanelPosition_' + this.id);
		const panelEl = this.panelRef.nativeElement;

		if (position) {
			try {
				const parsedPosition = JSON.parse(position);

				this.x = Math.min(parsedPosition.x, window.innerWidth, panelEl.offsetWidth);
				this.y = Math.min(parsedPosition.y, window.innerHeight - panelEl.offsetHeight);
			} catch {
				this.y = 0;
				this.x = 0;
			} finally {
				panelEl.style.top = `${this.y}px`;
				panelEl.style.left = `${this.x}px`;
			}
		}
	}
}
