import { OverlayArrow } from './overlay.const';

type OverlayPositionType = 'se' | 'sw' | 's' | 'ne' | 'nw' | 'n';

export class OverlayObserver {
	private mutationObserver: MutationObserver | undefined;

	private readonly positions: OverlayPositionType[] = ['se', 'sw', 's', 'ne', 'nw', 'n'];

	constructor(private readonly triggerElement: HTMLElement) { }

	disconnect(): void {
		if (this.mutationObserver) {
			this.mutationObserver.disconnect();
		}
	}

	observeOverlay(overlayElement: HTMLElement | null): void {
		if (!overlayElement) {
			return;
		}

		this.setArrowPosition(overlayElement);

		this.mutationObserver = new MutationObserver(mutationsList => {
			for (const mutation of mutationsList) {
				if (mutation.attributeName === 'class') {
					this.setArrowPosition(overlayElement);
				}
			}
		});

		this.mutationObserver.observe(overlayElement, { attributes: true, attributeFilter: ['class'] });
	}

	private setArrowPosition(overlayElement: HTMLElement): void {
		const position = Array.from(overlayElement.classList).find((value: OverlayPositionType) => this.positions.indexOf(value) >= 0);

		if (!position) {
			return;
		}

		const arrowElement: HTMLElement = overlayElement.querySelector(`.${OverlayArrow.outerCssClass}`);

		if (!arrowElement) {
			return;
		}

		arrowElement.classList.remove(...this.positions);

		arrowElement.classList.add(position);

		const elements: HTMLElement[] = Array.from(arrowElement.querySelectorAll(`.${OverlayArrow.innerBeforeCssClass}, .${OverlayArrow.innerAfterCssClass}`));
		const arrowOffset = overlayElement.getBoundingClientRect().right - this.triggerElement.getBoundingClientRect().right;

		let minArrowWidth = 12;

		minArrowWidth = Math.min(this.triggerElement.clientWidth / 2, minArrowWidth);

		elements.forEach(element => {
			element.style.left = `${position === 'ne' || position === 'se' ? minArrowWidth + arrowOffset : null}px`;
			element.style.right = `${position === 'nw' || position === 'sw' ? minArrowWidth + arrowOffset : null}px`;
		});
	}
}
