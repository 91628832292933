import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@snappet-content-products/shared/environments';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { RegionConfigService } from './region-config/region-config.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private readonly authService: AuthService, private readonly regionConfigService: RegionConfigService) { }

	private get authorizationApiUrls(): string[] {
		return [
			...Object.values(this.regionConfigService.config.authorizationApiUrls),
			...Object.values(environment.authorizationApiUrls)
		];
	}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return this.authService.token$.pipe(
			take(1),
			switchMap(accessToken => {
				if (accessToken) {
					if (req.url.includes(this.regionConfigService.config.authorizationApiUrls.pupilApp)) {
						req = req.clone({ headers: req.headers.set('Authorization', accessToken) });
					} else if (this.authorizationApiUrls.some(api => req.url.includes(api))) {
						req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + accessToken) });
					}
				}

				return next.handle(req);
			})
		);
	}
}
