/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import { Market } from '@snappet/enums/dist';
import { BehaviorSubject, Subject as RxjsSubject, takeUntil, tap, throttleTime } from 'rxjs';
import { DataConverterOptions } from './data-converter-options.model';
import { DataConverterService } from './data-converter.service';
import { DataFilter } from './data-filter.model';
import { DataFilterService } from './data-filter.service';
import { DataItem } from './data-item.model';
import { DataSelectorService } from './data-selector.service';
import { DataUtilService } from './data-util.service';
import { DataWheelDirection } from './data-wheel-direction.type';
import { DataWheelNavigation } from './data-wheel-navigation.model';
import { DataWheelState } from './data-wheel-state.model';
import { DataWheelView } from './data-wheel-view.type';
import { ANIMATION_DURATION, MAX_ZOOM_FACTOR, MIN_ZOOM_FACTOR, ROOT_GUID } from './data-wheel.consts';
import { CourseGrade, Cycle, EntityTypeEnum, Section } from './entities';
import { LessonListData } from './lesson-list-data.model';
import { Color, GradeLearningObjectivesGrowth } from './lo-colors';
import { SelectedState } from './selected-state.model';
import { DataWheelInput } from './data-wheel-input.model';
import { shouldEnlargeLeaf } from './data-wheel.functions';

@Injectable()
export class DataWheelControllerService implements OnDestroy {
	private market: Market;
	private colors: Color[];
	private contentData: DataItem[];
	private educationalStandardData: DataItem[];
	private learningObjectiveData: DataItem[];
	private labels: {
		root: {
			standards: string;
			course: string;
		};
		lesson: string;
		noLesson: string;
	};

	private filter: DataFilter;
	private selectedView: DataWheelView;
	private radiusZoomFactor: number;
	private selectedColorId: number;
	private selectedState: SelectedState;
	private data: DataItem;
	private shouldUseSubMarketFilter: boolean;
	private loColors: GradeLearningObjectivesGrowth[];
	private ignoreDataWheelState: boolean;
	private shouldTrimData: boolean;
	private preferredStandardId: number;

	private jsonData: {
		courseGrades: CourseGrade[];
		cycles: Cycle[];
		sections: Section[];
	};

	private readonly pathLabelSubject = new BehaviorSubject<string[]>([]);
	pathLabel$ = this.pathLabelSubject.asObservable();

	private readonly filterSubject = new BehaviorSubject<DataFilter>(this.getEmptyFilter());
	filter$ = this.filterSubject.asObservable();

	private readonly radiusZoomFactorSubject = new RxjsSubject<number>();
	radiusZoomFactor$ = this.radiusZoomFactorSubject.asObservable();

	private readonly selectedViewSubject = new RxjsSubject<DataWheelView>();
	selectedView$ = this.selectedViewSubject.asObservable();

	private readonly selectedColorIdSubject = new RxjsSubject<number>();
	selectedColorId$ = this.selectedColorIdSubject.asObservable();

	private readonly selectedStateSubject = new RxjsSubject<SelectedState>();
	selectedState$ = this.selectedStateSubject.asObservable();

	private readonly lessonListDataSubject = new BehaviorSubject<LessonListData>(null);
	lessonListData$ = this.lessonListDataSubject.asObservable();

	private readonly dataSubject = new BehaviorSubject<DataItem>(null);
	data$ = this.dataSubject.asObservable();

	private readonly handleSelectedStateSubject = new RxjsSubject<SelectedState>();
	private readonly rotateSelectSubject = new RxjsSubject<SelectedState>();
	private readonly destroy$ = new RxjsSubject<void>();

	constructor(
		private readonly dataUtilService: DataUtilService,
		private readonly dataSelectorService: DataSelectorService,
		private readonly dataConverterService: DataConverterService,
		private readonly dataFilterService: DataFilterService
	) {
		this.handleSelectedStateSubject.pipe(
			throttleTime(ANIMATION_DURATION),
			tap(selectedState => this.handleSelectedState(selectedState)),
			takeUntil(this.destroy$)
		).subscribe();

		this.rotateSelectSubject.pipe(
			throttleTime(ANIMATION_DURATION, undefined, { leading: true, trailing: true }),
			tap(selectedState => this.handleSelectedState(selectedState)),
			takeUntil(this.destroy$)
		).subscribe();

		this.radiusZoomFactorSubject.pipe(
			tap(radiusZoomFactor => this.radiusZoomFactor = radiusZoomFactor),
			takeUntil(this.destroy$)
		).subscribe();

		this.selectedViewSubject.pipe(
			tap(selectedView => this.selectedView = selectedView),
			takeUntil(this.destroy$)
		).subscribe();

		this.selectedColorIdSubject.pipe(
			tap(selectedColorId => this.selectedColorId = selectedColorId),
			takeUntil(this.destroy$)
		).subscribe();

		this.selectedStateSubject.pipe(
			tap(selectedState => this.selectedState = selectedState),
			tap(selectedState => this.setPathLabel(this.getSelectedRoot(selectedState.rootId))),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataSubject.pipe(
			tap(data => this.data = data),
			takeUntil(this.destroy$)
		).subscribe();

		this.filterSubject.pipe(
			tap(f => this.filter = f),
			takeUntil(this.destroy$)
		).subscribe();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	initialize(input: DataWheelInput): void {
		const { contentData, educationalStandardData, learningObjectiveData, defaultFilter, shouldTrimData, preferredStandardId,
			shouldUseSubMarketFilter, colors, jsonData, market, labels, navigation, loColors, ignoreDataWheelState, defaultView } = input;

		this.market = market;
		this.colors = colors;
		this.jsonData = jsonData;
		this.labels = labels;
		this.shouldUseSubMarketFilter = shouldUseSubMarketFilter;
		this.ignoreDataWheelState = ignoreDataWheelState;
		this.shouldTrimData = shouldTrimData;
		this.preferredStandardId = preferredStandardId;

		let dataWheelState = this.loadDataWheelState();

		dataWheelState = this.validateAndResetDataWheelState(dataWheelState);

		if (dataWheelState?.filter) {
			this.filterSubject.next(dataWheelState.filter);
		} else {
			this.applyDefaultFilter(defaultFilter);
		}

		this.selectedViewSubject.next(dataWheelState?.view ?? defaultView ?? 'course');

		this.radiusZoomFactorSubject.next(dataWheelState?.radiusZoomFactor === MAX_ZOOM_FACTOR ? MAX_ZOOM_FACTOR : MIN_ZOOM_FACTOR);

		if (dataWheelState?.colorId) {
			this.selectedColorIdSubject.next(dataWheelState.colorId);
		} else {
			this.applyDefaultSelectedColorId();
		}

		const storedNavigation = navigation || dataWheelState?.navigation;

		this.updateData({
			contentData,
			educationalStandardData,
			learningObjectiveData,
			loColors,
			navigation: storedNavigation,
			rootLabel: labels.root
		});
	}

	updateData(input: {
		contentData: DataItem[];
		educationalStandardData: DataItem[];
		learningObjectiveData: DataItem[];
		navigation?: DataWheelNavigation;
		rootLabel: {
			standards: string;
			course: string;
		};
		loColors: GradeLearningObjectivesGrowth[];
	}): void {
		const { contentData, educationalStandardData, learningObjectiveData, navigation, rootLabel, loColors } = input;

		this.contentData = contentData;
		this.educationalStandardData = educationalStandardData;
		this.learningObjectiveData = learningObjectiveData;
		this.labels.root = rootLabel;
		this.loColors = loColors;

		this.setData();

		if (navigation) {
			this.applyStoredNavigation(navigation);
		} else {
			this.applyDefaultNavigation();
		}

		this.setLessonList();

		this.saveDataWheelState();
	}

	clickSelect(selectedId: string): void {
		const selectedState = this.handleSelectedChange(selectedId);

		this.handleSelectedStateSubject.next(selectedState);
	}

	rotateSelect(selectedId: string): void {
		this.rotateSelectSubject.next({ rootId: this.selectedState.rootId, leafId: selectedId });
	}

	rotate(rotateDirection: DataWheelDirection): void {
		const indexChange = rotateDirection === 'cw' ? 1 : -1;

		this.handleIndexChange(indexChange);
	}

	zoomBy(radiusZoomFactor: number): void {
		this.radiusZoomFactorSubject.next(radiusZoomFactor);
		this.saveDataWheelState();
	}

	selectedViewChange(selectedView: DataWheelView): void {
		if (this.selectedView === selectedView) {
			return;
		}

		this.selectedViewSubject.next(selectedView);

		const { root: selectedRoot, leaf: selectedLeaf } = this.getSelectedState();

		this.setData();

		if (!selectedLeaf) {
			this.applyDefaultNavigation();
		} else {
			this.applySwitchNavigation({ selectedRoot, selectedLeaf });
		}

		this.handleSelectedState(this.selectedState);
	}

	selectedColorChange(selectedColorId: number) {
		this.selectedColorIdSubject.next(selectedColorId);
		this.saveDataWheelState();
	}

	filterChange(dataFilter: DataFilter) {
		const filterTypeChanged = (t: EntityTypeEnum) => dataFilter[t]?.length !== this.filter[t]?.length;

		const ignoreFilter = this.selectedView === 'course' && filterTypeChanged(EntityTypeEnum.EducationalStandard)
			|| this.selectedView === 'standards' && filterTypeChanged(EntityTypeEnum.CourseGroup);

		this.filterSubject.next(dataFilter);

		if (ignoreFilter) {
			this.saveDataWheelState();
			return;
		}

		this.setData();
		this.handleSelectedState(this.selectedState);
	}

	removeFilter(filterRemoveOrder: EntityTypeEnum[]) {
		this.filterSubject.next({ ...this.dataFilterService.removeFilter(this.filter, filterRemoveOrder) });
		this.setData();
		this.handleSelectedState(this.selectedState);
	}

	getSelectedRoot(rootId: string): DataItem {
		let selectedRoot = (this.data.guid === rootId || !rootId) ? this.data : this.dataSelectorService.getNodeByGuid(this.data.children, rootId);

		selectedRoot = selectedRoot || this.data;

		return selectedRoot;
	}

	getSelectedState(): { leaf: DataItem; root: DataItem } {
		return {
			leaf: this.dataSelectorService.getNodeByGuid(this.data.children, this.selectedState.leafId),
			root: this.getSelectedRoot(this.selectedState.rootId)
		};
	}

	private setData() {
		const options: DataConverterOptions = {
			rootLabel: this.selectedView === 'course' ? this.labels.root.course : this.labels.root.standards,
			filter: this.filter,
			loColors: this.loColors,
			requiredLeafType: this.selectedView === 'course' ? undefined : EntityTypeEnum.StandardNode,
			shouldTrimData: this.shouldTrimData
		};

		let data: DataItem;

		if (this.selectedView === 'course') {
			const filteredContent = this.dataFilterService.filterEntities(this.contentData, this.filter, this.shouldUseSubMarketFilter);

			data = this.dataConverterService.convertData(filteredContent, options);
		} else {
			const filteredEducationalStandardData = this.dataFilterService.filterEntities(this.educationalStandardData, this.filter, this.shouldUseSubMarketFilter);

			data = this.dataConverterService.convertData(filteredEducationalStandardData, options);
		}

		this.dataSubject.next(data);
	}

	private setLessonList() {
		const data = this.selectedView === 'course' ? this.contentData : this.educationalStandardData;
		const selectedItem = this.dataSelectorService.getNodeByGuid(this.data.children, this.selectedState.leafId);
		const lessonList: LessonListData = { selectedLesson: null, learningObjective: null };

		if (selectedItem?.type === EntityTypeEnum.Lesson) {
			lessonList.selectedLesson = this.dataSelectorService.getNodeByGuid(data, selectedItem?.guid);
		} else if (selectedItem?.type === EntityTypeEnum.LO) {
			const standardNode = this.dataSelectorService.getNodeByGuid(data, selectedItem.parent.guid);
			const lessonsForLosInStandard = standardNode.children.map(lo => {
				const lessonForLo = this.findLessonByLo(lo);

				return {
					...lessonForLo,
					// Use a negative LO id to ensure that it does not coincidentally match a lesson id in this list
					id: lessonForLo?.id ?? -lo.id,
					label: lessonForLo ? this.labels.lesson : this.labels.noLesson,
					type: lessonForLo ? lessonForLo.type : EntityTypeEnum.LO,
					loId: lessonForLo?.loId ?? lo.id,
					parentId: lo?.parentId
				} as DataItem;
			});

			let selectedLesson = lessonsForLosInStandard.find(l => l.loId === selectedItem.loId);

			if (!selectedLesson) {
				selectedLesson = {
					id: -selectedItem.loId,
					loId: selectedItem.loId,
					label: this.labels.noLesson,
					type: EntityTypeEnum.LO,
					children: [],
					parentId: null,
					parent: null,
					guid: null,
					filterIds: []
				};
			}

			lessonList.selectedLesson = selectedLesson;
		}

		lessonList.learningObjective = lessonList.selectedLesson && this.learningObjectiveData
			? this.learningObjectiveData.find(lo => lo.id === lessonList.selectedLesson.loId)
			: null;

		this.lessonListDataSubject.next(lessonList);
	}

	private loadDataWheelState(): DataWheelState {
		const storedState = sessionStorage.getItem('dataWheelState');

		if (!storedState || this.ignoreDataWheelState) {
			return null;
		}

		const state: DataWheelState = JSON.parse(storedState);

		return state;
	}

	private validateAndResetDataWheelState(dataWheelState: DataWheelState) {
		if (dataWheelState?.market && dataWheelState.market !== this.market) {
			sessionStorage.removeItem('dataWheelState');

			dataWheelState = null;
		}

		if (dataWheelState?.preferredStandardId && dataWheelState.preferredStandardId !== this.preferredStandardId) {
			dataWheelState.navigation = null;
		}

		return dataWheelState;
	}

	private saveDataWheelState(): void {
		if (this.ignoreDataWheelState) {
			return;
		}

		const { rootId, leafId } = this.selectedState ?? {};
		const state: DataWheelState = {
			filter: this.filter,
			navigation: { rootId, leafId },
			view: this.selectedView,
			radiusZoomFactor: this.radiusZoomFactor,
			market: this.market,
			colorId: this.selectedColorId,
			preferredStandardId: this.preferredStandardId
		};

		sessionStorage.setItem('dataWheelState', JSON.stringify(state));
	}

	private handleSelectedChange(selectedId: string) {
		let { leafId } = this.selectedState;
		let rootId: string;

		const minRings = 2;
		const selectedNode = this.dataSelectorService.getNodeByGuid(this.data.children, selectedId);
		const depthFromOuterRing = this.dataUtilService.getDepthFromOuterRing(selectedNode);
		const isOuterRing = selectedNode && depthFromOuterRing < minRings;
		const isCenterCircle = this.selectedState.rootId === selectedId || !selectedNode;

		if (isOuterRing) {
			leafId = this.dataSelectorService.getFirstLeaf(selectedNode?.children)?.guid || selectedId;
			const nthParent = this.dataUtilService.getNthParent(selectedNode, 0, minRings - depthFromOuterRing);
			const nthParentDepthFromOuter = this.dataUtilService.getDepthFromOuterRing(nthParent);

			// check if nth parent meets to min rings
			if (nthParentDepthFromOuter <= minRings) {
				rootId = nthParent.guid;
			} else {
				rootId = selectedNode.parent.guid;
			}

			this.radiusZoomFactorSubject.next(MAX_ZOOM_FACTOR);
		} else if (isCenterCircle) {
			rootId = selectedNode?.parent.guid || ROOT_GUID;
			this.radiusZoomFactorSubject.next(MIN_ZOOM_FACTOR);
		} else {
			rootId = selectedId;
		}

		return {
			rootId,
			leafId
		};
	}

	private handleIndexChange(indexChange: number) {
		const { rootId, leafId } = this.selectedState;

		const selectedRoot = this.getSelectedRoot(rootId);
		const selectedItem = this.dataSelectorService.getNodeByGuid(selectedRoot.children, leafId);
		const leaves = this.dataSelectorService.getAllLeaves(selectedRoot.children);

		let selectedIndex = leaves.findIndex(n => n.guid === selectedItem?.guid);

		if (selectedIndex === -1) {
			selectedIndex = 0;
		}

		const newIndex = (selectedIndex + indexChange + leaves.length) % leaves.length;

		const selectedState = {
			rootId: selectedRoot.guid,
			leafId: leaves[newIndex]?.guid
		};

		this.handleSelectedStateSubject.next(selectedState);
	}

	private handleSelectedState(selectedState: SelectedState) {
		let { rootId, leafId } = selectedState;

		// If the currently selected root is not available reset.
		if (this.data.guid !== rootId && !this.dataSelectorService.getNodeByGuid(this.data.children, rootId)) {
			const unfilteredData = this.getUnfilteredData();

			rootId = this.dataUtilService.getFirstVisibleParent(this.data, unfilteredData, rootId);
		}

		const selectedRoot = this.getSelectedRoot(rootId);

		// If the currently selected leaf is not a child of the new root, we need to find a new leaf below it.
		if (!this.dataSelectorService.getNodeByGuid(selectedRoot.children, leafId)) {
			leafId = this.dataSelectorService.getFirstLeaf(selectedRoot.children)?.guid;
		}

		this.setSelectedState({ rootId, leafId });
		this.setLessonList();
		this.saveDataWheelState();
	}

	private applyDefaultFilter(defaultFilter: DataFilter): void {
		this.filterSubject.next({ ...this.getEmptyFilter(), ...defaultFilter });
	}

	private getEmptyFilter(): DataFilter {
		return {
			[EntityTypeEnum.Subject]: [],
			[EntityTypeEnum.Grade]: [],
			[EntityTypeEnum.CourseGroup]: [],
			[EntityTypeEnum.EducationalStandard]: [],
			[EntityTypeEnum.Cycle]: [],
			[EntityTypeEnum.SubMarket]: []
		};
	}

	private applyDefaultSelectedColorId() {
		if (this.colors.length > 0) {
			const getColorIdForMarket = (): number => {
				switch (this.market) {
					case Market.Es:
						return 35;
					case Market.Nl:
						return 6;
					case Market.Us:
						return 124;
					default:
						return 0;
				}
			};

			const targetId = getColorIdForMarket();
			const targetColor = this.colors.find(c => c.id === targetId);

			this.selectedColorIdSubject.next(targetColor?.id || this.colors[0].id);
		} else {
			this.selectedColorIdSubject.next(null);
		}
	}

	private applyStoredNavigation({ rootId, leafId }: DataWheelNavigation): void {
		const selectedRoot = this.getSelectedRoot(rootId);
		const selectedItem = this.dataSelectorService.getNodeByGuid(selectedRoot.children, leafId);

		if (!selectedItem && selectedRoot.children.length) {
			this.applyDefaultNavigation();
		} else {
			this.setSelectedState({ rootId, leafId });
		}
	}

	private setSelectedState(selectedState: SelectedState) {
		this.selectedStateSubject.next(selectedState);
	}

	private getUnfilteredData(): DataItem {
		const data = this.selectedView === 'course' ? this.contentData : this.educationalStandardData;
		const options: DataConverterOptions = {
			rootLabel: '',
			filter: this.getEmptyFilter(),
			loColors: this.loColors,
			shouldTrimData: this.shouldTrimData
		};

		return this.dataConverterService.convertData(data, options);
	}

	private applySwitchNavigation({ selectedLeaf, selectedRoot }: { selectedLeaf: DataItem; selectedRoot: DataItem }) {
		let leaf: DataItem;
		let root: DataItem;

		if (this.selectedView === 'course') {
			// Find a lesson ID for the selected LO.
			leaf = this.findLessonByLo(selectedLeaf);
		} else {
			// The Lesson card in the course view only contains a content LO Id for which we have to find the best LO guid.
			leaf = this.findLoByLesson(selectedLeaf.guid);
			root = this.findPreferredStandardForLo(leaf?.guid);
		}

		if (shouldEnlargeLeaf(this.radiusZoomFactor, selectedLeaf, selectedRoot)) {
			root = leaf?.parent?.parent || root;
		}

		const leafId = leaf?.guid;
		const rootId = root?.guid || this.data.guid;

		this.setSelectedState({ leafId, rootId });
	}

	private setPathLabel(rootItem: DataItem): void {
		const concatParentName = (item: DataItem, title: string[], level: number, maxLevel: number) => {
			title.unshift(item.label);

			if (item.parent && level < maxLevel) {
				return concatParentName(item.parent, title, level + 1, maxLevel);
			}

			return title;
		};

		const pathLabel = concatParentName(rootItem, [], 0, 1);

		this.pathLabelSubject.next(pathLabel);
	}

	private applyDefaultNavigation(): void {
		const entityType = this.selectedView === 'course' ? EntityTypeEnum.Lesson : EntityTypeEnum.LO;

		let rootId = this.data.guid;
		let leafId = this.dataSelectorService.getLevelByType(this.data.children, entityType)[0]?.guid;

		if (this.selectedView === 'standards' && this.preferredStandardId) {
			const rootNode = this.dataSelectorService.getNodeById(this.data.children, EntityTypeEnum.EducationalStandard, this.preferredStandardId);

			if (rootNode) {
				rootId = rootNode.guid;
				leafId = this.dataSelectorService.getFirstLeaf(rootNode.children)?.guid;
			}
		}

		this.setSelectedState({ rootId, leafId });
	}

	private findLoByLesson(selectedLessonId: string): DataItem {
		const selectedLesson = this.dataSelectorService.getNodeByGuid(this.contentData, selectedLessonId);

		if (!selectedLesson) {
			return null;
		}

		const selectedSection = this.dataSelectorService.getNodeByGuid(this.contentData, selectedLesson.parent?.guid);

		if (!selectedSection) {
			return null;
		}

		const selectedCourseGroup = this.dataUtilService.findParentByType(selectedSection, EntityTypeEnum.CourseGroup);
		let gradeId = selectedSection.parentId;
		let gradeType = EntityTypeEnum.Grade;

		if (this.market === Market.Es) {
			gradeId = this.jsonData.cycles.find(c => c.gradeIds.includes(selectedSection.parentId))?.cycleId;
			gradeType = EntityTypeEnum.Cycle;
		}

		const loNodes = this.dataSelectorService.getLevelByType(this.data.children, EntityTypeEnum.LO).filter(lo => lo.id === selectedLesson.loId);

		if (!loNodes.length) {
			return null;
		}

		this.sortByPreferredStandard(loNodes, this.preferredStandardId);

		const loNodesForGradeAndSubMarket = this.filterByGradeAndSubMarket(loNodes, gradeType, gradeId, selectedCourseGroup?.subMarketIds);

		return loNodesForGradeAndSubMarket.length ? loNodesForGradeAndSubMarket[0] : loNodes[0];
	}

	private filterByGradeAndSubMarket(loNodes: DataItem[], gradeType: EntityTypeEnum, gradeId: number, subMarketIds: number[]) {
		return loNodes.filter(lo => {
			const loGrade = this.dataUtilService.findParentByType(lo, gradeType);
			const loEducationalStandardItem = this.dataUtilService.findParentByType(lo, EntityTypeEnum.EducationalStandard);
			const loSubMarketIds = loEducationalStandardItem?.subMarketIds;

			return loGrade?.id === gradeId && (!loSubMarketIds?.length || loSubMarketIds.some(id => subMarketIds.includes(id)));
		});
	}

	private sortByPreferredStandard(loNodes: DataItem[], preferredStandardId: number) {
		if (!preferredStandardId) {
			return loNodes;
		}

		return loNodes.sort((a, b) => {
			const aStandard = this.dataUtilService.findParentByType(a, EntityTypeEnum.EducationalStandard);
			const bStandard = this.dataUtilService.findParentByType(b, EntityTypeEnum.EducationalStandard);

			const aStandardIsPreferred = preferredStandardId === aStandard?.id;
			const bStandardIsPreferred = preferredStandardId === bStandard?.id;

			if (aStandardIsPreferred && !bStandardIsPreferred) {
				return -1;
			}
			if (!aStandardIsPreferred && bStandardIsPreferred) {
				return 1;
			}

			return 0;
		});
	}

	private findLessonByLo(selectedLo: DataItem): DataItem {
		const filteredContent = this.dataFilterService.filterEntities(this.contentData, this.filter, this.shouldUseSubMarketFilter);
		const gradeType = this.market === Market.Es ? EntityTypeEnum.Cycle : EntityTypeEnum.Grade;
		const selectedGrade = this.dataUtilService.findParentByType(selectedLo, gradeType);
		const selectedEducationalStandard = this.dataUtilService.findParentByType(selectedLo, EntityTypeEnum.EducationalStandard);
		let selectedGradeIds = [selectedGrade?.id];
		const selectedSubMarketIds = selectedEducationalStandard.subMarketIds;

		if (this.market === Market.Es) {
			selectedGradeIds = this.jsonData.cycles.find(c => c.cycleId === selectedGrade?.id)?.gradeIds ?? [];
		}

		const foundLessons = this.dataSelectorService.getLevelByType(filteredContent, EntityTypeEnum.Lesson).filter(lesson => !lesson.isExam && lesson.loId === selectedLo.loId);
		const lessonsWithCourseTypes = this.sortLessonsForLoSelection(foundLessons, selectedGradeIds, selectedSubMarketIds);

		return lessonsWithCourseTypes.length > 0 ? lessonsWithCourseTypes[0].lesson : null;
	}

	private sortLessonsForLoSelection(foundLessons: DataItem[], selectedGradeIds: number[], selectedSubMarketId: number[]) {
		const getCourseSort = (courseGrade: CourseGrade, subMarketIds: number[]) => {
			let sort = '';

			if (selectedGradeIds.includes(courseGrade?.gradeId) && subMarketIds.some(id => selectedSubMarketId.includes(id))) {
				sort += 'A';
			} else if (selectedGradeIds.includes(courseGrade?.gradeId) || subMarketIds.some(id => selectedSubMarketId.includes(id))) {
				sort += 'B';
			} else {
				sort += 'Z';
			}

			sort += (['A', 'B'].includes(courseGrade?.courseType) ? 'A' : 'Z') + courseGrade?.name + courseGrade?.gradeId;
			return sort;
		};

		return foundLessons.map(lesson => {
			const courseId = this.jsonData.sections.find(s => s.i === lesson.parentId)?.p;
			const courseGrade = this.jsonData.courseGrades.find(c => c.courseId === courseId);
			const courseGroup = this.dataUtilService.findParentByType(lesson, EntityTypeEnum.CourseGroup);
			// on course view we dont have course groups
			const subMarketIds = courseGroup?.subMarketIds ?? [];

			return {
				lesson,
				courseId,
				lessonId: lesson.id,
				sectionId: lesson.parentId,
				courseName: courseGrade?.name,
				courseType: courseGrade?.courseType,
				courseSort: getCourseSort(courseGrade, subMarketIds),
				courseGradeId: courseGrade?.gradeId
			};
		})
			.sort((a, b) => {
				if (a.courseSort < b.courseSort) {
					return -1;
				} else if (a.courseSort > b.courseSort) {
					return 1;
				}

				return a.lessonId - b.lessonId;
			});
	}

	private findPreferredStandardForLo(leafId: string) {
		const rootNode = this.dataSelectorService.getNodeById(this.educationalStandardData, EntityTypeEnum.EducationalStandard, this.preferredStandardId);

		if (rootNode) {
			const childLeafNode = this.dataSelectorService.getNodeByGuid(rootNode.children, leafId);

			if (childLeafNode || !leafId) {
				return rootNode;
			}
		}

		return null;
	}
}
