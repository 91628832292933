import { ColorEnum } from '@snappet-content-products/shared/domain';

export const growthColorMap = new Map([
	[null, '#e4e4e5'],
	[0, '#e4e4e5'],
	[33, '#d6ecf7'],
	[66, '#84c7e7'],
	[100, '#34a3d7']
]);

export const colorFontColorMap = new Map([
	['#e4e4e5', '#828283'],
	['#d6ecf7', ColorEnum.Dark],
	['#84c7e7', ColorEnum.Dark],
	['#34a3d7', '#ffffff']
]);
