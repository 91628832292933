import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, retry, tap } from 'rxjs';
import { genericRetryStrategy } from '@snappet-content-products/shared/util';
import { environment } from '@snappet-content-products/shared/environments';

@Injectable({ providedIn: 'root' })
export class IconsService {
	private readonly svgSpriteDocSubject = new BehaviorSubject<Document>(null);
	// eslint-disable-next-line @typescript-eslint/member-ordering
	svgSpriteDoc$ = this.svgSpriteDocSubject.asObservable();

	private readonly svgSpriteFileName = 'icons-7420ebe06b.sprite.svg';

	constructor(
		private readonly http: HttpClient
	) {
		this.getSvgSprite().pipe(
			map(svgSprite => new DOMParser().parseFromString(svgSprite, 'text/xml')),
			tap(svgSpriteDoc => this.svgSpriteDocSubject.next(svgSpriteDoc))
		).subscribe();
	}

	private get svgSpriteUrl(): string {
		return `${environment.sharedAssetsLocation}/assets/icons/${this.svgSpriteFileName}`;
	}

	private getSvgSprite(): Observable<string> {
		return this.http.get(this.svgSpriteUrl, { responseType: 'text' }).pipe(
			retry(genericRetryStrategy())
		);
	}
}
