<div class="slider-container">
    <label>{{ 'DATA_WHEEL.COLOR' | translate }}</label>
    <div class="slider-control">
        <input type="range"
               min="0"
               [max]="maxColorIndex"
               [(ngModel)]="selectedColorIndex"
               (ngModelChange)="onColorChange()"
               class="slider" />
        <div class="handle"
             [style.left.%]="selectedColorIndex * (100 / colors.length)">
            <span>{{ selectedColorLabel }}</span>
        </div>
        <div class="ticks">
            <span *ngFor="let color of colors; trackBy: trackByFn; let i = index"
                  [value]="i"></span>
        </div>
    </div>
</div>
