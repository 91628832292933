<div class="top-bar-left">
	<a *ngIf="dataWheelContext?.showLogo"
	   class="logo"
	   data-test="logo"
	   href="/">
		<img [src]="safeLogoUrl"
			 alt="Snappet" />
	</a>
</div>
<div class="top-bar-center">
	<div class="data-wheel-filter">
		<cp-data-wheel-filter [entities]="entities"
							  [filter]="filter"
							  [market]="market"
							  [selectedView]="selectedView"
							  (filterChange)="filterChange.emit($event)"></cp-data-wheel-filter>
	</div>
</div>
<div class="top-bar-right">
	<div class="data-wheel-buttons">

		<button *ngIf="dataWheelContext?.showLogin"
				class="login-button"
				data-test="login-button"
				cp-button
				(click)="loginClick.emit()">
			{{ 'DATA_WHEEL.LOGIN' | translate }}
		</button>
		<ng-container *ngIf="dataWheelContext?.showLogout">
			<button class="logout-button"
					data-test="logout-button"
					cp-button
					(click)="logoutClick.emit()">
				{{ 'DATA_WHEEL.LOGOUT' | translate }}
			</button>
		</ng-container>
	</div>
</div>