/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Market } from '@snappet/enums/dist';
import { Region } from './auth-config/auth-config';
import { environment } from '@snappet-content-products/shared/environments';

@Injectable({ providedIn: 'root' })
export class MarketService {
	private readonly marketSubject = new BehaviorSubject<Market>(null);
	market$ = this.marketSubject.asObservable().pipe(
		distinctUntilChanged()
	);

	region$ = this.market$.pipe(
		map(market => this.mapMarketToRegion(market))
	);

	countryId$ = this.market$.pipe(
		map(market => this.mapMarketToCountryId(market))
	);

	setMarket(market: Market): void {
		this.marketSubject.next(market);
	}

	private mapMarketToRegion(market: Market): Region {
		if (!market) {
			return null;
		}

		if (market === Market.Us) {
			return 'us';
		}

		return 'eu';
	}

	private mapMarketToCountryId(market: Market): number {
		switch (market) {
			case Market.Es:
				return environment.countryIds.spain;
			case Market.Us:
				return environment.countryIds.unitedStates;
			case Market.Nl:
				return environment.countryIds.netherlands;
			default:
				return null;
		}
	}
}
