import { HttpErrorResponse } from '@angular/common/http';
import { throwError, timer } from 'rxjs';

export interface GenericRetryStrategyConfig {
	maxRetryAttempts?: number;
	scalingDuration?: number;
	excludedStatusCodes?: number[];
}

export const genericRetryStrategy = ({
	maxRetryAttempts = 3,
	scalingDuration = 3000,
	excludedStatusCodes = [404]
}: GenericRetryStrategyConfig = {}) => ({
	count: maxRetryAttempts,
	delay: (error: HttpErrorResponse, retryAttempt: number) => {
		if (excludedStatusCodes.find(e => e === error?.status)) {
			return throwError(() => error);
		}

		return timer(retryAttempt * scalingDuration);
	}
});
