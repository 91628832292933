/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BaseFacade } from '@snappet-content-products/shared/util';
import { Market } from '@snappet/enums/dist';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Injectable()
export class DataWheelFacade extends BaseFacade {
	private readonly marketSubject = new BehaviorSubject<Market>(null);
	market$ = this.marketSubject.asObservable().pipe(
		distinctUntilChanged()
	);

	private readonly langSubject = new BehaviorSubject<string>(null);
	lang$ = this.langSubject.asObservable().pipe(
		distinctUntilChanged()
	);

	init$ = combineLatest([
		this.market$,
		this.lang$
	]).pipe(
		filter(([market, lang]) => !!market && !!lang),
		map(([market, lang]) => ({
			market,
			lang
		}))
	);

	constructor() {
		super();
	}

	setMarket(market: Market): void {
		this.marketSubject.next(market);
	}

	setLang(lang: string): void {
		this.langSubject.next(lang);
	}
}
