import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { ToggleOptionComponent } from './toggle-option/toggle-option.component';
import { ToggleOptionGroupDirective } from './toggle-group.directive';

@NgModule({
	imports: [
		CommonModule,
		IconModule
	],
	exports: [
		ToggleOptionComponent,
		ToggleOptionGroupDirective
	],
	declarations: [
		ToggleOptionComponent,
		ToggleOptionGroupDirective
	]
})
export class ToggleGroupModule { }
