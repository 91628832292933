import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { genericRetryStrategy } from '@snappet-content-products/shared/util';
import { environment } from '@snappet-content-products/shared/environments';
import { ReadSpeakerSettings } from '../domain';
import { AudioFormatEnum, OutputEnum, SynchronisationEnum } from '../domain/read-speaker/enums';
import { ReadSpeakerInput } from '../domain/read-speaker/read-speaker-input.model';

@Injectable()
export class ReadSpeakerService {
	private readonly apiUrl: string = environment.authorizationApiUrls.readSpeaker;
	private readonly retrySettings = { maxRetryAttempts: 1 };

	constructor(private readonly httpClient: HttpClient) { }

	getAudioUrl({ culture, text, voice, speed, isHtml }: ReadSpeakerSettings): Observable<string> {
		const readInput = {
			text,
			voice,
			speed,
			isHtml,
			lang: culture,
			audioformat: AudioFormatEnum.Mp3,
			output: OutputEnum.Audiolink
		} as ReadSpeakerInput;

		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

		return this.httpClient.post<string>(this.apiUrl, readInput, { headers }).pipe(
			retry(genericRetryStrategy(this.retrySettings))
		);
	}

	getHighlightInfo({ culture, text, voice, speed, isHtml }: ReadSpeakerSettings): Observable<string> {
		const highlightInput = {
			text,
			voice,
			speed,
			isHtml,
			audioformat: AudioFormatEnum.Xml,
			sync: SynchronisationEnum.Wordsent,
			lang: culture
		} as ReadSpeakerInput;

		const headers = new HttpHeaders({ 'Content-Type': 'text/xml' });

		return this.httpClient.post<string>(this.apiUrl, highlightInput, { headers }).pipe(
			retry(genericRetryStrategy(this.retrySettings))
		);
	}
}
