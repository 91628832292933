import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import {
	genericRetryStrategy,
	RegionConfigService
} from '@snappet-content-products/shared/util';
import { PupilAppSetting } from '../../domain';

@Injectable()
export class PupilAppSettingsService {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly regionConfigService: RegionConfigService
	) { }

	get apiUrl(): string {
		return this.regionConfigService.config?.authorizationApiUrls.pupilApp;
	}

	getClassSettings(
		classId: string,
		schoolId: string,
		settingName: string
	): Observable<PupilAppSetting[]> {
		const query = `
			query($classId: String!, $schoolId: String!, $settingName: String!) {
				appSettings(
					classId: $classId,
					schoolId: $schoolId,
					settingName: $settingName
				) {
					pupilId
					pupilName
					settingValue
				}
		}`;

		const gqlProps = {
			query,
			graphQlOperation: 'appSettings',
			variables: { classId, schoolId, settingName }
		};

		return this.httpClient.post<{
			data: { appSettings: PupilAppSetting[] };
			errors: unknown;
		}>(this.apiUrl, gqlProps)
			.pipe(
				map(response => {
					if (!response?.data?.appSettings) {
						if (response?.errors[0].errorType === 'Unauthorized') {
							return null;
						}
						throw new Error(JSON.stringify(response.errors));
					}
					return response.data.appSettings;
				}),
				retry(genericRetryStrategy()),
				catchError(() => of(null))
			);
	}

	putPupilSetting(
		classId: string,
		pupilId: string,
		schoolId: string,
		settingName: string,
		settingValue: string
	): Observable<boolean> {
		const query = `
			mutation($classId: String!, $schoolId: String!, $settingName: String!, $pupilId: String!, $settingValue: AWSJSON!) {
				putAppSetting(
					classId: $classId,
					schoolId: $schoolId,
					settingName: $settingName,
					pupilId: $pupilId,
					settingValue: $settingValue
				)
		}`;

		const gqlProps = {
			query,
			graphQlOperation: 'putAppSettings',
			variables: {
				classId,
				schoolId,
				pupilId,
				settingName,
				settingValue
			}
		};

		return this.httpClient.post<{ data: { putAppSetting: boolean }; errors: unknown }>(
			this.apiUrl,
			gqlProps
		)
			.pipe(
				map(response => {
					if (!response?.data?.putAppSetting) {
						if (response?.errors[0].errorType === 'Unauthorized') {
							return false;
						}
						throw new Error(JSON.stringify(response.errors));
					}
					return response.data.putAppSetting;
				}),
				retry(genericRetryStrategy()),
				catchError(() => of(false))
			);
	}
}
