
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedUiModule } from '@snappet-content-products/shared/ui';
import { SharedUtilModule } from '@snappet-content-products/shared/util';
import { SvgPlaceholderComponent } from './svg-placeholder/svg-placeholder.component';
import { TeacherTipComponent } from './teacher-tip/teacher-tip.component';
import { LoginFlowComponent } from './login-flow/login-flow.component';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { CloudflareStreamModule } from '@cloudflare/stream-angular';
import { PixelsToRemPipe } from './pixels-to-rem/pixels-to-rem.pipe';
import { DecryptNamePipe } from './decrypt-name/decrypt-name.pipe';
import { DecryptInitialsPipe } from './decrypt-name/decrypt-initials.pipe';
import { LessonMenuComponent } from './lesson-menu/lesson-menu.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { AppSettingsPanelComponent } from './app-settings-panel/app-settings-panel.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LearningObjectiveIconComponent } from './learning-objective-icon/learning-objective-icon.component';
import { ObserversModule } from '@angular/cdk/observers';
import { PupilsPickerComponent } from './pupils-picker/pupils-picker.component';
import { ExerciseThumbnailModule } from '@snappet/exercise-thumbnail';
import { ExerciseInfoThumbnailComponent } from './exercise-info-thumbnail/exercise-info-thumbnail.component';
import { IfChangesDirective } from './if-changes.directive';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		SharedUiModule,
		SharedUtilModule,
		CloudflareStreamModule,
		DragDropModule,
		ObserversModule,
		ExerciseThumbnailModule
	],
	declarations: [
		LoginFlowComponent,
		TeacherTipComponent,
		SvgPlaceholderComponent,
		VideoDialogComponent,
		PixelsToRemPipe,
		DecryptNamePipe,
		DecryptInitialsPipe,
		LessonMenuComponent,
		DashboardHeaderComponent,
		AppSettingsPanelComponent,
		LearningObjectiveIconComponent,
		PupilsPickerComponent,
		ExerciseInfoThumbnailComponent,
		IfChangesDirective
	],
	exports: [
		ReactiveFormsModule,
		LoginFlowComponent,
		TeacherTipComponent,
		SvgPlaceholderComponent,
		VideoDialogComponent,
		PixelsToRemPipe,
		DecryptNamePipe,
		DecryptInitialsPipe,
		LessonMenuComponent,
		DashboardHeaderComponent,
		AppSettingsPanelComponent,
		LearningObjectiveIconComponent,
		PupilsPickerComponent,
		ExerciseInfoThumbnailComponent,
		IfChangesDirective
	]
})
export class SharedAngularModule { }
