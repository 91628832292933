import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import {
	DataItem, SelectedState, colorFontColorMap, ANIMATION_DURATION, DataWheelDirection, shouldEnlargeLeaf,
	growthColorMap, GradeGrowthDict, ColorDict, RADIUS_ZOOM_MULTIPLIER, MIN_ZOOM_FACTOR, MAX_ZOOM_FACTOR, ROOT_GUID
} from '@snappet-content-products/data-wheel/domain';
import { ColorEnum } from '@snappet-content-products/shared/domain';
import { ZoomTransform, arc, curveBundle, easeCubicOut, easeSinInOut, hierarchy, interpolate, interpolateString, line, partition, rgb, select, zoom, zoomIdentity } from 'd3';
import { BehaviorSubject, ReplaySubject, Subject, distinctUntilChanged, map, pairwise, pipe, skip, startWith, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';

const QUARTER_CIRCLE = Math.PI / 2;
const FULL_CIRCLE = Math.PI * 2;

interface DataItemNode extends d3.HierarchyRectangularNode<DataItem> {
	current: DataItemNode;
	target: DataItemNode;
	isVisible: boolean;
	isLandscape: boolean;
}

@Component({
	selector: 'cp-curriculum-sunburst',
	templateUrl: './curriculum-sunburst.component.html',
	styleUrls: ['./curriculum-sunburst.component.scss']
})
export class CurriculumSunburstComponent implements OnChanges, OnDestroy, AfterViewChecked {
	@Input() data: DataItem;
	@Input() selectedState: SelectedState;
	@Input() pathLabel: string[];
	@Input() radiusZoomFactor: number;
	@Input() selectedColorId: number;
	@Input() innerCircleCallback: () => void;
	@Input() gapFactor = 1;
	@Input() template: TemplateRef<unknown>;

	@Output() readonly clickSelect = new EventEmitter<string>();
	@Output() readonly rotateSelect = new EventEmitter<string>();
	@Output() readonly renderingDone = new EventEmitter<void>();

	transitionDuration = { slow: ANIMATION_DURATION, fast: 100, none: 0 };

	private readonly destroy$ = new Subject<void>();

	private readonly width = 500;
	private readonly height = 500;

	private layers: number;
	private layerZoomFactor = 1;
	private currentRadiusZoomFactor = MIN_ZOOM_FACTOR;
	private readonly fontSize = { xs: 4, sm: 8, lg: 12 };
	private readonly fontColor = { gray: '#828283', black: ColorEnum.Dark };
	private readonly selectedStateSubject = new Subject<SelectedState>();
	private readonly zoomBySubject = new ReplaySubject<number>(0);
	private readonly dataReadySubject = new Subject<void>();
	private readonly renderingDoneSubject = new BehaviorSubject<void>(null);
	private readonly selectedColorIdSubject = new Subject<number>();
	private readonly pathLabelSubject = new ReplaySubject<string[]>(0);
	private readonly innerCircleCallbackSubject = new ReplaySubject<() => void>(0);
	private readonly factorForCollapsedRing = 0.05;

	private readonly radiusZoomFontSizeMap = new Map([
		[MIN_ZOOM_FACTOR, {
			default: this.fontSize.sm,
			bottomTitle: this.fontSize.lg
		}],
		[MAX_ZOOM_FACTOR, {
			default: this.fontSize.xs,
			bottomTitle: this.fontSize.sm
		}]
	]);

	private arcGen: d3.Arc<unknown, d3.HierarchyRectangularNode<DataItem>>;
	private readonly chart: {
		svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
		mainGroup: d3.Selection<SVGGElement, unknown, null, undefined>;
		sunburst: {
			group: d3.Selection<SVGGElement, unknown, null, undefined>;
			labelGroup: d3.Selection<SVGGElement, unknown, null, undefined>;
			circle: d3.Selection<SVGCircleElement, d3.HierarchyRectangularNode<DataItem>, null, undefined>;
			title: d3.Selection<SVGTextElement, unknown, null, undefined>;
			topTitle: d3.Selection<SVGTextElement, unknown, null, undefined>;
			bottomTitle: d3.Selection<SVGTextElement, unknown, null, undefined>;
			pathGroup: d3.Selection<SVGGElement, unknown, null, undefined>;
			layout: d3.HierarchyRectangularNode<DataItem>;
			defs: d3.Selection<SVGDefsElement, unknown, null, undefined>;
		};
		magnifyingGlass: {
			group: d3.Selection<SVGGElement, unknown, null, undefined>;
			body: d3.Selection<SVGGElement, unknown, null, undefined>;
			footer: d3.Selection<SVGGElement, unknown, null, undefined>;
			object: d3.Selection<SVGGElement, unknown, null, undefined>;
			outline: d3.Selection<SVGGElement, unknown, null, undefined>;
			inline: d3.Selection<SVGGElement, unknown, null, undefined>;
		};
	} = {
			svg: null,
			mainGroup: null,
			sunburst: {
				group: null,
				labelGroup: null,
				circle: null,
				title: null,
				topTitle: null,
				bottomTitle: null,
				pathGroup: null,
				layout: null,
				defs: null
			},
			magnifyingGlass: {
				group: null,
				body: null,
				footer: null,
				object: null,
				outline: null,
				inline: null
			}
		};
	private zoom: d3.ZoomBehavior<Element, unknown>;
	private rootHierarchy: d3.HierarchyNode<DataItem>;

	private isDragging: boolean;
	private startDegrees: number;
	private dragDirection: DataWheelDirection;
	private dragListeners: (() => void)[] = [];

	private readonly handleSelectedState = pipe(
		tap(({ selectedState }: { selectedState: SelectedState; shouldAnimate: boolean }) => {
			const currentRootNodeSelection = this.chart.sunburst.pathGroup
				.selectAll('path')
				.filter((n: DataItemNode) => n.data.guid === selectedState.rootId);

			if (currentRootNodeSelection.size() > 0) {
				const newRoot = currentRootNodeSelection.datum() as DataItemNode;

				if (this.currentRoot.data.guid !== newRoot.data.guid) {
					this.applyNodeToCenter(newRoot);
				}
			} else if (this.currentRoot.parent) {
				this.applyNodeToCenter(this.currentRoot.parent);
			}
		}),
		tap(({ selectedState, shouldAnimate }) => {
			const selectedNode = this.findOuterNodeByGuid(selectedState.leafId);

			if (selectedNode) {
				this.rotateAndEnlarge(selectedNode, shouldAnimate);
			} else {
				this.removeMagnifyingGlass();
			}
		})
	);

	constructor(
		private readonly el: ElementRef<HTMLElement>,
		private readonly renderer: Renderer2,
		private readonly viewContainerRef: ViewContainerRef
	) {
		this.dataReadySubject.pipe(
			switchMap(() => this.selectedStateSubject.pipe(
				distinctUntilChanged((x, y) => x.leafId === y.leafId && x.rootId === y.rootId),
				startWith(null),
				pairwise(),
				map(([previous, current]) => ({ selectedState: current, shouldAnimate: !!previous })),
				this.handleSelectedState
			)),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataReadySubject.pipe(
			switchMap(() => this.zoomBySubject.pipe(
				// dont apply zoom factor if data just has changed
				skip(1),
				tap(radiusZoomFactor => this.applyZoomFactor(radiusZoomFactor))
			)),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataReadySubject.pipe(
			switchMap(() => this.selectedColorIdSubject.pipe(
				// prevent transition when chart might still be rotating because of data change
				map((_, index) => index === 0 ? this.transitionDuration.none : this.transitionDuration.slow),
				tap(animationDuration => this.updateColor(animationDuration))
			)),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataReadySubject.pipe(
			switchMap(() => this.pathLabelSubject.pipe(
				tap(() => this.setTitle())
			)),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataReadySubject.pipe(
			switchMap(() => this.innerCircleCallbackSubject.pipe(
				tap(() => this.setInnerCircleCallback())
			)),
			takeUntil(this.destroy$)
		).subscribe();

		this.dataReadySubject.pipe(
			withLatestFrom(this.renderingDoneSubject),
			tap(() => requestAnimationFrame(() => this.renderingDone.emit())),
			takeUntil(this.destroy$)
		).subscribe();
	}

	get radius() {
		return this.width / 2;
	}

	get currentCircle() {
		return FULL_CIRCLE * this.gapFactor;
	}

	get currentLayer() {
		return Math.ceil(this.layers / this.layerZoomFactor);
	}

	get currentRoot() {
		return this.chart.sunburst.circle?.datum() as DataItemNode;
	}

	get currentRadiusZoomLevel() {
		let zoomFactor = this.radiusZoomFactor;
		let count = 0;

		while (zoomFactor > 1) {
			zoomFactor = zoomFactor / RADIUS_ZOOM_MULTIPLIER;
			count++;
		}

		return count;
	}

	get radiusZoomFontSize() {
		if (this.radiusZoomFontSizeMap.has(this.radiusZoomFactor)) {
			return this.radiusZoomFontSizeMap.get(this.radiusZoomFactor);
		}

		return this.radiusZoomFontSizeMap.get(MIN_ZOOM_FACTOR);
	}

	onWheelDragStart = (event: MouseEvent | TouchEvent): void => {
		event.stopPropagation();

		const { clientX, clientY } = event instanceof MouseEvent ? event : event.touches[0];

		this.startRotation(clientX, clientY);
	};

	onWheelMove = (event: MouseEvent | TouchEvent): void => {
		const { clientX, clientY } = event instanceof MouseEvent ? event : event.touches[0];

		this.rotateWheel(clientX, clientY);

		if (this.isDragging) {
			event.stopPropagation();
		}
	};

	onWheelRelease = (): void => {
		if (this.isDragging) {
			const easeRotation = this.dragDirection === 'cw' ? 2 : -2;
			const animationEndCallback = () => {
				const node = this.findOuterNodeClosestToZero();

				this.rotateSelect.emit(node.data.guid);
				this.rotateAndEnlarge(node, true);
			};

			this.chart.sunburst.layout.each((node: DataItemNode) => node.target = node.current);
			this.rotateChart(easeRotation, this.transitionDuration.slow, animationEndCallback);
		}

		this.stopRotation();
	};

	ngAfterViewChecked(): void {
		this.renderingDoneSubject.next();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['data'] && this.data) {
			this.layers = this.findMaxDepth(this.data);
			this.layerZoomFactor = 1;
			this.createChart();
			this.zoomChart(this.getZoomTransform(this.radiusZoomFactor), 0);

			this.dataReadySubject.next();
		}
		if (changes['pathLabel']) {
			this.pathLabelSubject.next(this.pathLabel);
		}
		if (changes['innerCircleCallback']) {
			this.innerCircleCallbackSubject.next(this.innerCircleCallback);
		}
		if (changes['selectedState'] && this.selectedState) {
			this.selectedStateSubject.next(this.selectedState);
		}
		if (changes['radiusZoomFactor'] && this.radiusZoomFactor) {
			this.zoomBySubject.next(this.radiusZoomFactor);
		}
		if (changes['selectedColorId'] && this.selectedColorId) {
			this.selectedColorIdSubject.next(this.selectedColorId);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private readonly radToDeg = (rad: number) => rad * (180 / Math.PI);
	private readonly degToRad = (deg: number) => deg * (Math.PI / 180);
	private readonly nodeRad = (n: DataItemNode) => (n.x0 + n.x1) / 2;
	private readonly nodeDeg = (n: DataItemNode) => this.radToDeg(this.nodeRad(n));

	private createChart() {
		if (!this.chart.svg) {
			this.chart.svg = select(this.el.nativeElement)
				.append('svg')
				.attr('viewBox', [-this.width / 2, -this.height / 2, this.width, this.width])
				.attr('fill', this.fontColor.black)
				.style('width', '100%')
				.style('height', '100%')
				.style('font', `${this.radiusZoomFontSize.default}px \'abezeh\', Sans-serif`)
				.style('touch-action', 'none')
				.on('touchmove', e => e.preventDefault());
			this.chart.mainGroup = this.chart.svg
				.append('g')
				.on('mousedown', this.onWheelDragStart)
				.on('touchstart', this.onWheelDragStart);

			this.chart.sunburst.group = this.chart.mainGroup.append('g');
			this.chart.magnifyingGlass.group = this.chart.mainGroup.append('g');

			this.arcGen = arc<unknown, d3.HierarchyRectangularNode<DataItem>>()
				.startAngle(n => n.x0)
				.endAngle(n => n.x1)
				.padAngle(n => Math.min((n.x1 - n.x0) / 2, 0.015))
				.padRadius(this.getRingHeight(0))
				.innerRadius(n => this.getInnerRadius(n.y0))
				.outerRadius(n => this.getInnerRadius(n.y1) - 1)
				.cornerRadius(2);

			this.zoom = zoom()
				.on('zoom', ({ transform }) => {
					const k = transform.k;
					const duration = k === this.currentRadiusZoomFactor ? this.transitionDuration.none : this.transitionDuration.slow;

					const selectedNode = this.findOuterNodeByGuid(this.selectedState.leafId);

					this.currentRadiusZoomFactor = k;
					this.rotateAndEnlarge(selectedNode, true);
					this.zoomChart(transform, duration);

					this.chart.sunburst.labelGroup
						.selectAll('text')
						.attr('fill-opacity', (n: DataItemNode) => this.labelVisible(n.target));
				});
		}

		this.rootHierarchy = hierarchy(this.data).sum(item => item.value);

		this.chart.sunburst.layout = partition<DataItem>().size([this.currentCircle, this.rootHierarchy.height + 1])(this.rootHierarchy);
		this.chart.sunburst.layout.each((node: DataItemNode) => {
			node.current = node;
			node.isVisible = true;
			node.isLandscape = this.isLandscape(node.current);
		});

		if (this.chart.sunburst) {
			this.chart.sunburst.group.html(null);
		}

		this.chart.sunburst.pathGroup = this.chart.sunburst.group.append('g');
		this.renderPaths();

		this.chart.sunburst.defs = this.chart.sunburst.group.append('defs');
		this.renderDefs();

		this.chart.sunburst.labelGroup = this.chart.sunburst.group
			.append('g')
			.attr('pointer-events', 'none')
			.attr('text-anchor', 'middle')
			.style('user-select', 'none');
		this.renderLabels();

		this.chart.sunburst.circle = this.chart.sunburst.group
			.append('circle')
			.datum(this.chart.sunburst.layout)
			.attr('r', this.getRingHeight(0))
			.attr('fill', ColorEnum.GrayLight)
			.on('mousedown', e => e.stopPropagation())
			.on('touchstart', e => e.stopPropagation());

		this.chart.sunburst.circle.append('title');

		this.chart.sunburst.title = this.chart.sunburst.group
			.append('text')
			.attr('pointer-events', 'none')
			.style('user-select', 'none')
			.attr('text-anchor', 'middle');

		this.chart.sunburst.topTitle = this.chart.sunburst.title
			.append('tspan')
			.attr('x', '0')
			.attr('fill', this.fontColor.gray)
			.style('font-weight', 'bold');

		this.chart.sunburst.bottomTitle = this.chart.sunburst.title
			.append('tspan')
			.attr('x', '0')
			.style('font-weight', 'bold');
	}

	private zoomChart(transform: ZoomTransform, duration: number) {
		const { default: defaultFontSize } = this.radiusZoomFontSize;

		this.chart.mainGroup.interrupt().transition().duration(duration).attr('transform', transform.toString());
		this.chart.svg.style('font-size', `${defaultFontSize}px`);
		this.chart.sunburst.labelGroup
			.selectAll('text')
			.filter((n: DataItemNode) => !n.isLandscape)
			.attr('fill-opacity', (n: DataItemNode) => this.labelVisible(n.current))
			.text((n: DataItemNode) => this.labelTruncate(n.current, defaultFontSize));

		this.chart.sunburst.labelGroup
			.selectAll('text')
			.filter((n: DataItemNode) => n.isLandscape)
			.attr('fill-opacity', (n: DataItemNode) => this.labelVisible(n.current))
			.select('textPath')
			.text((n: DataItemNode) => this.labelTruncate(n.current, defaultFontSize));

		this.setTitle();
	}

	private setTitle(): void {
		if (!this.pathLabel?.length) {
			return;
		}

		const { circle, topTitle, bottomTitle } = this.chart.sunburst;

		const bottomTitleFontSize = this.radiusZoomFontSize.bottomTitle;
		const firstLine = this.pathLabel.slice(0, this.pathLabel.length - 1).join(', ');
		const lastLine = this.pathLabel[this.pathLabel.length - 1];
		const size = this.getRingHeight(0) * 2;

		circle
			.select('title').text(`${[firstLine, lastLine].filter(l => !!l).join('\n')}`);
		topTitle
			.attr('dy', '-.5em')
			.text(this.getTruncatedText(firstLine, size));
		bottomTitle
			.attr('dy', firstLine ? '1em' : '.35em')
			.style('font-size', `${bottomTitleFontSize}px`)
			.text(this.getTruncatedText(lastLine, size, bottomTitleFontSize));
	}

	private setInnerCircleCallback(): void {
		this.chart.sunburst.circle
			.style('cursor', this.innerCircleCallback ? 'pointer' : 'default')
			.on('click', () => this.innerCircleCallback ? this.innerCircleCallback() : {})
			.on('mouseover', () => this.chart.sunburst.circle.attr('fill', this.getColor(ColorEnum.GrayLight, !!this.innerCircleCallback)))
			.on('mouseout', () => this.chart.sunburst.circle.attr('fill', this.getColor(ColorEnum.GrayLight)));
	}

	private applyNodeToCenter(node: DataItemNode) {
		this.chart.sunburst.pathGroup.selectAll('path').interrupt();
		this.chart.sunburst.labelGroup.selectAll('text').interrupt();
		this.chart.sunburst.defs.selectAll('path').interrupt();

		this.layerZoomFactor = this.layers / (node.height + 1);

		this.chart.sunburst.layout.each((n: DataItemNode) => {
			n.current = {
				...n.current,
				x0: (n.x0 - node.x0) / (node.x1 - node.x0) * this.currentCircle,
				x1: (n.x1 - node.x0) / (node.x1 - node.x0) * this.currentCircle,
				y0: Math.max(0, n.y0 - node.depth),
				y1: Math.max(0, n.y1 - node.depth),
				depth: n.y0 - node.depth
			} as DataItemNode;
			n.isVisible = this.isDescendant(node, n) || n === node;
			n.isLandscape = this.isLandscape(n.current);
		});

		this.updateChartWithNewRadius(node);
		this.renderPaths();
		this.renderDefs();
		this.renderLabels();
	}

	private renderPaths() {
		this.chart.sunburst.pathGroup
			.html(null)
			.selectAll('path')
			.data(this.rootHierarchy.descendants().slice(1))
			.enter()
			.filter((d: DataItemNode) => d.isVisible)
			.append('path')
			.attr('fill', (n: DataItemNode) => this.getNodeColor(n))
			.attr('d', (n: DataItemNode) => this.arcGen(n.current))
			.attr('data-id', n => n.data.guid)
			.attr('data-test', n => `${n.data.type}-node`)
			.style('cursor', 'pointer')
			.on('mouseover', this.nodeMouseover)
			.on('mouseout', this.nodeMouseout)
			.on('click', this.nodeClick);

		this.chart.sunburst.pathGroup
			.selectAll('path')
			.append('title')
			.text((n: DataItemNode) => [n.data.label, n.data.description].filter(l => !!l).join('\n'));
	}

	private renderDefs() {
		this.chart.sunburst.defs
			.html(null)
			.selectAll('path')
			.data(this.rootHierarchy.descendants().slice(1))
			.enter()
			.filter((n: DataItemNode) => n.isVisible && n.isLandscape && this.labelVisible(n.current) > 0)
			.append('path')
			.attr('d', (n: DataItemNode) => this.formatArc(n.current, n.isVisible, n.isLandscape))
			.attr('id', (n: DataItemNode) => n.data.guid);
	}

	private renderLabels() {
		this.chart.sunburst.labelGroup
			.html(null)
			.selectAll('text')
			.data(this.rootHierarchy.descendants().slice(1))
			.enter()
			.filter((n: DataItemNode) => n.isVisible && this.labelRender(n.current))
			.append('text')
			.attr('fill-opacity', (n: DataItemNode) => this.labelVisible(n.current))
			.attr('fill', (n: DataItemNode) => this.getNodeFontColor(n))
			.style('font-weight', 'bold');

		this.chart.sunburst.labelGroup
			.selectAll('text')
			.filter((n: DataItemNode) => !n.isLandscape)
			.attr('dy', '.35em')
			.attr('transform', (n: DataItemNode) => this.labelTransform(n.current, n.isLandscape, 0))
			.text((n: DataItemNode) => this.labelTruncate(n.current));

		this.chart.sunburst.labelGroup
			.selectAll('text')
			.filter((n: DataItemNode) => n.isLandscape)
			.append('textPath')
			.attr('startOffset', '50%')
			.attr('text-anchor', 'middle')
			.attr('xlink:href', (d: DataItemNode) => `#${d.data.guid}`)
			.text((n: DataItemNode) => this.labelTruncate(n.current));
	}

	private applyZoomFactor(radiusZoomFactor: number) {
		const transform = this.getZoomTransform(radiusZoomFactor);

		this.zoom
			.scaleExtent([MIN_ZOOM_FACTOR, MAX_ZOOM_FACTOR])
			.transform(this.chart.svg, transform);
	}

	private getZoomTransform(radiusZoomFactor: number) {
		const additionalRadiusWidth = (this.width * radiusZoomFactor) - this.width;
		const translateX = -(additionalRadiusWidth / 2 / radiusZoomFactor);

		return zoomIdentity.scale(radiusZoomFactor).translate(translateX, 0);
	}

	private updateChartWithNewRadius(node: DataItemNode) {
		this.arcGen
			.innerRadius(n => this.getInnerRadius(n.y0))
			.outerRadius(n => this.getInnerRadius(n.y1) - 1);

		this.chart.sunburst.circle
			.datum(node || this.chart.sunburst.layout)
			.attr('r', this.getRingHeight(0));
	}

	private appendTemplate(containerElement: HTMLElement) {
		if (!this.template) {
			return;
		}

		const embeddedView = this.viewContainerRef.createEmbeddedView(this.template);
		const element: HTMLElement = embeddedView.rootNodes[0] as HTMLElement;

		containerElement.appendChild(element);
	}

	private getTargetNodeDict(selectedLeafId: string, applyEnlarging: boolean): Map<string, DataItemNode> {
		const firstNodeGuid = this.currentRoot?.children.length > 0 ? this.currentRoot.children[0].data.guid : ROOT_GUID;
		const firstNode = this.findOuterNodeByGuid(firstNodeGuid);
		const rotationRad = firstNode.current.x0 || 0;
		const largeItemValue = 4;
		const outerNodesCount = this.countOuterNodes(this.currentRoot.children);
		const smallItemValue = ((100 * this.gapFactor) - largeItemValue) / (outerNodesCount - 1);

		const rootHierarchy = hierarchy(this.currentRoot.data).sum(item => {
			if (!item.value || !applyEnlarging) {
				return item.value;
			}

			if (item.guid === selectedLeafId) {
				return largeItemValue;
			}

			return smallItemValue;
		});

		const layout = partition<DataItem>().size([this.currentCircle, rootHierarchy.height + 1])(rootHierarchy);
		const dict = new Map<string, DataItemNode>();

		layout.each((n: DataItemNode) => {
			n.x0 = n.x0 + rotationRad;
			n.x1 = n.x1 + rotationRad;
			n.isVisible = true;
			n.current = n;

			dict.set(n.data.guid, n);
		});

		return dict;
	}

	private rotateAndEnlarge(selectedNode: DataItemNode, shouldAnimate: boolean) {
		if (!selectedNode) {
			return;
		}

		const selectedLeaf = selectedNode.data.guid;
		const applyEnlarging = shouldEnlargeLeaf(this.radiusZoomFactor, selectedNode.data, this.currentRoot.data);
		const targetNodeDict = this.getTargetNodeDict(selectedLeaf, applyEnlarging);
		const angleDifferenceInDegrees = this.getAngleToZeroInDegrees(targetNodeDict.get(selectedLeaf));
		const isVisible = +(this.chart.magnifyingGlass.group.node() as SVGElement)?.getAttribute('fill-opacity');
		const opacity = isVisible && applyEnlarging || isVisible && !applyEnlarging ? 1 : 0;

		if (isVisible && !applyEnlarging) {
			this.removeMagnifyingGlass();
		}

		const animationEndCallback = () => {
			if (applyEnlarging) {
				this.createMagnifyingGlass(opacity);
			}
		};

		this.chart.sunburst.layout.each((node: DataItemNode) => node.target = targetNodeDict.get(node.data.guid));
		this.rotateChart(angleDifferenceInDegrees, shouldAnimate ? this.transitionDuration.slow : this.transitionDuration.none, animationEndCallback);
	}

	private rotateChart(rotationDegrees: number, transitionDuration: number, animationEndCallback?: () => void) {
		const rotationAngleRadians = this.degToRad(rotationDegrees);
		const transition = this.chart.svg
			.interrupt()
			.transition()
			.ease(easeCubicOut)
			.duration(transitionDuration);

		this.chart.sunburst.pathGroup
			.selectAll('path')
			.transition(transition)
			.filter((n: DataItemNode) => n.isVisible)
			.attrTween('d', (n: DataItemNode) => {
				const startAngleInterpolator = interpolate(n.current.x0, n.target.x0 + rotationAngleRadians);
				const endAngleInterpolator = interpolate(n.current.x1, n.target.x1 + rotationAngleRadians);

				return t => {
					n.current.x0 = startAngleInterpolator(t);
					n.current.x1 = endAngleInterpolator(t);

					return this.arcGen(n.current);
				};
			});

		this.chart.sunburst.defs
			.selectAll('path')
			.transition(transition)
			.filter((n: DataItemNode) => n.isVisible)
			.attrTween('d', (n: DataItemNode) => {
				const startAngleInterpolator = interpolate(n.current.x0, n.target.x0 + rotationAngleRadians);
				const endAngleInterpolator = interpolate(n.current.x1, n.target.x1 + rotationAngleRadians);

				return t => {
					n.current.x0 = startAngleInterpolator(t);
					n.current.x1 = endAngleInterpolator(t);

					return this.formatArc(n.current, n.isVisible, n.isLandscape);
				};
			});

		this.chart.sunburst.labelGroup
			.selectAll('text')
			.transition(transition)
			.filter((n: DataItemNode) => n.isVisible && !n.isLandscape)
			.attrTween('transform', (n: DataItemNode) =>
				interpolateString(this.labelTransform(n.current, n.isLandscape, 0).split(' ')[0], this.labelTransform(n.target, n.isLandscape, rotationDegrees)))
			.text((n: DataItemNode) => this.labelTruncate(n.target));

		this.chart.sunburst.labelGroup
			.selectAll('text')
			.transition(transition)
			.filter((n: DataItemNode) => n.isVisible && n.isLandscape)
			.attr('transform', '')
			.select('textPath')
			.text((n: DataItemNode) => this.labelTruncate(n.target));

		if (animationEndCallback) {
			transition.on('end', animationEndCallback);
		}
	}

	private createMagnifyingGlass(startOpacity: number) {
		const scaleMultiplier = 4;
		const scaleDivider = 1 / scaleMultiplier;

		if (this.chart.magnifyingGlass.group.selectChildren().size() === 0) {
			this.chart.magnifyingGlass.body = this.chart.magnifyingGlass.group.append('path');
			this.chart.magnifyingGlass.footer = this.chart.magnifyingGlass.group.append('path');
			this.chart.magnifyingGlass.outline = this.chart.magnifyingGlass.group.append('path');
			this.chart.magnifyingGlass.inline = this.chart.magnifyingGlass.group.append('path');
			this.chart.magnifyingGlass.object = this.chart.magnifyingGlass.group.append('g');

			this.chart.magnifyingGlass.object
				.append('foreignObject')
				.html(`<div xmlns="http://www.w3.org/1999/xhtml" 
						id="template-placeholder" 
						style="width: 100%;	height: 100%; padding: ${12 * scaleMultiplier}px 0; pointer-events: auto;"></div>`);

			const templatePlaceholder = this.chart.magnifyingGlass.object
				.select('foreignObject')
				.select('#template-placeholder')
				.node() as HTMLElement;

			this.appendTemplate(templatePlaceholder);
		}

		const nodeClostestToZero = this.findOuterNodeByGuid(this.selectedState.leafId);

		this.chart.magnifyingGlass.group
			.attr('fill-opacity', startOpacity)
			.attr('stroke-opacity', startOpacity)
			.style('transform', 'translateX(-2px)')
			.transition()
			.duration(this.transitionDuration.fast)
			.ease(easeSinInOut)
			.attr('fill-opacity', 1)
			.attr('stroke-opacity', 1);

		this.chart.magnifyingGlass.body
			.datum(nodeClostestToZero)
			.attr('d', (n: DataItemNode) => this.createMagnifyingGlassBodyArc(n.target))
			.attr('pointer-events', 'none')
			.attr('fill', '#ffffff')
			.style('filter', 'drop-shadow(-1px 2px 2px rgb(0 0 0 / 0.3))');

		this.chart.magnifyingGlass.inline
			.datum(nodeClostestToZero)
			.attr('d', (n: DataItemNode) => this.createMagnifyingGlassBodyArc(n.target, 1.2))
			.attr('pointer-events', 'none')
			.attr('fill', 'none')
			.attr('stroke', 'var(--data-wheel-magnifying-glass-color, currentColor)')
			.attr('stroke-width', 1.6);

		this.chart.magnifyingGlass.outline
			.datum(nodeClostestToZero)
			.attr('d', (n: DataItemNode) => this.createMagnifyingGlassBodyArc(n.target))
			.attr('pointer-events', 'none')
			.attr('fill', 'none')
			.attr('stroke', '#ffffff')
			.attr('stroke-width', 1);

		this.chart.magnifyingGlass.footer
			.datum(nodeClostestToZero)
			.attr('d', (n: DataItemNode) => this.createMagnifyingGlassFooterArc(n.target, 1.2))
			.attr('pointer-events', 'none')
			.attr('fill', 'rgba(51, 51, 51, 0.05)');

		this.chart.magnifyingGlass.object
			.datum(nodeClostestToZero)
			.style('transform', ({ target: n }: DataItemNode) => `translate(${this.getInnerRadius(n.y0)}px, ${-(this.calculateArcLength(this.radius, n.x0, n.x1) / 2)}px)`);

		this.chart.magnifyingGlass.object
			.select('foreignObject')
			.datum(nodeClostestToZero)
			.attr('pointer-events', 'none')
			.attr('transform', `scale(${Math.max(scaleDivider, 0.25001)})`)
			.attr('width', ({ target: n }: DataItemNode) => this.getRingHeight(n.depth) * scaleMultiplier)
			.attr('height', ({ target: n }: DataItemNode) => this.calculateArcLength(this.radius, n.x0, n.x1) * scaleMultiplier)
			.on('mousedown', e => e.stopPropagation())
			.on('touchstart', e => e.stopPropagation());
	}

	private removeMagnifyingGlass() {
		if (this.chart.magnifyingGlass.group.selectChildren().size() === 0) {
			return;
		}

		this.chart.magnifyingGlass.object
			.select('foreignObject')
			.html(null);

		this.chart.magnifyingGlass.group
			.attr('fill-opacity', 1)
			.attr('stroke-opacity', 1)
			.transition()
			.duration(this.transitionDuration.fast)
			.ease(easeSinInOut)
			.attr('fill-opacity', 0)
			.attr('stroke-opacity', 0)
			.on('end', () => this.chart.magnifyingGlass.group.html(null));
	}

	private getMagnifyingGlassArc(n: DataItemNode) {
		const curveGen = line().curve(curveBundle.beta(1));
		const radius = 5;

		const innerRadius = this.calculateArcLength(this.getInnerRadius(n.y0), n.x0, n.x1);
		const outerRadius = this.calculateArcLength(this.getInnerRadius(n.y1), n.x0, n.x1);

		const start = this.getInnerRadius(n.y0);
		const width = this.getRingHeight(n.depth);
		const end = start + width;

		return {
			curveGen,
			radius,
			innerRadius,
			outerRadius,
			start,
			end
		};
	}

	private createMagnifyingGlassBodyArc(n: DataItemNode, offset = 0) {
		const { radius, curveGen, innerRadius, outerRadius, start, end } = this.getMagnifyingGlassArc(n);

		const leftBottom = innerRadius / 2 - offset;
		const rightBottom = outerRadius / 2 - offset;
		const rightTop = -(outerRadius / 2) + offset;
		const leftTop = -(innerRadius / 2) + offset;
		const left = start + offset;
		const right = end - offset;

		const points = [
			[left, leftTop + radius], [left, leftTop], [left + radius, leftTop],
			[right - radius, rightTop], [right, rightTop], [right, rightTop + radius],
			[right, rightBottom - radius], [right, rightBottom], [right - radius, rightBottom],
			[left + radius, leftBottom], [left, leftBottom], [left, leftBottom - radius],
			[left, leftTop + radius]
		] as [number, number][];

		return curveGen(points);
	}

	private createMagnifyingGlassFooterArc(n: DataItemNode, offset = 0) {
		const { radius, curveGen, innerRadius, outerRadius, start, end } = this.getMagnifyingGlassArc(n);

		const leftBottom = innerRadius / 2 - offset;
		const rightBottom = outerRadius / 2 - offset;
		const rightTop = rightBottom - 22 + offset;
		const leftTop = rightBottom - 22 + offset;
		const left = start + offset;
		const right = end - offset;

		const points = [
			[left, leftTop], [left, leftTop], [left, leftTop],
			[right, rightTop], [right, rightTop], [right, rightTop],
			[right, rightBottom - radius], [right, rightBottom], [right - radius, rightBottom],
			[left + radius, leftBottom], [left, leftBottom], [left, leftBottom - radius],
			[left, leftTop + radius]
		] as [number, number][];

		return curveGen(points);
	}

	private calculateArcLength(radius: number, x0: number, x1: number): number {
		const angle = x1 - x0;

		return radius * angle;
	}

	private updateColor(animimationDuration: number) {
		const { pathGroup, labelGroup } = this.chart.sunburst;

		if (animimationDuration === 0) {
			pathGroup
				.selectAll('path')
				.style('fill', (n: DataItemNode) => this.getNodeColor(n));

			labelGroup
				.selectAll('text')
				.attr('fill', (n: DataItemNode) => this.getNodeFontColor(n));
		} else {
			pathGroup
				.selectAll('path')
				.transition()
				.duration(animimationDuration)
				.style('fill', (n: DataItemNode) => this.getNodeColor(n));

			labelGroup
				.selectAll('text')
				.transition()
				.duration(animimationDuration)
				.attr('fill', (n: DataItemNode) => this.getNodeFontColor(n));
		}
	}

	private readonly nodeMouseover = (_event: PointerEvent, node: DataItemNode) => {
		this.chart.sunburst.pathGroup
			.selectAll('path')
			.style('fill', (n: DataItemNode) => this.getNodeColor(n, this.isAncestorOrDescendant(n, node)));
	};

	private readonly nodeMouseout = () => {
		this.chart.sunburst.pathGroup
			.selectAll('path')
			.style('fill', (n: DataItemNode) => this.getNodeColor(n));
	};

	private readonly nodeClick = (_event: PointerEvent, node: DataItemNode) => {
		if (!node || this.isDragging) {
			return;
		}

		this.clickSelect.emit(node.data.guid);
	};

	private readonly formatArc = (node: DataItemNode, isVisible: boolean, isLandscape: boolean) => {
		if (!isLandscape || !isVisible || !this.labelRender(node)) {
			return '';
		}

		const getFormattedPath = (arcObject: { innerRadius: number; outerRadius: number; startAngle: number; endAngle: number }) => {
			const pathData = arc()(arcObject);
			const firstArcSection = /(^.+?)L/;
			const results = firstArcSection.exec(pathData);

			if (!results) {
				return '';
			}

			const newArc = results[1];

			return newArc.replace(/,/g, ' ');
		};

		const innerRadius = this.getInnerRadius(node.y0);
		const outerRadius = this.getInnerRadius(node.y1) - (this.getRingHeight(node.y0) / 2);
		const halfFontSize = this.radiusZoomFontSize.default / 2;

		// if it is a full circle, render a half circle
		if (this.isFullCircleRing(node)) {
			return getFormattedPath({ innerRadius, outerRadius, startAngle: -(QUARTER_CIRCLE), endAngle: QUARTER_CIRCLE });
		}

		if (this.shouldFlip(node, isLandscape)) {
			return getFormattedPath({
				innerRadius,
				outerRadius: outerRadius + (halfFontSize / 2),
				startAngle: node.x1,
				endAngle: node.x0
			});
		}

		return getFormattedPath({
			innerRadius,
			outerRadius: outerRadius - (halfFontSize / 2),
			startAngle: node.x0,
			endAngle: node.x1
		});
	};

	private readonly labelVisible = (node: DataItemNode): number => {
		if (!node.isVisible) {
			return 0;
		}

		const fontSize = this.radiusZoomFontSize.default;

		const minNodeSizeDict = {
			[this.fontSize.xs]: 0.02,
			[this.fontSize.sm]: 0.04
		};

		const minNodeSize = minNodeSizeDict[fontSize];
		const nodeSize = (node.y1 - node.y0) * (node.x1 - node.x0);

		return node.y1 <= this.currentLayer && node.y0 >= 1 && nodeSize > minNodeSize && this.labelRender(node) ? 1 : 0;
	};

	private readonly labelRender = (node: DataItemNode) => {
		const ringHeight = this.getRingHeight(node.depth);

		return ringHeight > (this.radius * this.factorForCollapsedRing);
	};

	private readonly labelTransform = (node: DataItemNode, isLandscape: boolean, rotationDegrees: number): string => {
		const x = this.nodeDeg(node);
		const radiusBefore = this.getInnerRadius(node.y0);
		const ringHeight = this.getRingHeight(node.y0);
		const y = radiusBefore + (ringHeight / 2);

		return `rotate(${(x - ((90 - rotationDegrees) % 360))}) translate(${y},0) rotate(${this.shouldFlip(node, isLandscape, rotationDegrees) ? 0 : 180})`;
	};

	private readonly labelTruncate = (node: DataItemNode, fontSize = this.radiusZoomFontSize.default) => {
		let { width, height } = this.getDimension(node);

		if (this.isFullCircleRing(node)) {
			width = width / 2;
			height = height / 2;
		}

		const label = node.data.label + (node.data.description ? '. ' + node.data.description : '');

		return this.getTruncatedText(label, Math.max(width, height), fontSize);
	};

	private isAncestorOrDescendant(node1: DataItemNode, node2: DataItemNode) {
		return node1.ancestors().includes(node2) || node2.ancestors().includes(node1);
	}

	private isDescendant(parent: DataItemNode, node: DataItemNode) {
		return node.ancestors().includes(parent) && parent !== node;
	}

	private findMaxDepth(data: DataItem): number {
		if (!data.children || data.children.length === 0) {
			return 1;
		}

		let maxChildDepth = 0;

		for (const child of data.children) {
			const childDepth = this.findMaxDepth(child);

			maxChildDepth = Math.max(maxChildDepth, childDepth);
		}

		return 1 + maxChildDepth;
	}

	private findOuterNodeClosestToZero(): DataItemNode {
		let closestNode = null;
		let minAngleDifference = Infinity;

		this.chart.sunburst.pathGroup.selectAll('path')
			.filter(({ current }: DataItemNode) => current.height === 0)
			.each((node: DataItemNode) => {
				const angleDifference = Math.abs(this.getAngleToZeroInDegrees(node.current));

				if (angleDifference < minAngleDifference) {
					minAngleDifference = angleDifference;
					closestNode = node;
				}
			});

		return closestNode;
	}

	private findOuterNodeByGuid(guid: string): DataItemNode {
		const leaves = this.chart.sunburst.pathGroup.selectAll('path')
			.filter((n: DataItemNode) => n.isVisible && n.data.guid === guid);

		if (leaves.size() > 0) {
			return leaves.datum() as DataItemNode;
		}

		return null;
	}

	private getAngleToZeroInDegrees(node: DataItemNode) {
		const nodeAngle = (node.x0 + node.x1) / 2;
		const threeOClockAngle = QUARTER_CIRCLE;

		let angleToZero = this.radToDeg(threeOClockAngle - nodeAngle) % 360;

		// Calculate the difference closest to the 3 o'clock position for the shortest rotation
		if (angleToZero > 180) {
			angleToZero -= 360;
		} else if (angleToZero < -180) {
			angleToZero += 360;
		}

		return angleToZero;
	}

	private countOuterNodes(nodes: DataItemNode[]): number {
		let count = 0;

		nodes.forEach(node => {
			if (node.children) {
				count += this.countOuterNodes(node.children);
			} else if (node.isVisible) {
				count++;
			}
		});

		return count;
	}

	private getTruncatedText(text: string, size: number, fontSize = this.radiusZoomFontSize.default): string {
		if (!text) {
			return '';
		}

		const charSizeDict = {
			[this.fontSize.xs]: 2.5,
			[this.fontSize.sm]: 4.5,
			[this.fontSize.lg]: 6.5
		};

		let maxLength = size / charSizeDict[fontSize];

		if (text.length <= maxLength) {
			return text;
		}

		const ellipsisLength = 3;

		maxLength -= ellipsisLength;

		return text.substring(0, maxLength) + '...';
	}

	private getInnerRadius(ring: number) {
		const ringFactors = this.allocateRingFactors(this.currentLayer);

		return ringFactors.slice(0, ring).reduce((radius, per) => {
			radius += this.radius * per;

			return radius;
		}, 0);
	}

	private getRingHeight(ring: number, radius = this.radius) {
		const factor = this.allocateRingFactors(this.currentLayer)[ring];

		return radius * factor;
	}

	private allocateRingFactors(totalRings: number): number[] {
		const minBig = 3;
		const ringFactors = Array(totalRings);

		const bigRings = totalRings > minBig ? minBig : totalRings;
		const collapsedRings = totalRings - bigRings;
		const totalFactorForCollapsedRings = collapsedRings * this.factorForCollapsedRing;
		const factorPerRing = (1 - totalFactorForCollapsedRings) / bigRings;

		ringFactors.fill(factorPerRing, 0, bigRings);
		ringFactors.fill(this.factorForCollapsedRing, bigRings, totalRings);

		return ringFactors;
	}

	private getColor(color: string, brighter = false): string {
		if (!color) {
			color = growthColorMap.get(0);
		}

		if (brighter) {
			const brightness = color === growthColorMap.get(0) ? 0.1 : 0.2;

			return rgb(color).brighter(brightness).toString();
		}

		return color;
	}

	private getNodeColor(node: DataItemNode, brighter = false): string {
		const color = this.getGrowthColor(node.data.growths);

		return this.getColor(color, brighter);
	}

	private getNodeFontColor(node: DataItemNode): string {
		const color = this.getNodeColor(node);

		return colorFontColorMap.get(color);
	}

	private shouldFlip(node: DataItemNode, isLandscape: boolean, rotationDegrees = 0) {
		let midAngleInDegrees = (this.nodeDeg(node) + rotationDegrees) % 360;

		midAngleInDegrees = midAngleInDegrees < 0 ? 360 + midAngleInDegrees : midAngleInDegrees;

		if (isLandscape) {
			return midAngleInDegrees > 90 && midAngleInDegrees < 270;
		}

		return midAngleInDegrees > 0 && midAngleInDegrees < 180;
	}

	private isLandscape(n: DataItemNode) {
		const dimension = this.getDimension(n);

		return dimension.width > dimension.height;
	}

	private isFullCircleRing(n: DataItemNode) {
		const round = (value: number) => Math.round((value + Number.EPSILON) * 1e5) / 1e5;

		return round(FULL_CIRCLE) === round(Math.abs(n.x1 - n.x0));
	}

	private getDimension(n: DataItemNode) {
		const radiusInner = this.getInnerRadius(n.y0);
		const radiusOuter = this.getInnerRadius(n.y1);
		const radius = (radiusInner + radiusOuter) / 2;
		const circumference = FULL_CIRCLE * radius;

		const angleDifference = n.x1 - n.x0;
		const percentage = (angleDifference / FULL_CIRCLE);

		const width = circumference * percentage;
		const height = this.getRingHeight(n.y0);

		return { width, height };
	}

	private startRotation(clientX: number, clientY: number) {
		this.isDragging = false;
		this.startDegrees = this.calcAngleDegrees(clientX, clientY);

		this.dragListeners.push(this.renderer.listen(document, 'mousemove', this.onWheelMove));
		this.dragListeners.push(this.renderer.listen(document, 'touchmove', this.onWheelMove));
		this.dragListeners.push(this.renderer.listen(document, 'click', this.onWheelRelease));
		this.dragListeners.push(this.renderer.listen(document, 'touchend', this.onWheelRelease));
	}

	private rotateWheel(clientX: number, clientY: number) {
		const degrees = this.calcAngleDegrees(clientX, clientY);

		if (!this.isDragging && this.startDegrees !== null && (Math.abs(degrees - this.startDegrees) > 0)) {
			this.isDragging = true;
		}

		if (this.isDragging) {
			const rotationDegrees = degrees - this.startDegrees;

			this.chart.sunburst.layout.each((node: DataItemNode) => node.target = node.current);
			this.rotateChart(rotationDegrees, 0);
			this.startDegrees = degrees;
			this.dragDirection = rotationDegrees > 0 ? 'cw' : 'ccw';
		}
	}

	private stopRotation() {
		this.isDragging = false;
		this.startDegrees = null;
		this.clearDragListeners();
	}

	private clearDragListeners() {
		this.dragListeners.forEach(listener => listener());
		this.dragListeners = [];
	}

	private calcAngleDegrees(x: number, y: number): number {
		const { left, top, width, height } = this.chart.sunburst.group.node().getBoundingClientRect();

		const centerX = left + width / 2;
		const centerY = top + height / 2;

		const angle = Math.atan2(y - centerY, x - centerX);

		return this.radToDeg(angle);
	}

	private getGrowthColor(growths: GradeGrowthDict): string {
		const mapToColor = (value: number) => [...growthColorMap.keys()].reduce((prev, curr) => value > prev ? curr : prev, 0);

		const colors = Array.from(growths, ([key, value]) => {
			const color = mapToColor(value);

			return [key, growthColorMap.has(color) ? growthColorMap.get(color) : growthColorMap.get(0)] as [number, string];
		});

		return new ColorDict(colors).get(this.selectedColorId);
	}
}
