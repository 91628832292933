import { Directive, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';

@Directive({ selector: '[resizeObserver]' })
export class ResizeObserverDirective implements OnDestroy {
	@Output() readonly resize = new EventEmitter<DOMRectReadOnly>();

	private observer: ResizeObserver;
	private entriesMap = new WeakMap();

	constructor(private el: ElementRef) {
		const target = this.el.nativeElement;

		this.observer = new ResizeObserver(entries => {
			for (const entry of entries) {
				if (this.entriesMap.has(entry.target)) {
					const resize = this.entriesMap.get(entry.target);

					resize(entry);
				}
			}
		});

		this.entriesMap.set(target, this.resizeCallback);
		this.observer.observe(target);
	}

	ngOnDestroy(): void {
		const target = this.el.nativeElement;

		this.observer.unobserve(target);
		this.entriesMap.delete(target);
	}

	private resizeCallback = (entry: ResizeObserverEntry): void => {
		this.resize.emit(entry.contentRect);
	};
}
