import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { TooltipTriggerDirective } from './tooltip-trigger.directive';
import { TooltipComponent } from './tooltip.component';

@NgModule({
	imports: [
		CommonModule,
		OverlayModule
	],
	exports: [
		TooltipTriggerDirective
	],
	declarations: [
		TooltipTriggerDirective,
		TooltipComponent
	]
})
export class TooltipModule { }
