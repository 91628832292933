import { Component } from '@angular/core';
import { OverlayArrow } from '../overlay.const';

@Component({
	selector: 'cp-overlay-container',
	templateUrl: './overlay-container.component.html',
	styleUrls: ['./overlay-container.component.scss'],
	standalone: true
})
export class OverlayContainerComponent {
	overlayArrow = OverlayArrow;
}
