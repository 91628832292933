import { NgModule } from '@angular/core';
import { CheckboxToggleComponent } from './checkbox-toggle.component';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [
		FormsModule
	],
	exports: [
		CheckboxToggleComponent
	],
	declarations: [
		CheckboxToggleComponent
	]
})
export class CheckboxToggleModule { }
