import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { take } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { LoggingService } from '../logging.service';

@Injectable()
export class GenericErrorHandler implements ErrorHandler {
	private readonly includedStatusCodes = [401, 403, 504];

	constructor(private injector: Injector) { }

	handleError(error: Error): void {
		const loggingService = this.injector.get(LoggingService);

		// TODO: Remove this extra debug logging when we know the root cause of our failing requests problem PBI #59469
		if (error instanceof HttpErrorResponse && (!!error.status || this.includedStatusCodes.includes(error.status))) {
			const authService = this.injector.get(AuthService);

			authService.token$.pipe(
				take(1)
			).subscribe((token: string) => {
				let tokenDecodeError = '';
				let tokenExpiration: Date;
				let tokenHasExpired: boolean;

				try {
					const decoded = jwtDecode(token?.replace('Bearer ', '')) as any;

					tokenExpiration = new Date(decoded?.exp * 1000);
					tokenHasExpired = Date.now() > tokenExpiration.getTime();
				} catch (e: unknown) {
					tokenDecodeError = e?.toString();
				}
				const debugLog = {
					url: error?.url,
					status: error?.status,
					statusText: error?.statusText,
					hasToken: !!token,
					tokenDecodeError: tokenDecodeError,
					tokenExpiration: tokenExpiration,
					tokenHasExpired: tokenHasExpired
				};

				loggingService.logError(new Error(JSON.stringify(debugLog)));
			});
		} else {
			loggingService.logError(error);
		}
	}
}
