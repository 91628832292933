import { IDataWheelContext } from './data-wheel-context.interface';
import { DashboardContext } from './dashboard-context';
import { WebsiteContext } from './website-context';
import { HostContextType } from '@snappet-content-products/shared/domain';

export class DataWheelContextFactory {
	static create(contextType: HostContextType): IDataWheelContext {
		switch (contextType) {
			case 'dashboard':
				return new DashboardContext();
			case 'website':
				return new WebsiteContext();
			default:
				return new DashboardContext();
		}
	}
}
