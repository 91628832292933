import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { retry } from 'rxjs/operators';
import { genericRetryStrategy } from '@snappet-content-products/shared/util';
import {
	BestPractice, Course, Exercise, ExerciseInLesson, ExercisesInLessonBestPractices, LearningObjective,
	LearningPhase, Lesson, Section, ReadOutLoudSettings, Subject, ContentAttachment, ExtendedLessonPhase, Video, EducationalStandardTree, EducationalStandardNode, SectionGroup
} from '../..';
import { environment } from '@snappet-content-products/shared/environments';
import { Subdomain } from '../domain/content/subdomain.model';

@Injectable()
export class ContentService {
	private readonly apiUrl: string = environment.contentApiUrl;

	constructor(private readonly httpClient: HttpClient) { }

	getLesson(lessonId: number): Observable<Lesson> {
		return this.httpClient.get<Lesson>(`${this.apiUrl}/Lessons/${lessonId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLessons(lessonIds: number[]): Observable<Lesson[]> {
		if (!lessonIds.length) {
			return of([]);
		}

		const ids = lessonIds.map(id => `${id}`).join(',');

		return this.httpClient.get<Lesson[]>(`${this.apiUrl}/Lessons?ids=${ids}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLessonsBySection(sectionId: number): Observable<Lesson[]> {
		return this.httpClient.get<Lesson[]>(`${this.apiUrl}/Lessons/filter/by-section/${sectionId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLessonsByLearningObjective(learningObjectiveId: number): Observable<Lesson[]> {
		return this.httpClient.get<Lesson[]>(`${this.apiUrl}/Lessons/filter/by-learningObjective/${learningObjectiveId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLessonByLearningObjectives(learningObjectiveIds: number[], preferredCourseId?: number, preferredGradeId?: number): Observable<Lesson[]> {
		if (!learningObjectiveIds.length) {
			return of([]);
		}

		const ids = learningObjectiveIds.map(id => `${id}`).join(',');
		const courseIdQueryString = preferredCourseId ? `&preferredCourseId=${preferredCourseId}` : '';
		const gradeIdQueryString = preferredGradeId ? `&preferredGradeId=${preferredGradeId}` : '';

		return this.httpClient.get<Lesson[]>(
			`${this.apiUrl}/Lessons/map/by-learning-objectives?ids=${ids}${courseIdQueryString}${gradeIdQueryString}`)
			.pipe(
				retry(genericRetryStrategy())
			);
	}

	getLessonInstruction(lessonId: number): Observable<string> {
		return this.httpClient.get(`${this.apiUrl}/Lessons/${lessonId}/instruction`, { responseType: 'text' }).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLearningObjectiveInstruction(learningObjectiveId: number): Observable<string> {
		return this.httpClient.get(`${this.apiUrl}/LearningObjectives/${learningObjectiveId}/instruction`, { responseType: 'text' }).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLessonPhases(lessonId: number): Observable<ExtendedLessonPhase[]> {
		return this.httpClient.get<ExtendedLessonPhase[]>(`${this.apiUrl}/ExercisesInLesson/filter/by-lesson/${lessonId}/group-by-lesson-phase`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getExerciseInLesson(exerciseInLessonId: number): Observable<ExerciseInLesson> {
		return this.httpClient.get<ExerciseInLesson>(`${this.apiUrl}/ExercisesInLesson/${exerciseInLessonId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getExercise(exerciseId: number): Observable<Exercise> {
		return this.httpClient.get<Exercise>(`${this.apiUrl}/Exercises/${exerciseId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getExercises(exerciseIds: number[]): Observable<Exercise[]> {
		if (!exerciseIds.length) {
			return throwError(() => new Error('No exercise ids provided'));
		}

		const ids = exerciseIds.map(id => `${id}`).join(',');
		const url = `${this.apiUrl}/Exercises?ids=${ids}`;

		return this.httpClient.get<Exercise[]>(url).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLearningObjective(learningObjectiveId: number): Observable<LearningObjective> {
		return this.httpClient.get<LearningObjective>(`${this.apiUrl}/LearningObjectives/${learningObjectiveId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLearningObjectives(loIds: number[]): Observable<LearningObjective[]> {
		if (!loIds.length) {
			return of([]);
		}

		const ids = loIds.join(',');

		return this.httpClient.get<LearningObjective[]>(`${this.apiUrl}/LearningObjectives?ids=${ids}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLearningObjectivesByLessonIds(lessonIds: number[]): Observable<LearningObjective[]> {
		if (!lessonIds.length) {
			return of([]);
		}

		const ids = lessonIds.map(id => `${id}`).join(',');

		return this.httpClient.get<LearningObjective[]>(`${this.apiUrl}/LearningObjectives/filter/by-lessons?ids=${ids}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getSection(section: number): Observable<Section> {
		return this.httpClient.get<Section>(`${this.apiUrl}/Sections/${section}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getSectionsByCourse(courseId: number): Observable<Section[]> {
		return this.httpClient.get<Section[]>(`${this.apiUrl}/Sections/filter/by-course/${courseId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getSectionGroupsByCourse(courseId: number): Observable<SectionGroup[]> {
		return this.httpClient.get<SectionGroup[]>(`${this.apiUrl}/Sections/filter/by-course/${courseId}/group-by-section-group`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getCourse(courseId: number): Observable<Course> {
		return this.httpClient.get<Course>(`${this.apiUrl}/Courses/${courseId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getCoursesBySubjectId(subjectId: number, gradeId?: number): Observable<Course[]> {
		const queryString = gradeId ? `?gradeId=${gradeId}` : '';

		return this.httpClient.get<Course[]>(`${this.apiUrl}/Courses/filter/by-subject/${subjectId}${queryString}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getCourseAttachments(courseId: number): Observable<ContentAttachment[]> {
		return this.httpClient.get<ContentAttachment[]>(`${this.apiUrl}/Courses/${courseId}/attachments`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLessonAttachments(lessonId: number): Observable<ContentAttachment[]> {
		return this.httpClient.get<ContentAttachment[]>(`${this.apiUrl}/Lessons/${lessonId}/attachments`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getSubject(subjectId: number): Observable<Subject> {
		return this.httpClient.get<Subject>(`${this.apiUrl}/Subjects/${subjectId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getSubjectsByCountryId(countryId: number): Observable<Subject[]> {
		return this.httpClient.get<Subject[]>(`${this.apiUrl}/Subjects/filter/by-country/${countryId}?isAvailableForClasses=true`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLearningPhases(learningPhaseIds: number[]): Observable<LearningPhase[]> {
		const ids = learningPhaseIds.map(id => `ids=${id}`).join('&');

		return this.httpClient.get<LearningPhase[]>(`${this.apiUrl}/LearningPhases?${ids}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLearningPhasesByTemplateId(templateId: number): Observable<LearningPhase[]> {
		return this.httpClient.get<LearningPhase[]>(`${this.apiUrl}/LearningPhases/filter/by-template/${templateId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getBestPracticesByExerciseInLesson(exerciseInLessonId: number): Observable<ExercisesInLessonBestPractices[]> {
		return this.httpClient.get<ExercisesInLessonBestPractices[]>(`${this.apiUrl}/BestPractices/filter/by-exerciseInLesson/${exerciseInLessonId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getBestPracticesByExerciseInLessons(exerciseInLessonIds: number[]): Observable<ExercisesInLessonBestPractices[]> {
		if (!exerciseInLessonIds.length) {
			return of([]);
		}

		const ids = exerciseInLessonIds.map(id => `ids=${id}`).join('&');

		return this.httpClient.get<ExercisesInLessonBestPractices[]>(`${this.apiUrl}/BestPractices/filter/by-exerciseInLessons?${ids}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getBestPractice(bestPracticeId: number): Observable<BestPractice> {
		return this.httpClient.get<BestPractice>(`${this.apiUrl}/BestPractices/${bestPracticeId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getBestPractices(bestPracticeIds: number[]): Observable<BestPractice[]> {
		if (!bestPracticeIds.length) {
			return of([]);
		}

		const ids = bestPracticeIds.map(id => `ids=${id}`).join('&');

		return this.httpClient.get<BestPractice[]>(`${this.apiUrl}/BestPractices?${ids}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getReadOutLoudSettings(subjectId: number): Observable<ReadOutLoudSettings> {
		return this.httpClient.get<ReadOutLoudSettings>(`${this.apiUrl}/SpeakerSettings/${subjectId}`);
	}

	getReadOutLoudSettingProfiles(): Observable<ReadOutLoudSettings[]> {
		return this.httpClient.get<ReadOutLoudSettings[]>(`${this.apiUrl}/SpeakerSettings`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getSubDomain(subDomainId: number): Observable<Subdomain> {
		return this.httpClient.get<Subdomain>(`${this.apiUrl}/Subdomains/${subDomainId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getVideosByLearningObjective(learningObjectiveId: number, { gradeId }: { gradeId: number }): Observable<Video[]> {
		return this.httpClient.get<Video[]>(`${this.apiUrl}/Videos/filter/by-learning-objective/${learningObjectiveId}?gradeId=${gradeId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getEducationalStandardTrees(subjectId: number, gradeId?: number): Observable<EducationalStandardTree[]> {
		const queryString = gradeId ? `?gradeId=${gradeId}` : '';

		return this.httpClient.get<EducationalStandardTree[]>(`${this.apiUrl}/EducationalStandardTrees/filter/by-subject/${subjectId}${queryString}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getEducationalStandardNodes(educationalStandardTreeId: number): Observable<EducationalStandardNode[]> {
		return this.httpClient.get<EducationalStandardNode[]>(`${this.apiUrl}/EducationalStandardNodes/filter/by-educational-standard-tree/${educationalStandardTreeId}`).pipe(
			retry(genericRetryStrategy())
		);
	}

	getLearningObjectivesForSubject(subjectId: number): Observable<LearningObjective[]> {
		return this.httpClient.get<LearningObjective[]>(`${this.apiUrl}/LearningObjectives/filter/by-subject/${subjectId}`).pipe(
			retry(genericRetryStrategy())
		);
	}
}
