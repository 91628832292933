<div class="activity"
     data-detail-level="1"
     data-test="lesson-card">
    <div class="activity-body">
        <div class="activity-body-left">
            <ng-container [ngTemplateOutlet]="leftCardContent ?? leftCardTemplate"></ng-container>
        </div>
        <div *ngIf="learningObjectiveIcon || loIconUrl"
             class="activity-body-center">
            <cp-learning-objective-icon class="icon"
                                        data-test="lo-icon"
                                        [sourceUrl]="learningObjectiveIcon ?? loIconUrl"
                                        [width]="100"
                                        [height]="100"></cp-learning-objective-icon>
        </div>
    </div>
    <div class="activity-footer">
        <span *ngIf="lessonType"
              class="activity-tag">{{ 'COURSE_VIEW.LESSON_TYPES.' + lessonType | translate }}</span>
    </div>
    <ng-content select="[cp-icon-button]"></ng-content>
</div>

<ng-template #leftCardTemplate>
    <h2 *ngIf="lessonLabel"
        class="title"
        data-test="lesson-name"
        [title]="lessonLabel"><span>{{ lessonLabel }}</span><cp-icon *ngIf="lessonId > 0"
                 iconId="Right"></cp-icon></h2>
    <h3 *ngIf="learningObjectiveLabel"
        class="description"
        data-test="lo-name"
        [title]="learningObjectiveLabel"><span>{{ learningObjectiveLabel }}</span></h3>
</ng-template>