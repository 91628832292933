<div class="data-wheel-container"
	 (direction)="onRotate($event)"
	 cpDataWheelInteraction>
	<cp-top-bar class="data-wheel-topbar"
				[entities]="entities"
				[market]="market"
				[filter]="filter"
				[selectedView]="selectedView"
				[dataWheelContext]="dataWheelContext"
				(filterChange)="onFilterChange($event)"
				(logoutClick)="logoutClick.emit()"
				(loginClick)="loginClick.emit()">
	</cp-top-bar>
	<div class="data-wheel-content">
		<div class="data-wheel-content-inner">
			<cp-data-wheel-controller class="data-wheel-controller"
									  [data]="data"
									  [selectedView]="selectedView"
									  [pathLabel]="pathLabel"
									  [selectedState]="selectedState"
									  [radiusZoomFactor]="radiusZoomFactor"
									  [colors]="colors"
									  [selectedColorId]="selectedColorId"
									  [innerCircleCallback]="innerCircleCallback"
									  [magnifyingGlassTemplate]="magnifyingGlassTemplate"
									  (clickSelect)="onClickSelect($event)"
									  (rotateSelect)="onRotateSelect($event)"
									  (selectedViewChange)="onSelectedViewChange($event)"
									  (selectedColorChange)="onSelectedColorChange($event)"
									  (rotate)="onRotate($event)"
									  (zoomBy)="onZoomBy($event)"
									  (renderingDone)="renderingDone.emit()"></cp-data-wheel-controller>
		</div>
	</div>
</div>

<ng-template #magnifyingGlassTemplate>
	<cp-data-wheel-magnifying-glass [id]="lessonListData.selectedLesson?.id"
									[template]="lessonCardTemplate">
	</cp-data-wheel-magnifying-glass>
</ng-template>

<ng-template #lessonCardTemplate>
	<cp-data-wheel-lesson-card *ngIf="lessonListData.selectedLesson as lesson"
							   class="lesson-card"
							   data-test="lesson-card"
							   [class.disabled]="lesson.id <= 0"
							   [lessonId]="lesson.id"
							   [lessonLabel]="lesson.label"
							   [learningObjectiveId]="lesson.loId"
							   [learningObjectiveLabel]="lessonListData.learningObjective?.label"
							   (click)="onLessonClick(lesson)">
	</cp-data-wheel-lesson-card>
</ng-template>