import { Injectable } from '@angular/core';
import { Region, RegionConfig } from '../..';
import { regionConfig as regionConfigEuMaster } from './configs/region-config.eu.master';
import { regionConfig as regionConfigUsMaster } from './configs/region-config.us.master';
import { regionConfig as regionConfigEuProd } from './configs/region-config.eu.prod';
import { regionConfig as regionConfigUsProd } from './configs/region-config.us.prod';
import { environment } from '@snappet-content-products/shared/environments';

@Injectable({ providedIn: 'root' })
export class RegionConfigService {
	config: RegionConfig;

	setConfig(region: Region): void {
		this.config = this.mapToConfig(region);
	}

	private mapToConfig(region: Region): RegionConfig {
		switch (region) {
			case 'us':
				return environment.production ? regionConfigUsProd : regionConfigUsMaster;
			case 'eu':
			default:
				return environment.production ? regionConfigEuProd : regionConfigEuMaster;
		}
	}
}
