<cp-dropdown *ngIf="showSubMarketsFilter"
			 class="filter-dropdown"
			 data-test="sub-markets-filter"
			 size="small"
			 [options]="subMarketOptions"
			 [selectedOptions]="selectedSubMarketOptions"
			 [multiSelect]="true"
			 [placeholder]="'DATA_WHEEL.SUB_MARKETS' | translate"
			 (selectedOptionsChange)="onSelectedSubMarketOptionsChange($event)"></cp-dropdown>

<cp-dropdown class="filter-dropdown"
			 data-test="subjects-filter"
			 size="small"
			 [options]="subjectOptions"
			 [selectedOptions]="selectedSubjectOptions"
			 [multiSelect]="true"
			 [placeholder]="'DATA_WHEEL.SUBJECTS' | translate"
			 (selectedOptionsChange)="onSelectedSubjectOptionsChange($event)"></cp-dropdown>

<cp-dropdown *ngIf="showGradesFilter"
			 class="filter-dropdown"
			 data-test="grades-filter"
			 size="small"
			 [options]="gradeOptions"
			 [selectedOptions]="selectedGradeOptions"
			 [multiSelect]="true"
			 [placeholder]="'DATA_WHEEL.GRADES' | translate"
			 (selectedOptionsChange)="onSelectedGradeOptionsChange($event)"></cp-dropdown>

<cp-dropdown *ngIf="showCyclesFilter"
			 class="filter-dropdown"
			 data-test="cycles-filter"
			 size="small"
			 [options]="cycleOptions"
			 [selectedOptions]="selectedCycleOptions"
			 [multiSelect]="true"
			 [placeholder]="'DATA_WHEEL.CYCLES' | translate"
			 (selectedOptionsChange)="onSelectedCycleOptionsChange($event)"></cp-dropdown>

<cp-dropdown *ngIf="!showSubMarketsFilter"
			 class="filter-dropdown"
			 data-test="educational-standards-filter"
			 size="small"
			 [options]="educationalStandardOptions"
			 [selectedOptions]="selectedEducationalStandardOptions"
			 [multiSelect]="true"
			 [placeholder]="'DATA_WHEEL.STANDARDS' | translate"
			 (selectedOptionsChange)="onSelectedEducationalStandardOptionsChange($event)"></cp-dropdown>

<cp-dropdown *ngIf="!showSubMarketsFilter"
			 class="filter-dropdown"
			 data-test="course-groups-filter"
			 size="small"
			 [options]="courseGroupOptions"
			 [selectedOptions]="selectedCourseGroupOptions"
			 [multiSelect]="true"
			 [placeholder]="'DATA_WHEEL.COURSE' | translate"
			 (selectedOptionsChange)="onSelectedCourseGroupOptionsChange($event)"></cp-dropdown>