import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DoBootstrap, ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SharedAngularModule } from '@snappet-content-products/shared/angular';
import { SharedDomainModule } from '@snappet-content-products/shared/domain';
import { environment } from '@snappet-content-products/shared/environments';
import { SharedUiModule } from '@snappet-content-products/shared/ui';
import {
	AUTH_CONFIG, AuthInterceptor, GenericErrorHandler,
	HttpErrorInterceptor, LoggingService, SharedUtilModule, defineCustomElement
} from '@snappet-content-products/shared/util';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppComponent } from './app.component';
import { AuthConfig } from './auth.config';
import { DataWheelComponent } from './data-wheel/data-wheel.component';
import { DataWheelFeatureModule } from '@snappet-content-products/data-wheel/feature';
import { TopBarComponent } from './top-bar/top-bar.component';

@NgModule({
	declarations: [
		AppComponent,
		DataWheelComponent,
		TopBarComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		HttpClientModule,
		OAuthModule.forRoot(),
		SharedUtilModule,
		SharedDomainModule,
		SharedUiModule,
		SharedAngularModule,
		DataWheelFeatureModule
	],
	providers: [
		LoggingService,
		{ provide: 'rayGunKey', useValue: environment.rayGunKey.dataWheel },
		{ provide: AUTH_CONFIG, useValue: new AuthConfig() },
		{ provide: ErrorHandler, useClass: GenericErrorHandler },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
	]
})
export class AppModule implements DoBootstrap {
	constructor(private readonly injector: Injector) { }

	ngDoBootstrap(): void {
		[
			{ tagName: 'snappet-data-wheel-app', element: AppComponent },
			{ tagName: 'snappet-data-wheel', element: DataWheelComponent }
		].forEach(input => defineCustomElement(this.injector, input.tagName, input.element));
	}
}
