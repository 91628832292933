<div *ngIf="!isLoggedIn"
	 class="login-controls">
	<div class="form">
		<div class="form-control">
			<input type="radio"
				   id="eu"
				   name="region"
				   value="eu"
				   [(ngModel)]="region"
				   (ngModelChange)="regionChange()">
			<label for="eu">EU</label>
		</div>
		<div class="form-control">
			<input type="radio"
				   id="us"
				   name="region"
				   value="us"
				   [(ngModel)]="region"
				   (ngModelChange)="regionChange()">
			<label for="us">US</label>
		</div>
	</div>
	<button class="btn btn-login"
			data-test="login"
			size="xs"
			color="primary"
			(click)="login()"
			cp-button>Login</button>
</div>

<div class="form-controls"
	 *ngIf="isLoggedIn">
	<button class="btn btn-logout"
			data-test="logout"
			size="xs"
			color="light"
			(click)="logout()"
			cp-button>Logout</button>

	<span class="branch-identifier">Branch: {{branchIdentifier}}</span>

	<form *ngIf="formControls.length > 0"
		  [formGroup]="formGroup"
		  (ngSubmit)="submit()">
		<div class="form-control"
			 *ngFor="let control of formControls; trackBy: trackByFn"
			 [attr.title]="control.key">

			<ng-container [ngSwitch]="control.type">

				<input *ngSwitchCase="'text'"
					   type="text"
					   [attr.data-test]="control.dataTest"
					   [id]="control.key"
					   [formControlName]="control.key"
					   [placeholder]="control.key"
					   autocomplete="on" />

				<input *ngSwitchCase="'checkbox'"
					   type="checkbox"
					   [attr.data-test]="control.dataTest"
					   [id]="control.key"
					   [checked]="control.value"
					   [formControlName]="control.key" />

				<select *ngSwitchCase="'select'"
						[attr.data-test]="control.dataTest"
						[formControlName]="control.key"
						[id]="control.key">
					<option *ngFor="let option of control.options"
							[value]="option"
							[selected]="option === control.value">{{ option }}</option>
				</select>

				<label [for]="control.key"
					   *ngIf="control.label">{{control.label}}</label>

			</ng-container>
		</div>

		<button type="submit"
				class="btn"
				data-test="submit"
				size="xs"
				color="primary"
				cp-button>Submit</button>
	</form>

	<ng-content></ng-content>
</div>