import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DataFilter, DataFilterOption, DataWheelView, EntityTypeEnum, DataEntities } from '@snappet-content-products/data-wheel/domain';
import { DropdownOption } from '@snappet-content-products/shared/ui';
import { Market } from '@snappet/enums/dist';
import { BehaviorSubject, Subject, combineLatest, filter, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'cp-data-wheel-filter',
	templateUrl: './data-wheel-filter.component.html',
	styleUrls: ['./data-wheel-filter.component.scss']
})
export class DataWheelFilterComponent implements OnChanges, OnDestroy {
	@Input() market: Market;
	@Input() selectedView: DataWheelView = 'standards';
	@Input() filter: DataFilter;
	@Input() entities: DataEntities;

	@Output() readonly filterChange = new EventEmitter<DataFilter>();

	subjectOptions: DropdownOption[] = [];
	selectedSubjectOptions: DropdownOption[] = [];

	gradeOptions: DropdownOption[] = [];
	selectedGradeOptions: DropdownOption[] = [];

	cycleOptions: DropdownOption[] = [];
	selectedCycleOptions: DropdownOption[] = [];

	subMarketDataFilterOptions: DataFilterOption[] = [];
	subMarketOptions: DropdownOption[] = [];
	selectedSubMarketOptions: DropdownOption[] = [];

	courseGroupDataFilterOptions: DataFilterOption[] = [];
	courseGroupOptions: DropdownOption[] = [];
	selectedCourseGroupOptions: DropdownOption[] = [];

	educationalStandardDataFilterOptions: DataFilterOption[] = [];
	educationalStandardOptions: DropdownOption[] = [];
	selectedEducationalStandardOptions: DropdownOption[] = [];

	private readonly filterSubject = new BehaviorSubject<DataFilter>(null);
	private readonly entitiesSubject = new BehaviorSubject<DataEntities>(null);
	private readonly destroy$ = new Subject<void>();

	constructor() {
		combineLatest([this.filterSubject, this.entitiesSubject]).pipe(
			filter(([dataFilter, dataEntities]) => !!dataFilter && !!dataEntities),
			tap(([dataFilter, { subjects, grades, cycles, courseGroups, educationalStandards, subMarkets }]) => {
				if (subjects) {
					this.subjectOptions = subjects.map(({ label, id }) => ({
						label,
						value: id.toString(),
						selected: dataFilter[EntityTypeEnum.Subject].includes(id)
					} as DropdownOption));
					this.selectedSubjectOptions = this.subjectOptions.filter(o => o.selected);
				}
				if (grades) {
					this.gradeOptions = grades.map(({ label, id }) => ({
						label,
						value: id.toString(),
						selected: dataFilter[EntityTypeEnum.Grade].includes(id)
					} as DropdownOption));
					this.selectedGradeOptions = this.gradeOptions.filter(o => o.selected);
				}
				if (cycles) {
					this.cycleOptions = cycles.map(({ label, id }) => ({
						label,
						value: id.toString(),
						selected: dataFilter[EntityTypeEnum.Cycle].includes(id)
					} as DropdownOption));
					this.selectedCycleOptions = this.cycleOptions.filter(o => o.selected);
				}
				if (subMarkets) {
					this.subMarketDataFilterOptions = this.mergeDataFilterOptions(subMarkets.map(({ label, id }) => ({
						label,
						value: id,
						selected: dataFilter[EntityTypeEnum.SubMarket].includes(id)
					} as DataFilterOption)));
					this.subMarketOptions = this.subMarketDataFilterOptions
						.map(({ label, value, selected }) => ({ label, selected, value: value.toString() } as DropdownOption));
					this.selectedSubMarketOptions = this.subMarketOptions.filter(o => o.selected);
				}
				if (courseGroups) {
					this.courseGroupDataFilterOptions =
						this.mergeDataFilterOptions(courseGroups.map(({ label, id }) => ({
							label,
							value: id,
							selected: dataFilter[EntityTypeEnum.CourseGroup].includes(id)
						} as DataFilterOption)));
					this.courseGroupOptions = this.courseGroupDataFilterOptions
						.map(({ label, value, selected }) => ({ label, selected, value: value.toString() } as DropdownOption));
					this.selectedCourseGroupOptions = this.courseGroupOptions.filter(o => o.selected);
				}
				if (educationalStandards) {
					this.educationalStandardDataFilterOptions =
						this.mergeDataFilterOptions(educationalStandards.map(({ label, id }) => ({
							label,
							value: id,
							selected: dataFilter[EntityTypeEnum.EducationalStandard].includes(id)
						} as DataFilterOption)));
					this.educationalStandardOptions = this.educationalStandardDataFilterOptions
						.map(({ label, value, selected }) => ({ label, selected, value: value.toString() } as DropdownOption));
					this.selectedEducationalStandardOptions = this.educationalStandardOptions.filter(o => o.selected);
				}
			}),
			takeUntil(this.destroy$)
		).subscribe();
	}

	get showCyclesFilter() {
		return this.market === Market.Es && this.selectedView === 'standards';
	}

	get showGradesFilter() {
		return !this.showCyclesFilter;
	}

	get showSubMarketsFilter() {
		return this.entities?.subMarkets.length > 0;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['filter'] && this.filter) {
			this.filterSubject.next(this.filter);
		}
		if (changes['entities'] && this.entities) {
			this.entitiesSubject.next(this.entities);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	onSelectedSubjectOptionsChange(options: DropdownOption[]): void {
		this.selectedSubjectOptions = options;
		this.doFilterChange();
	}

	onSelectedGradeOptionsChange(options: DropdownOption[]): void {
		this.selectedGradeOptions = options;
		this.doFilterChange();
	}

	onSelectedCycleOptionsChange(options: DropdownOption[]): void {
		this.selectedCycleOptions = options;
		this.doFilterChange();
	}

	onSelectedSubMarketOptionsChange(options: DropdownOption[]): void {
		this.selectedSubMarketOptions = options;
		this.doFilterChange();
	}

	onSelectedCourseGroupOptionsChange(options: DropdownOption[]): void {
		this.selectedCourseGroupOptions = options;
		this.doFilterChange();
	}

	onSelectedEducationalStandardOptionsChange(options: DropdownOption[]): void {
		this.selectedEducationalStandardOptions = options;
		this.doFilterChange();
	}

	private doFilterChange(): void {
		this.filterChange.emit({
			[EntityTypeEnum.Subject]: this.selectedSubjectOptions.map(o => +o.value),
			[EntityTypeEnum.Grade]: this.selectedGradeOptions.map(o => +o.value),
			[EntityTypeEnum.CourseGroup]: this.flattenDataFilterOptions(this.courseGroupDataFilterOptions, this.selectedCourseGroupOptions),
			[EntityTypeEnum.EducationalStandard]: this.flattenDataFilterOptions(this.educationalStandardDataFilterOptions, this.selectedEducationalStandardOptions),
			[EntityTypeEnum.Cycle]: this.selectedCycleOptions.map(o => +o.value),
			[EntityTypeEnum.SubMarket]: this.flattenDataFilterOptions(this.subMarketDataFilterOptions, this.selectedSubMarketOptions)
		});
	}

	private mergeDataFilterOptions(nodes: DataFilterOption[]): DataFilterOption[] {
		return nodes.reduce((mergedOptions, { label, value, selected }) => {
			const existing = mergedOptions.find(item => item.label === label);

			if (existing) {
				existing.entityIds.push(+value);
			} else {
				mergedOptions.push({ label, value, selected, entityIds: [+value] });
			}
			return mergedOptions;
		}, [] as DataFilterOption[]);
	}

	private flattenDataFilterOptions(options: DataFilterOption[], selectedOptions: DropdownOption[]): number[] {
		if (!options || !selectedOptions) {
			return [];
		}
		const selectedValues = selectedOptions.map(o => o.value);

		return options.filter(option => selectedValues.includes(option.value.toString())).flatMap(option => option.entityIds);
	}
}
