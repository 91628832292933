import { Injectable } from '@angular/core';
import { DataFilter, DataItem } from '../domain';
import { EntityTypeEnum } from './entities';

@Injectable()
export class DataFilterService {
	filterEntities(entities: DataItem[], filter: DataFilter, shouldUseSubMarketFilter: boolean): DataItem[] {
		const filteredEntities = [];
		const filterIncludesEntity = (entity: DataItem) => filter[entity.type]?.length > 0 ? filter[entity.type].includes(entity.id) : true;

		entities?.forEach(entity => {
			// Post Pilot Action: This should be refactored to be more generic if we keep this capability in the copilot.
			let shouldShow = true;

			if (shouldUseSubMarketFilter && [EntityTypeEnum.StandardNode, EntityTypeEnum.CourseGroup].includes(entity.type)) {
				if (filter[EntityTypeEnum.SubMarket]?.length > 0) {
					shouldShow = entity.filterIds?.some(id => filter[EntityTypeEnum.SubMarket].includes(id)) || false;
				} else {
					shouldShow = filterIncludesEntity(entity);
				}
			} else {
				shouldShow = filterIncludesEntity(entity);
			}

			if (shouldShow) {
				const filteredEntity = { ...entity, children: [] };

				filteredEntities.push(filteredEntity);
				filteredEntity.children = this.filterEntities(entity.children, filter, shouldUseSubMarketFilter);
			}
		});

		return filteredEntities;
	}

	hasFilterApplied(filter: DataFilter): boolean {
		return Object.values(filter).flat().length > 0;
	}

	removeFilter(filter: DataFilter, filterRemoveOrder: EntityTypeEnum[], index = 0): DataFilter {
		if (index >= filterRemoveOrder.length) {
			return filter;
		}

		const entityType = filterRemoveOrder[index];

		if (filter[entityType].length > 0) {
			filter[entityType] = [];
			return filter;
		}

		return this.removeFilter(filter, filterRemoveOrder, index + 1);
	}
}
