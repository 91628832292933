import { trigger, animate, style, transition, state } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import {
	ANIMATION_DURATION, Color, DataItem, DataWheelDirection, DataWheelView, MAX_ZOOM_FACTOR, MIN_ZOOM_FACTOR,
	RADIUS_ZOOM_MULTIPLIER, SelectedState, ZoomEvent
} from '@snappet-content-products/data-wheel/domain';

@Component({
	selector: 'cp-data-wheel-controller',
	templateUrl: './data-wheel-controller.component.html',
	styleUrls: ['./data-wheel-controller.component.scss'],
	animations: [
		trigger('scaleInOut', [
			state('true', style({ height: '100%' })),
			state('false', style({ height: '*' })),
			transition('false <=> true', animate(ANIMATION_DURATION))
		]),
		trigger('fadeInOut', [
			state('true', style({ opacity: 1 })),
			state('false', style({ opacity: 0 })),
			transition('false => true', animate(`${ANIMATION_DURATION}ms ${ANIMATION_DURATION}ms`))
		])
	],
	host: {
		'[class.zoomed-out]': 'radiusZoomFactor === 1',
		'[class.landscape]': 'isLandscape'
	}
})
export class DataWheelControllerComponent implements AfterViewInit {
	@Input() data: DataItem;
	@Input() selectedView: DataWheelView;
	@Input() selectedState: SelectedState;
	@Input() pathLabel: string[];
	@Input() radiusZoomFactor = 1;
	@Input() colors: Color[];
	@Input() selectedColorId: number;
	@Input() innerCircleCallback: () => void;
	@Input() gapFactor = 1;
	@Input() hideViewToggle: boolean;
	@Input() magnifyingGlassTemplate: TemplateRef<unknown>;

	@Output() readonly clickSelect = new EventEmitter<number>();
	@Output() readonly rotateSelect = new EventEmitter<number>();
	@Output() readonly renderingDone = new EventEmitter<void>();
	@Output() readonly selectedViewChange = new EventEmitter<DataWheelView>();
	@Output() readonly selectedColorChange = new EventEmitter<number>();
	@Output() readonly rotate = new EventEmitter<DataWheelDirection>();
	@Output() readonly zoomBy = new EventEmitter<number>();

	@ViewChild('dataWheel') dataWheel: ElementRef<HTMLElement>;

	isLandscape = false;

	constructor(private readonly cd: ChangeDetectorRef) { }

	get hasData(): boolean {
		return !!this.data?.children?.length;
	}

	ngAfterViewInit(): void {
		this.isLandscape = this.dataWheel?.nativeElement.clientWidth > this.dataWheel?.nativeElement.clientHeight;
	}

	onSelectedColorChange(selectedColorId: number) {
		this.selectedColorChange.emit(selectedColorId);
	}

	onSelectedViewChange(selectedView: DataWheelView) {
		this.selectedViewChange.emit(selectedView);
	}

	onClockwise(event: Event): void {
		event.stopPropagation();
		this.rotate.emit('ccw');
	}

	onCounterclockwise(event: Event): void {
		event.stopPropagation();
		this.rotate.emit('cw');
	}

	onZoom(zoomEvent: ZoomEvent): void {
		const currentZoomFactor = this.radiusZoomFactor;

		if (currentZoomFactor === MIN_ZOOM_FACTOR && zoomEvent === 'out' || currentZoomFactor === MAX_ZOOM_FACTOR && zoomEvent === 'in') {
			return;
		}

		const newZoomFactor = zoomEvent === 'in'
			? currentZoomFactor * RADIUS_ZOOM_MULTIPLIER
			: currentZoomFactor / RADIUS_ZOOM_MULTIPLIER;

		if (newZoomFactor < MIN_ZOOM_FACTOR || newZoomFactor > MAX_ZOOM_FACTOR) {
			return;
		}

		this.zoomBy.emit(newZoomFactor);
	}

	onResize({ width, height }: DOMRectReadOnly): void {
		this.isLandscape = width > height;
		this.cd.detectChanges();
	}
}
