import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../button/button.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { DropdownTriggerDirective } from './dropdown-trigger.directive';
import { DropdownComponent } from './dropdown.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
	imports: [
		CommonModule,
		CdkMenuModule,
		ButtonModule,
		SpinnerModule,
		IconModule
	],
	exports: [
		DropdownComponent
	],
	declarations: [
		DropdownComponent,
		DropdownTriggerDirective
	]
})
export class DropdownModule { }
