import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoggingService } from '../logging.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	private readonly excludedStatusCodes = [401, 403, 404, 504];

	constructor(private logginService: LoggingService) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			// eslint-disable-next-line rxjs/no-implicit-any-catch
			catchError((error: HttpErrorResponse) => {
				let errorMessage = '';

				if (error.error instanceof ErrorEvent) {
					errorMessage = `Error: ${error.error.message}`;
				} else if (!this.excludedStatusCodes.includes(error.status)) {
					errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
				}

				if (errorMessage) {
					this.logginService.logError(new Error(errorMessage));
				}

				return throwError(error);
			})
		);
	}
}
