import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseAppComponent } from '@snappet-content-products/shared/angular';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppService } from './app.service';
import { Market } from '@snappet/enums/dist';
import { LoggingService } from '@snappet-content-products/shared/util';
import { environment } from '@snappet-content-products/shared/environments';

@Component({
	selector: 'cp-data-wheel-app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [
		AppService
	]
})
export class AppComponent extends BaseAppComponent {
	showDataWheel: boolean;
	market: Market;
	context: string;
	loColors: string;

	constructor(
		private readonly appService: AppService,
		private readonly loggingService: LoggingService,
		protected override oauthService: OAuthService,
		protected override translateService: TranslateService
	) {
		super(oauthService, translateService);

		loggingService.isDebugEnabled = environment.name === 'master';

		this.restoreValues();

		this.tokenHandler().subscribe(() => {
			this.showDataWheel = true;
			this.updateFormControls();
		});
	}

	onLessonClick(lesson: { id: number }): void {
		this.loggingService.debug(`Lesson click: ${JSON.stringify(lesson)}`);
	}

	onLogoutClick(): void {
		this.loggingService.debug('Logout click');
	}

	onLoginClick(): void {
		this.loggingService.debug('Login click');
	}

	private restoreValues(): void {
		[
			{ key: 'market', type: 'number', defaultValue: Market.Nl },
			{ key: 'context', type: 'string', defaultValue: 'website' },
			{ key: 'loColors', type: 'string', defaultValue: '' }
		].forEach(({ key, type, defaultValue }) => this.restoreValue(key, type, defaultValue));
	}

	private updateFormControls(): void {
		this.formControls = this.appService.getLoginFormControls({
			market: this.market,
			context: this.context,
			loColors: this.loColors
		});
	}
}
