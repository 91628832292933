import { Injectable } from '@angular/core';
import { DataItem } from '../domain';
import { EntityTypeEnum } from '../domain/entities';

@Injectable()
export class DataSelectorService {
	getLevelByType(nodes: DataItem[], type: EntityTypeEnum, foundItems: DataItem[] = []): DataItem[] {
		for (const node of nodes) {
			if (node.type === type) {
				foundItems.push(node);
			}

			if (node.children) {
				this.getLevelByType(node.children, type, foundItems);
			}
		}

		return foundItems;
	}

	getNodeByGuid(nodes: DataItem[], guid?: string): DataItem {
		for (const node of nodes) {
			if (node.guid === guid) {
				return node;
			} else if (Array.isArray(node.children)) {
				const found = this.getNodeByGuid(node.children, guid);

				if (found) {
					return found;
				}
			}
		}

		return null;
	}

	getNodeById(nodes: DataItem[], type: EntityTypeEnum, id?: number): DataItem {
		for (const node of nodes) {
			if (node.id === id && node.type === type) {
				return node;
			} else if (Array.isArray(node.children)) {
				const found = this.getNodeById(node.children, type, id);

				if (found) {
					return found;
				}
			}
		}

		return null;
	}

	getFirstLeaf(nodes: DataItem[]): DataItem {
		for (const node of nodes) {
			if (!node.children?.length) {
				return node;
			}

			const firstLeaf = this.getFirstLeaf(node.children);

			if (firstLeaf) {
				return firstLeaf;
			}
		}

		return null;
	}

	getAllLeaves(nodes: DataItem[], allLeaves: DataItem[] = []): DataItem[] {
		for (const node of nodes) {
			if (!node.children?.length) {
				allLeaves.push(node);
			} else {
				this.getAllLeaves(node.children, allLeaves);
			}
		}

		return allLeaves;
	}
}
