import { Injectable } from '@angular/core';
import { DataConverterOptions, DataItem, ROOT_GUID } from '../domain';
import { GradeLearningObjectivesGrowth, GradeGrowthDict } from '../domain/lo-colors';
import { EntityTypeEnum } from '../domain/entities';
import { DataSelectorService } from '.';
import { enumValues } from '@snappet-content-products/shared/node';

@Injectable()
export class DataConverterService {
	constructor(private readonly dataSelectorService: DataSelectorService) { }

	convertData(nodes: DataItem[], options: DataConverterOptions): DataItem {
		const { rootLabel, filter, requiredLeafType, shouldTrimData } = options;

		let rootName = rootLabel;
		let rootGuid = ROOT_GUID;

		if (filter[EntityTypeEnum.Subject]?.length === 1) {
			const { label, guid } = this.dataSelectorService.getLevelByType(nodes, EntityTypeEnum.Subject).find(n => n.id === filter[EntityTypeEnum.Subject][0]);

			rootName = label;
			rootGuid = guid;
		}

		const rootItem: DataItem = {
			id: 0, label: rootName, children: [], type: null, guid: rootGuid,
			parent: null, parentId: null,
			filterIds: []
		};

		this.updateItem(rootItem, nodes, { ...options });

		if (shouldTrimData) {
			this.pruneBranches(rootItem.children, requiredLeafType);

			// Loop over all types of layers to see if a layer consists of only 1 node (it would be a full circle)
			enumValues(EntityTypeEnum).forEach(entityType => this.trimLayer(rootItem, entityType));

			return this.mapToFirstWithMultipleChildren(rootItem);
		}

		return rootItem;
	}

	private pruneBranches(nodes: DataItem[], requiredLeafType: EntityTypeEnum) {
		if (!requiredLeafType) {
			return;
		}

		nodes.forEach(node => {
			const leafsOfType = this.dataSelectorService.getLevelByType(node.children, requiredLeafType);

			if (leafsOfType.length === 0) {
				node.parent.children = [...node.parent.children.filter(n => n.id !== node.id)];
				node = null;
			} else if (node.children.length > 0 && node.children[0].type !== requiredLeafType) {
				this.pruneBranches(node.children, requiredLeafType);
			}
		});
	}

	private trimLayer(rootNode: DataItem, entityType: EntityTypeEnum) {
		const currentLayer = this.dataSelectorService.getLevelByType(rootNode.children, entityType);

		if (currentLayer.length === 0) {
			return;
		}

		const firstNode = currentLayer[0];

		// If ALL the nodes on this layer have the same LABEL it can be removed.
		if (currentLayer.every(node => node.label && node.label === firstNode.label)) {
			currentLayer.forEach(nodeToRemove => {
				nodeToRemove.parent.children = [...nodeToRemove.children];
				nodeToRemove.parent.children.forEach(c => {
					c.parent = nodeToRemove.parent;
					c.parentId = nodeToRemove.parentId;
				});
				nodeToRemove = null;
			});
		}
	}

	private updateItem(item: DataItem, nodes: DataItem[], options: DataConverterOptions): DataItem {
		const { loColors } = options;

		nodes.forEach(node => {
			const growths = this.getLoGrowths(loColors, node.loId);

			const nodeItem: DataItem = {
				growths,
				guid: node.guid,
				type: node.type,
				id: node.id,
				label: node.label,
				description: node.description,
				loId: node.loId,
				parent: item,
				parentId: item.id,
				children: [],
				value: node.children?.length > 0 ? null : 1,
				filterIds: node.filterIds,
				subMarketIds: node.subMarketIds
			};

			if (node.children?.length > 0) {
				this.updateItem(nodeItem, node.children, options);
			}

			if (nodeItem.children?.length > 0 || nodeItem.value) {
				item.children.push(nodeItem);
			}
		});

		if (item.children.length > 0) {
			this.updateParentGrowth(item);
		}

		return item;
	}

	private mapToFirstWithMultipleChildren(dataItem: DataItem): DataItem {
		dataItem.children = dataItem.children.filter(i => i.children.length > 0);
		dataItem.parent = null;
		dataItem.parentId = null;

		if (dataItem.children.length === 1) {
			return this.mapToFirstWithMultipleChildren(dataItem.children[0]);
		}

		return dataItem;
	}

	private getLoGrowths(loColors: GradeLearningObjectivesGrowth[], loId: number): GradeGrowthDict {
		const growths = new GradeGrowthDict();

		loColors.forEach(lc => {
			const loColor = lc.values.find(l => l.l === loId);
			const growth = loColor ? loColor.v : null;

			growths.set(lc.gradeId, growth);
		});

		return growths;
	}

	private updateParentGrowth(parent: DataItem) {
		parent.growths = new GradeGrowthDict();
		const grades = parent.children[0].growths.keys();

		for (const grade of grades) {
			const childrenWithGrowth = parent.children.filter(c => c.growths.get(grade) !== null);
			const averageGrowth = childrenWithGrowth.length > 0 ? childrenWithGrowth.reduce((p, c) => p + c.growths.get(grade), 0) / childrenWithGrowth.length : 0;

			parent.growths.set(grade, averageGrowth);
		}
	}
}
